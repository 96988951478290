ui-points-alert {
	.flexbox();
	
	margin-top: 2rem;
}

.points-alert {
	@icon-width: 1.5rem;
	
	.flexbox(@flexFlow: row nowrap; @display: inline-flex; @justifyContent: flex-start);
	
	padding: .75rem;
	background-color: #FEF9E5;
	border-radius: @default-border-radius;
	
	&__icon {
		width: @icon-width;
		height: @icon-width;
	}
	
	&__message {
		margin-left: .625rem;
	}
}
