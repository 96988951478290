.hamburger {
	@hamburger-width: 24px;
	@hamburger-height: 20px;
	@hamburger-vertical-spacing: 8px;
	@hamburger-vertical-spacing-double: @hamburger-vertical-spacing * 2;
	
	.transition();
	
	display: inline-block;
	overflow: visible;
	margin: 0;
	padding: 0;
	cursor: pointer;
	color: inherit;
	border: 0;
	background-color: transparent;
	margin-top: 7px;
	
	// Animate from hamburger to 'X'
	&.--isActive {
		.hamburger__inner {
			transform: ~'translate3d(0, @{hamburger-vertical-spacing}, 0) rotate(45deg)';
			
			&::before,
			&::after {
				background-color: @white;
			}
			
			&::before {
				transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
    			opacity: 0;
			}
			
			&::after {
				transform: ~'translate3d(0, -@{hamburger-vertical-spacing-double}, 0) rotate(-90deg)';
				width: 100%;
			}
		}
	}
	
	&__wrapper {
		position: relative;
		display: inline-block;
		width: @hamburger-width;
		height: @hamburger-height;
	}
	
	&__inner,
	&__inner::before,
	&__inner::after {
		.transition();
		
		position: absolute;
		width: @hamburger-width;
		height: 2px;
		border-radius: 3px;
		background-color: @white;
	}
	
	&__inner {
		top: 2px;
		display: block;
		margin-top: -2px;
		
		&::before,
		&::after {
			display: block;
			content: '';
		}
		
		&::before {
			.transition();
			
			top: @hamburger-vertical-spacing;
		}
		
		// Bottom bar of the hamburger
		&::after {
			.transition();
			
			top: @hamburger-vertical-spacing-double;
			width: 50%;
			right: 0;
		}
	}
}
