/* Global */
/*
	Keyframes
*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Sidebar overlay animation */
@keyframes sideSlideIn {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes sideSlideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
/* Bottom panels */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(150%);
  }
}
/* Accordion */
@keyframes unfold {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
/* Side panels */
@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(150%);
  }
}
/*
	Lock -> unlock -> check animation for points reward levels
*/
@keyframes fadeOutAfter {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes scaleDownRotate {
  from {
    transform: scale(1) rotate(0);
  }
  to {
    transform: scale(0) rotate(-90deg);
  }
}
@keyframes scaleUpRotate {
  from {
    transform: rotate(135deg) scale(0);
  }
  to {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}
@keyframes propagateOut {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    top: -55%;
    transform: scale(1.6);
  }
}
/*
	Redeem points
*/
@keyframes scaleUpGroup {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(0.85);
  }
}
@keyframes moveUpGroup {
  to {
    top: -110px;
  }
}
@keyframes moveLeftBlue {
  to {
    left: 5%;
  }
}
@keyframes moveRightGreen {
  to {
    left: 85%;
  }
}
@keyframes moveRightWhite {
  to {
    left: 95%;
  }
}
@keyframes moveDownGroup {
  to {
    top: -90px;
  }
}
@keyframes moveDownBlue {
  to {
    top: 0;
  }
}
@keyframes moveDownGreen {
  to {
    top: -15px;
  }
}
@keyframes moveDownWhite {
  to {
    top: -40px;
  }
}
@keyframes halfSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
html {
  scroll-behavior: smooth;
}
.animated {
  transition: all 0.3s ease-out;
}
a {
  text-decoration: none;
  color: #00A7FF;
  font-weight: 700;
}
a:hover {
  text-decoration: underline;
}
[ng-click] {
  cursor: pointer;
}
.sandbox-env {
  position: absolute;
  z-index: 50000;
  bottom: 0;
  right: 0;
  color: #ff0000;
  font-size: 10px;
  font-weight: 500;
  padding: 5px;
}
.loading-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 10px 30px;
  text-align: center;
}
.prizeout-widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  background: #fcfdff;
  box-sizing: border-box;
}
.ui-header {
  background-color: #00A7FF;
  color: #FFFFFF;
  box-sizing: border-box;
  width: 100%;
  z-index: 51010;
  position: relative;
  height: 46px;
}
.ui-header * {
  color: #FFFFFF;
}
.ui-header__inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 1.875rem;
}
@media only screen and (max-width: 768px) {
  .ui-header__inner {
    padding: 0 1rem;
  }
}
.ui-header .back {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}
.ui-header .back__wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.ui-header .back__wrapper:hover .back__icon,
.ui-header .back__wrapper:hover .back__text {
  color: #021431;
}
.ui-header .back__icon,
.ui-header .back__text {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1;
}
.ui-header .back__text {
  margin-left: 0.5rem;
}
.ui-header .logo {
  text-align: center;
  width: auto;
}
.ui-header .logo__wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.ui-header .logo__image {
  width: 100%;
  max-width: 100px;
  height: auto;
  display: inline-block;
}
.ui-header .logo__image.--mobile {
  max-width: 20px;
}
.ui-header .user {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.ui-header .user__balance {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 2px solid #FFFFFF;
  padding: 0.15rem 0.5rem;
}
.ui-header .user__balance.--currency {
  border: none;
}
.ui-header .user__balance p {
  font-size: 1rem;
  line-height: 1;
}
@media only screen and (max-width: 480px) {
  .ui-header .user__balance p {
    font-size: 0.75rem;
    line-height: 1.4;
  }
}
@media only screen and (max-width: 480px) {
  .ui-header .user__balance {
    border-width: 1.5px;
    font-size: 0.75rem;
  }
}
.ui-header .user__menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.ui-header .user__menu-item {
  margin: 0 2.5rem 0 0;
}
.ui-header .user__menu-item.--isActive a {
  color: #021431;
  font-weight: 700;
}
.ui-header .user__menu-item a {
  font-family: Karla, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: #FFFFFF;
  text-decoration: none;
}
.ui-header .user__menu-item a:hover,
.ui-header .user__menu-item a:active {
  color: #021431;
}
.ui-header .user__currencyWrapper {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.ui-header .user__currencyWrapper p {
  font-size: 0.875rem;
  color: #FFFFFF;
}
.ui-header .user__currencyWrapper small {
  font-size: 0.75rem;
  color: #021431;
  font-weight: 600;
}
.ui-header .user__flag {
  max-width: 1.5rem;
  margin-right: 0.35rem;
}
.ui-header .user__callout {
  color: #021431;
}
.ui-content {
  box-sizing: border-box;
  padding: 2rem;
  color: #021431;
  background-color: #F3F3F6;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 1600px) {
  .ui-content {
    padding: 2rem 9rem;
  }
}
@media only screen and (max-width: 1024px) {
  .ui-content {
    padding: 2rem 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .ui-content {
    padding: 2rem 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .ui-content {
    padding: 1rem 0.5rem;
  }
}
.side-panel-layout.--website .ui-content {
  padding: 0;
  background-color: #FFFFFF;
}
.ui-footer {
  background-color: #FFFFFF;
}
.ui-footer * {
  box-sizing: border-box;
}
.ui-footer__inner {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 2rem;
  width: 100%;
}
@media only screen and (min-width: 1600px) {
  .ui-footer__inner {
    padding: 1.25rem 9rem;
  }
}
@media only screen and (max-width: 1024px) {
  .ui-footer__inner {
    padding: 1.25rem 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .ui-footer__inner {
    padding: 1.25rem 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .ui-footer__inner {
    padding: 1rem 0.5rem;
  }
}
@media only screen and (max-width: 1440px) {
  .ui-footer__inner {
    flex-flow: column;
  }
}
.sidebar-opened .side-panel-layout.--website .ui-footer__inner {
  width: calc(100% - 25rem);
}
@media only screen and (max-width: 879px) {
  .sidebar-opened .side-panel-layout.--website .ui-footer__inner {
    width: calc(100% - 19rem);
  }
}
@media only screen and (max-width: 640px) {
  .sidebar-opened .side-panel-layout.--website .ui-footer__inner {
    width: 100%;
  }
}
.ui-footer p {
  color: #808997;
  font-weight: 700;
}
@media only screen and (max-width: 1440px) {
  .ui-footer p {
    font-size: 0.75rem;
  }
}
.ui-footer__links {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1440px) {
  .ui-footer__links {
    margin-top: 0.875rem;
  }
}
.ui-footer__link {
  margin: 0;
  line-height: 1.5;
}
.ui-footer__link a {
  margin: 0 0.5rem;
}
@media only screen and (max-width: 480px) {
  .ui-footer__link a {
    margin: 0 0.05rem;
  }
}
.scroll-pane {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.side-panel-layout {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #F3F3F6;
  transition: 0.3s ease-in all;
  overflow-x: hidden;
}
.side-panel-layout.--website {
  background-color: #FFFFFF;
}
.side-panel-layout.side-panel-open .ui-content,
.side-panel-layout.side-panel-open .ui-footer__inner {
  width: calc((100% - 25rem) + 1rem);
  padding-right: 2.25rem;
}
@media only screen and (min-width: 1600px) {
  .side-panel-layout.side-panel-open .ui-content,
  .side-panel-layout.side-panel-open .ui-footer__inner {
    padding-right: 5rem;
  }
}
@media only screen and (max-width: 1440px) {
  .side-panel-layout.side-panel-open .ui-content,
  .side-panel-layout.side-panel-open .ui-footer__inner {
    padding-right: 2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .side-panel-layout.side-panel-open .ui-content,
  .side-panel-layout.side-panel-open .ui-footer__inner {
    padding-right: 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .side-panel-layout.side-panel-open .ui-content,
  .side-panel-layout.side-panel-open .ui-footer__inner {
    padding-right: 1rem;
  }
}
.ng-highlight {
  background-color: rgba(66, 165, 245, 0.3);
}
/*
	OneTrust
*/
button {
  margin: 0;
}
.ot-btn-anchor {
  text-decoration: none;
}
.ot-exercise-button-light {
  background: #eee;
  border: 1px solid #aaa;
  /*margin-left: 44px;*/
}
.ot-exercise-button {
  margin-top: 10px;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.ot-exercise-button img {
  margin-right: 0px;
  vertical-align: middle;
}
.ot-exercise-button .ot-text-container {
  vertical-align: middle;
  display: inline-block;
  margin-top: -4px;
  color: #6cc04a;
  font-size: 13px;
}
.ot-exercise-button .ot-subtext {
  float: right;
  margin-top: 0px;
  font-size: 11px;
}
.ot-exercise-button .ot-powered-by-text {
  font-size: 7px;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  line-height: 16px;
}
.ot-exercise-button.ot-exercise-button-light .ot-powered-by-text {
  color: #3c474c;
}
table.my-giftcards {
  width: 100%;
}
table.my-giftcards > tbody > tr > td {
  padding: 10px 5px;
}
table.my-giftcards > tbody > tr > td.image {
  width: 100px;
}
table.my-giftcards > tbody > tr > td.image > .giftcard-image {
  width: 123px;
  height: 78px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #dcdddf;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
table.my-giftcards > tbody > tr > td.gc-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0;
  color: #4f566b;
  font-size: 12px;
  font-weight: 500;
}
table.my-giftcards > tbody > tr > td.gc-name .name {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}
table.my-giftcards > tbody > tr > td.gc-name .detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .when {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  font-size: 10px;
  font-weight: 300;
  border-right: 1px solid #e1e8eb;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .when > span {
  margin-bottom: 5px;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .value {
  width: 40%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 16px;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .value > span {
  width: 80%;
  margin-right: 10px;
}
table.my-giftcards > tbody > tr > td.gc-name .detail .value .fa-caret-right,
table.my-giftcards > tbody > tr > td.gc-name .detail .value .fa-caret-down {
  font-size: 20px;
}
table.my-giftcards > tbody > tr > td.status {
  text-align: center;
}
table.my-giftcards > tbody > tr > td.status a {
  font-size: 10px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.7);
}
table.my-giftcards > tbody > tr.last td {
  border-bottom: 1px solid #6c748e;
}
table.transaction-detail {
  width: 100%;
  padding: 5px;
}
table.transaction-detail > tbody tr {
  font-size: 12px;
}
table.transaction-detail > tbody tr td {
  padding-bottom: 5px;
}
table.transaction-detail > tbody tr td:first-child {
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
}
table.transaction-detail > tbody tr td.detail {
  color: #4f566b;
}
.text-link {
  color: #1eb1fa;
  text-decoration: underline;
}
.ui-pagination > table {
  width: 100%;
}
.ui-pagination > table > tbody > tr > td {
  padding: 5px;
  text-align: center;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li {
  display: inline-block;
  padding: 3px 8px;
  font-size: 15px;
  font-weight: 500;
  color: #4f566b;
  border-radius: 3px;
  transition: 0.15s ease-in-out;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li:hover {
  background: #f4f5f7;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li.active {
  font-weight: 600;
  background-color: #42a5f5;
  color: #ffffff;
}
.ui-pagination > table > tbody > tr > td ul.pagination > li.disabled {
  pointer-events: none;
  color: #00000029;
}
.ui-nav {
  /*
		Large bubble menu:
		https://i.imgur.com/bWc7rTK.png
	*/
  /*
		Medium bubble toggle
	*/
  /*
		CTAs: Large buttons in a line
	*/
}
.ui-nav.bubble-menu {
  text-align: center;
  margin: 30px 0;
}
.ui-nav.bubble-menu > a {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #F8F9FC;
  width: 180px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
}
.ui-nav.bubble-menu > a > .icon {
  text-align: center;
  font-size: 45px;
  color: #42A5F5;
  transition: color 0.2s ease-out;
}
.ui-nav.bubble-menu > a > .label {
  text-align: center;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.ui-nav.bubble-menu > a:hover {
  background-color: rgba(3, 155, 229, 0.05);
  text-decoration: none;
  transform: scale(1.2);
}
.ui-nav.bubble-menu > a:hover > .icon {
  color: #29B6F6;
}
.ui-nav.bubble-toggle {
  text-align: center;
  margin: 30px 0;
}
.ui-nav.bubble-toggle > div {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  background-color: #F8F9FC;
  width: 150px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}
.ui-nav.bubble-toggle > div > .icon {
  text-align: center;
  font-size: 35px;
  color: #42A5F5;
  transition: color 0.2s ease-out;
}
.ui-nav.bubble-toggle > div > .label {
  text-align: center;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.ui-nav.bubble-toggle > div:hover {
  background-color: rgba(3, 155, 229, 0.05);
  text-decoration: none;
}
.ui-nav.bubble-toggle > div:hover > .icon {
  color: #29B6F6;
}
.ui-nav.bubble-toggle > div.selected {
  background-color: #42a5f5;
  color: #ffffff;
  text-decoration: none;
}
.ui-nav.bubble-toggle > div.selected > .icon {
  color: #ffffff;
}
.ui-nav.bubble-toggle > div.selected > .label {
  color: #ffffff;
  font-weight: 700;
}
.ui-nav.ctas {
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  /*&.scroll-fixed {
			position:	absolute;
		}*/
}
.ui-nav.ctas div,
.ui-nav.ctas a {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  margin: 10px;
  border-radius: 5px;
  padding: 0 25px;
  background-color: #1EB1FA;
  color: #ffffff;
  font-size: 14px;
  line-height: 45px;
  font-weight: 600;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
  position: relative;
}
.ui-nav.ctas div:hover,
.ui-nav.ctas a:hover {
  background-color: #128ef2;
  text-decoration: none;
}
.ui-nav.ctas div.main,
.ui-nav.ctas a.main {
  background-color: #1eb1fa;
}
.ui-nav.ctas div.main:hover,
.ui-nav.ctas a.main:hover {
  background-color: #128ef2;
  text-decoration: none;
}
.ui-nav.ctas div.blank,
.ui-nav.ctas a.blank {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #333333;
}
.ui-nav.ctas div.blank:hover,
.ui-nav.ctas a.blank:hover {
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.ui-nav.ctas div.danger,
.ui-nav.ctas a.danger {
  background-color: #e57373;
  color: #ffffff;
}
.ui-nav.ctas div.danger:hover,
.ui-nav.ctas a.danger:hover {
  background-color: #dd4848;
  text-decoration: none;
}
.ui-nav.ctas div.admin,
.ui-nav.ctas a.admin {
  background-color: #78909c;
  color: #ffffff;
}
.ui-nav.ctas div.admin:hover,
.ui-nav.ctas a.admin:hover {
  background-color: #5f7682;
  text-decoration: none;
}
.ui-nav.ctas div.positive,
.ui-nav.ctas a.positive {
  background-color: #66bb6a;
  color: #ffffff;
}
.ui-nav.ctas div.positive:hover,
.ui-nav.ctas a.positive:hover {
  background-color: #49a54e;
  text-decoration: none;
}
.ui-nav.ctas div.negative,
.ui-nav.ctas a.negative {
  background-color: #fb8c00;
  color: #ffffff;
}
.ui-nav.ctas div.negative:hover,
.ui-nav.ctas a.negative:hover {
  background-color: #c87000;
  text-decoration: none;
}
.ui-nav.ctas div.disabled,
.ui-nav.ctas a.disabled {
  background-color: rgba(0, 0, 0, 0.5);
  color: #333333;
  text-decoration: none;
  cursor: default;
}
.ui-nav.ctas div.disabled:hover,
.ui-nav.ctas a.disabled:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: #333333;
  text-decoration: none;
  cursor: default;
}
.ui-nav.ctas div.sep,
.ui-nav.ctas a.sep {
  padding: 0 !important;
  width: 1px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: default;
}
.ui-nav.ctas div .alert-dot,
.ui-nav.ctas a .alert-dot {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;
}
.ui-nav.ctas.small div,
.ui-nav.ctas.small a {
  font-size: 12px;
  line-height: 30px;
  padding: 2px 30px;
  margin: 5px;
}
.ui-nav.ctas.align-right {
  text-align: right;
}
.ui-nav.ctas.align-left {
  text-align: left;
}
/* Fonts */
/* Colors */
/* Borders */
/* Border Radius */
/* Box Shadow */
/* Homepage Layout */
/* Sidebar Nav */
/* Sidebar Expanded */
/* Header */
/* Spacing */
/* Media Queries
	usage: @media @tablet-landscape-down { ... };
*/
/* Change the widget background to white */
.prizeout-widget.theme-white .side-panel-layout,
.prizeout-widget.theme-white .side-panel,
.prizeout-widget.theme-white .ui-content,
.prizeout-widget.theme-white .horizontal-offers,
.prizeout-widget.theme-white .promo-offers,
.prizeout-widget.theme-white .sticky__component {
  background-color: #FFFFFF;
}
.prizeout-widget.theme-white .sticky__component {
  width: calc(100% + 5rem);
}
@media only screen and (max-width: 879px) {
  .prizeout-widget.theme-white .sticky__component {
    width: 100%;
  }
}
.prizeout-widget.theme-white .ui-promo-widget {
  box-shadow: 0 0 10px rgba(2, 20, 49, 0.1);
}
.prizeout-widget.theme-white .card.--hasDetails {
  border-color: #F5F5F7;
}
.prizeout-widget.theme-white .card.--selected {
  border-color: #00A7FF;
}
/* Change header background to white */
.ui-header.theme-white {
  background-color: #FFFFFF;
  color: #021431;
  border-bottom: 1px solid rgba(2, 20, 49, 0.1);
}
.ui-header.theme-white * {
  color: #021431;
}
.ui-header.theme-white .back__icon,
.ui-header.theme-white .back__text {
  color: #021431;
}
.ui-header.theme-white .back__wrapper:hover .back__icon,
.ui-header.theme-white .back__wrapper:hover .back__text {
  color: #00A7FF;
}
.ui-header.theme-white .user__balance {
  border: 2px solid #021431;
}
.ui-header.theme-white .hamburger.--isActive .hamburger__inner::before,
.ui-header.theme-white .hamburger.--isActive .hamburger__inner::after {
  background-color: #021431;
}
.ui-header.theme-white .hamburger__inner,
.ui-header.theme-white .hamburger__inner::before,
.ui-header.theme-white .hamburger__inner::after {
  background-color: #021431;
}
body {
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #021431;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
.animated-digits,
.card__title {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.animated-digits,
.card__title {
  line-height: 1.2;
  font-weight: 700;
}
h1,
.animated-digits {
  font-size: 1.875rem;
  font-family: Karla, Arial, sans-serif;
}
h2,
.card__title {
  font-size: 1.5rem;
  font-family: Karla, Arial, sans-serif;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 1.125rem;
}
h5 {
  font-size: 0.875rem;
}
p {
  font-size: 0.875rem;
  line-height: 1.5;
}
small {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.3;
}
strong,
.text-strong {
  color: inherit;
  font-weight: 700;
}
span,
span[ng-bind] {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
}
[ng-bind-html] ol,
[ng-bind-html] ul,
[ng-bind-html] dl {
  padding-left: revert;
}
[ng-bind-html] li {
  list-style-type: disc;
}
[ng-bind-html] > *:not(a) {
  margin-bottom: 0.75rem;
  color: inherit;
  font-family: inherit;
}
/*
	Typography utility classes
*/
.eyebrow {
  color: #00A7FF;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.eyebrow.--small {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}
.text-regular {
  font-weight: 400;
}
.text-extra-small {
  font-size: 0.675rem;
}
.text-large {
  font-size: 1.25;
}
.text-extra-large,
.checkout-confirmation__cost {
  font-size: 2.625rem;
}
.text-light,
.text-trim > *,
.free-giftcard__terms,
.iconText__steps,
.modal__subtitle,
.hero__subheading,
.redeem-homepage__description,
.bank-accounts__type,
.bank-accounts__number,
.checkout-page .ui-offer-page .checkout__description {
  color: #9098A5;
}
.text-dark {
  color: #021431;
}
.text-white {
  color: #FFFFFF;
}
.text-trim > * {
  line-height: 1.5;
}
.text-trim pre,
.text-trim code {
  font-family: inherit;
  white-space: normal;
  margin: 0;
}
.nobreak {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no-text-underline {
  text-decoration: none !important;
}
.link {
  font-size: 10px;
  font-weight: 500;
  color: rgba(66, 165, 245, 0.7);
  transition: color ease-out 0.3s;
}
.link:hover {
  text-decoration: underline;
}
.loading-page {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90000;
}
.loading-page img {
  width: 100%;
  max-width: 6.25rem;
}
.loading-indicator {
  padding: 20px auto;
  font-size: 45px;
  color: #000000;
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italicize {
  font-style: italic;
}
.help-link {
  text-align: right;
  padding: 3px 10px;
}
.help-link > span {
  color: #42a5f5;
  font-size: 11px;
  font-weight: 600;
}
.font-weight-bolder {
  font-weight: 800;
}
/*
	Alert Boxes
*/
.ui-alert {
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.ui-alert.warning {
  background-color: rgba(255, 193, 7, 0.8);
  color: #333;
}
/* Full screen overlay */
.ui-overlay {
  top: 46px;
  height: calc(100% - 46px);
  position: fixed;
  top: unset;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 51000;
  background-color: rgba(229, 231, 234, 0.7);
}
@supports (backdrop-filter: blur(6px)) {
  .ui-overlay {
    background-color: rgba(229, 231, 234, 0.2);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }
}
.ui-overlay.--fullScreen {
  top: 0;
  height: 100%;
}
.ui-overlay.--show-sidebar {
  z-index: 50100;
}
.ui-overlay.--with-message {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "message";
}
@media only screen and (max-width: 879px) {
  .ui-overlay.--with-message {
    z-index: 50990;
  }
}
.ui-overlay.--with-message > .ui-overlay__message {
  grid-area: message;
  justify-self: center;
  align-self: center;
  margin: 2rem;
}
.ui-overlay.--with-message > .ui-overlay__message > div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 2rem;
}
.ui-overlay.--with-message.--show-sidebar {
  width: calc(100% - 25rem);
}
.ui-overlay.--sidebar {
  z-index: 51020;
  /* Must go above sidebar but below bottom-panel */
}
.ui-overlay.--for-dialog.ng-enter {
  animation: fadeIn 0.4s ease-out;
}
.ui-overlay.--for-dialog.ng-leave {
  animation: fadeOut 0.3s linear;
}
.ui-overlay.--for-sidebar {
  z-index: 51005;
  /* Under the menu sidebar, but over the checkout sidebar */
}
.ui-overlay.--for-sidebar.ng-enter {
  animation: fadeIn 0.4s linear;
}
.ui-overlay.--for-sidebar.ng-leave {
  animation: fadeOut 0.3s linear;
}
.floating-dialog {
  /* Dialog bg */
}
.floating-dialog > .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  background-color: rgba(229, 231, 234, 0.7);
  z-index: 51000;
  /* navbar is 50000 */
}
@supports (backdrop-filter: blur(6px)) {
  .floating-dialog > .bg {
    background-color: rgba(229, 231, 234, 0.2);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }
}
.floating-dialog > .bg.--solid {
  background-color: #FFFFFF;
}
.floating-dialog > .dialog-box {
  z-index: 51000;
  /* navbar is 50000 */
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 50px;
  left: 50px;
}
.sidebar-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
  width: 31.25rem;
  padding: 20px;
  z-index: 52000;
  background-color: #ffffff;
  box-sizing: border-box;
  /* ng-if show animation */
  /* ng-if hide animation */
}
@media only screen and (max-width: 768px) {
  .sidebar-dialog {
    width: 100%;
  }
}
.sidebar-dialog.ng-enter {
  animation: slideLeft 0.4s ease-out;
}
.sidebar-dialog.ng-leave {
  animation: slideRight 0.3s linear;
}
/*
	Copy-pasted from Quant-Studio.com with authorization
*/
.app-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table /*!important*/;
  z-index: 51000;
  /* navbar is 50000 */
  transition: opacity ease-out 0.3s, transform ease-out 0.3s;
  /* Dialog bg */
}
@media only screen and (max-width: 879px) {
  .app-dialog.gdpr {
    bottom: 0;
  }
}
.app-dialog.gdpr .ctas > div {
  width: 180px !important;
}
.app-dialog.gdpr .ctas > div.disabled {
  opacity: 0.5;
}
.app-dialog.gdpr .exit {
  background: #acacac;
}
.app-dialog.gdpr .exit:hover {
  background: #0000009f;
}
.app-dialog.ng-enter,
.app-dialog.ng-leave.ng-leave-active {
  opacity: 0;
}
.app-dialog.ng-leave,
.app-dialog.ng-enter.ng-enter-active {
  opacity: 1;
}
.app-dialog.front {
  z-index: 52000;
}
.app-dialog.always-on-top {
  z-index: 90000;
}
.app-dialog > .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(229, 231, 234, 0.7);
}
@supports (backdrop-filter: blur(6px)) {
  .app-dialog > .bg {
    background-color: rgba(229, 231, 234, 0.2);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }
}
.app-dialog > .bg.--solid {
  background-color: #FFFFFF;
}
.app-dialog .overlay {
  position: relative;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.app-dialog .overlay > div {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  border-radius: 5px;
  padding: 2rem;
}
.app-dialog .overlay.form > div {
  max-width: 500px;
}
.app-dialog .overlay.medium > div {
  max-width: 500px;
}
.app-dialog .overlay.large > div {
  max-width: 800px;
}
.app-dialog.small > .overlay > div {
  max-width: 400px;
}
.app-dialog.medium > .overlay > div {
  max-width: 600px;
}
.app-dialog.large .overlay > div {
  max-width: 800px;
}
.app-dialog.x-large > .overlay > div {
  max-width: 90%;
}
.app-dialog-ui {
  background-image: linear-gradient(#FEFEFF, #F9F8FF);
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;
}
.app-dialog-ui .header {
  color: #404040;
  font-size: 18px;
  font-weight: 400;
  padding: 30px 10px 10px;
  text-align: center;
}
.app-dialog-box-v2 {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
}
.app-dialog-box-v2 .dialog-close {
  text-align: right;
}
.app-dialog-box-v2 .dialog-close .fa-times,
.app-dialog-box-v2 .dialog-close .fa-times-circle {
  margin-top: 5px;
  margin-right: 5px;
  color: lightgray;
  font-size: 30px;
  font-weight: 300;
  transition: 0.25s ease-in;
}
.app-dialog-box-v2 .dialog-close .fa-times:hover,
.app-dialog-box-v2 .dialog-close .fa-times-circle:hover {
  color: #000000;
}
.app-dialog-box {
  background-color: #ffffff;
  border-radius: 5px;
}
.app-dialog-box .body {
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #20222E;
  border-radius: 5px;
}
.app-dialog-box .body.gdpr {
  color: #6d7798;
  padding: 1rem;
  text-align: center;
}
.app-dialog-box .body.gdpr .logo {
  max-height: 48px;
}
.app-dialog-box .body.gdpr .text {
  margin: 8px 0;
  color: #6d7798;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
.app-dialog-box .body.gdpr .info-block {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.app-dialog-box .body.gdpr .info-block a {
  color: #0000009f;
  font-weight: 600;
  text-decoration: underline;
}
.app-dialog-box .body.gdpr .info-block a:hover {
  text-decoration: none;
}
.app-dialog-box .body.gdpr .checklist-container {
  display: flex;
  flex-direction: column;
  margin: 0 4rem 2rem 4rem;
}
@media screen and (max-width: 480px) {
  .app-dialog-box .body.gdpr .checklist-container {
    margin: 0;
  }
}
.app-dialog-box .body.gdpr .checklist-container .checklist-item > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
}
.app-dialog-box .body.gdpr label {
  position: relative;
  display: block;
  margin-right: 1rem;
  margin-bottom: 12px;
  padding-left: 24px;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
}
.app-dialog-box .body.gdpr label input {
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
  opacity: 0;
}
.app-dialog-box .body.gdpr label input:checked ~ .checkmark {
  background-color: #ffffff;
}
.app-dialog-box .body.gdpr label input:checked ~ .checkmark:after {
  display: block;
}
.app-dialog-box .body.gdpr label .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid #dcdddf;
  border-radius: 6px;
}
.app-dialog-box .body.gdpr label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: -11px;
  width: 6px;
  height: 18px;
  border: solid #1eb1fa;
  border-width: 0 3px 3px 0;
  transform: rotate(30deg);
}
.app-dialog-box .body.gdpr .ctas .button {
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: none;
}
.app-dialog-box .body.gdpr .ctas .button.disabled {
  color: #ffffff;
  background: #1eb1fa !important;
}
.app-dialog-box .body .text {
  font-size: 14px;
  font-weight: 400;
}
.app-dialog-box .body.no-padding {
  padding: 0;
}
.app-dialog-box .body.scroll {
  max-height: 600px;
  overflow-y: auto;
}
.app-dialog-box .logo {
  max-height: 100px;
}
.app-dialog-box .message {
  text-align: center;
  padding: 3px;
  font-size: 11px;
  background-color: #508ED7;
  color: #333333;
}
.app-dialog-box .message a {
  font-size: 10px;
  text-decoration: underline;
}
.app-dialog-box .header {
  background-color: #1F2229;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-bottom: 1px solid #20222E;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
.app-dialog-box .header > .author {
  color: #1CC5BC;
  font-size: 11px;
  font-weight: 500;
}
.app-dialog-box .header.with-tabs {
  position: relative;
}
.app-dialog-box .header.with-tabs .dialog-header-tabs {
  position: absolute;
  top: 10px;
  right: 10px;
}
.app-dialog-box .menu {
  background-color: #2D2F3B;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border-bottom: 1px solid #20222E;
}
.app-dialog-box .tabs {
  background-color: #2D2F3B;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border-bottom: 1px solid #20222E;
  text-align: center;
}
.app-dialog-box .tabs > div {
  display: inline-block;
  padding: 5px 15px;
  margin: 4px 0;
  color: #000000;
  border-bottom: 2px solid transparent;
}
.app-dialog-box .tabs > div.selected {
  border-bottom: 2px solid #508ED7;
  color: #333333;
}
.app-dialog-box .tabs > div:hover {
  color: #333333;
}
.app-dialog-box .tabs > div .tag {
  font-size: 8px;
}
.app-dialog-box .dialog-close {
  text-align: right;
}
.app-dialog-box .dialog-close .fa-times,
.app-dialog-box .dialog-close .fa-times-circle {
  margin-top: 5px;
  margin-right: 5px;
  color: lightgray;
  font-size: 30px;
  font-weight: 300;
  transition: 0.25s ease-in;
}
.app-dialog-box .dialog-close .fa-times:hover,
.app-dialog-box .dialog-close .fa-times-circle:hover {
  color: #000000;
}
.app-dialog-box .body {
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #20222E;
  border-radius: 0 0 5px 5px;
}
.app-dialog-box .body .title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}
.app-dialog-box .body .info {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
}
.app-dialog-box .body .text {
  font-size: 12px;
  font-weight: 400;
}
.app-dialog-box .body .error {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #B71C1C;
}
.app-dialog-box .body .fake-link {
  text-decoration: underline;
  font-weight: 700;
}
.app-dialog-box .body.no-padding {
  padding: 0;
}
.app-dialog-box .body.scroll {
  max-height: 600px;
  overflow-y: auto;
}
.app-dialog-box .confirmation-message {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}
.app-dialog-box.static-height .body {
  height: 400px;
  overflow-y: auto;
}
.app-dialog-box .text-block {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  text-align: left;
}
.app-dialog-box .text-block.center {
  text-align: center;
}
.app-dialog-box .text-block.right {
  text-align: right;
}
.app-dialog-box .text-block a {
  color: #508ED7;
  text-decoration: underline;
}
.app-dialog-box .footer {
  background-color: #508ED7;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  color: #333333;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin: 5px 0;
}
.app-dialog-box .footer:last-child {
  margin: 5px 0 0 0;
}
.app-dialog-box .footer:hover {
  background-color: #3b81d2;
}
.app-dialog-box .footer.green {
  background-color: #5BB85D;
}
.app-dialog-box .footer.green:hover {
  background-color: #4bae4e;
}
.app-dialog-box .footer.disabled,
.app-dialog-box .footer.disabled:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(229, 231, 234, 0.2);
  cursor: default;
}
.app-dialog-box .text-link {
  color: #333333;
  font-size: 11px;
  font-weight: 300;
  padding: 10px;
  text-align: center;
  margin: 5px 0;
}
.ui-tags {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  cursor: text;
}
.ui-tags > input {
  display: inline-block;
  height: 20px;
  border: 0;
  background-color: transparent;
  width: 20px;
}
.ui-tags > input:focus {
  outline: none;
}
.ui-tags > div {
  position: relative;
}
.ui-tags > div .ui-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}
.tag {
  background-color: #42A5F5;
  color: #ffffff;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 3px;
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
  margin: 1px;
}
.tag.green {
  background-color: #66bb6a;
}
.tag.red {
  background-color: #d32f2f;
}
.tag.orange {
  background-color: #ff9800;
}
.tag.blue {
  background-color: #03a9f4;
}
.tag.gray {
  background-color: #78909c;
}
.tag.disabled {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.7);
}
.tag > span {
  display: inline-block;
  vertical-align: middle;
}
.tag > .label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
  vertical-align: middle;
}
.tag.small {
  padding: 1px 3px;
  line-height: 14px;
  font-size: 8px;
}
.ui-list {
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.ui-list.absolute {
  position: absolute;
  width: 100%;
}
.ui-list > div {
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  vertical-align: top;
}
.ui-list > div > .icon {
  float: left;
  margin-right: 5px;
}
.ui-list > div > .with-help {
  float: left;
}
.ui-list > div > .with-help > .main {
  font-size: 14px;
  font-weight: 700;
}
.ui-list > div > .with-help > .sub {
  font-size: 11px;
  font-weight: 500;
}
.ui-list > div.active {
  background-color: #cce8ff;
}
.ui-list > div:hover {
  background-color: rgba(66, 165, 245, 0.1);
}
.password-input > .form__inputWrapper {
  position: relative;
}
.password-input > .form__inputWrapper .password-strength {
  width: calc(100% - 1rem);
  height: 2px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0.5rem;
}
.password-input > .form__inputWrapper .password-strength > div {
  height: 2px;
  background-color: transparent;
  border-radius: 3px;
}
.password-input > .form__inputWrapper .password-strength > div.score_0 {
  background-color: #D95F57;
  width: 1px;
}
.password-input > .form__inputWrapper .password-strength > div.score_1 {
  background-color: #F2675B;
  width: 25%;
}
.password-input > .form__inputWrapper .password-strength > div.score_2 {
  background-color: #F0C700;
  width: 50%;
}
.password-input > .form__inputWrapper .password-strength > div.score_3 {
  background-color: #26B668;
  width: 75%;
}
.password-input > .form__inputWrapper .password-strength > div.score_4 {
  background-color: #2AC86e;
  width: 100%;
}
.password-input .warning {
  color: #F2675B;
}
.ui-checklist {
  background-color: #ffffff;
  overflow: auto;
  border-radius: 3px;
  display: table;
  width: 100%;
}
.ui-checklist > div {
  display: table-row;
}
.ui-checklist > div > div {
  display: table-cell;
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  vertical-align: middle;
}
.ui-checklist > div > div.icon {
  width: 20px;
  text-align: left;
}
.ui-checklist > div > div.label > .main {
  font-size: 12px;
  font-weight: 700;
}
.ui-checklist > div > div.label > .sub {
  font-size: 11px;
  font-weight: 500;
}
.ui-checklist > div.active {
  background-color: #cce8ff;
}
.ui-checklist > div:hover {
  background-color: rgba(66, 165, 245, 0.1);
}
.edit-in-place {
  position: relative;
}
.edit-in-place input.input-text {
  width: 100%;
  padding: 2px 4px;
}
.edit-in-place select {
  width: 100%;
}
.edit-in-place .cell-message {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 7px;
  font-weight: 600;
  padding: 1px 3px;
  border-radius: 3px 0 0 0;
}
.edit-in-place .cell-message.accepted {
  background-color: rgba(102, 187, 106, 0.6);
  color: #ffffff;
}
.edit-in-place .cell-message.rejected {
  background-color: #e57373;
  color: #ffffff;
}
.edit-in-place .cell-message.assigned {
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.edit-in-place .cell-message.modified {
  background-color: rgba(255, 193, 7, 0.6);
  color: rgba(0, 0, 0, 0.6);
}
.edit-in-place .cell-message.new {
  background-color: rgba(66, 165, 245, 0.6);
  color: #ffffff;
}
/*
	Role editor UI
*/
.role-editor .type-ahead input {
  width: 100%;
  padding: 2px 4px;
}
.role-editor .type-ahead > div > div {
  position: relative;
}
.role-editor .type-ahead > div > div > div {
  position: absolute;
  width: 100%;
}
.role-editor > table {
  width: 100%;
}
.role-editor > table > tbody > tr {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}
.role-editor > table > tbody > tr > td {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.role-editor > table > tbody > tr > td.role {
  width: 100px;
}
.role-editor > table > tbody > tr > td.role select {
  width: 100%;
}
.role-editor > table > tbody > tr > td.del {
  width: 30px;
  text-align: center;
}
.role-editor > table > tbody > tr:last-child {
  border-bottom: 0;
}
.ui-profile img {
  float: left;
  width: 34px;
  margin: 0 10px 0 0;
  border-radius: 50%;
}
.ui-profile .name {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}
.ui-profile .email {
  font-size: 10px;
  font-weight: 600;
  color: rgba(0, 156, 239, 0.8);
}
.ui-toggles.--grid > div {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(4, minmax(4.375rem, 1fr));
}
.ui-toggles__spread {
  grid-column: span 2;
}
.ui-toggles__item {
  padding: 8px 4px;
  border: none;
  border-radius: 10px;
  text-align: center;
  background-color: #E5E7EA;
  color: #021431;
  width: 100%;
}
.ui-toggles__item.selected,
.ui-toggles__item.selected:hover,
.ui-toggles__item.selected:focus,
.ui-toggles__item:focus {
  background-color: #00A7FF;
  color: #FFFFFF;
}
.ui-toggles__item:hover {
  background-color: #CCD0D5;
}
.ui-form {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba(2, 20, 49, 0.1);
}
@media only screen and (max-width: 1024px) {
  .ui-form {
    padding: 1rem 1.5rem 1.5rem;
  }
}
@media only screen and (max-width: 879px) {
  .ui-form {
    padding: 1rem 1.5rem;
  }
}
.ui-form > div.form {
  width: 100%;
  padding: 10px 0;
}
.ui-form > div.form > div {
  display: flex;
  align-items: center;
}
.ui-form > div.form > div > div {
  display: table-cell;
  padding: 10px;
}
.ui-form > div.form > div > div input {
  width: 82%;
  padding: 7px;
  border: 1px solid silver;
  border-radius: 3px;
  font-size: 13px;
  text-overflow: ellipsis;
}
.ui-form > div.form > div > div textarea {
  width: 100%;
  height: 96px;
}
.ui-form > div.form > div > div:first-child {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
  width: 35%;
  text-align: right;
}
.ui-form > div.form > div > div:last-child {
  width: 70%;
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  text-align: left;
}
.ui-form > div.form > div > div:last-child .about {
  text-align: center;
  font-size: 11px;
  color: #5d5d5d;
  font-size: 500;
  padding: 6px 0;
}
.ui-form > div.form > div > div.special-offer {
  color: #57ba6e;
  font-size: 12px;
}
.ui-form > div.form .title {
  margin: 0 0 10px 0;
  color: #566481;
  text-align: center;
}
.ui-form > div.form.lines > div > div {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}
.ui-form > .sep {
  background-color: #e1e8eb;
  height: 1px;
  margin: 10px auto;
}
.ui-form > .ctas {
  text-align: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ui-form > .ctas .quantity-input {
  display: table;
  width: 75%;
  margin: 10px auto;
  border-bottom: 1px solid #1EB1FA;
}
.ui-form > .ctas .quantity-input > div {
  display: table-row;
}
.ui-form > .ctas .quantity-input > div > div {
  display: table-cell;
  padding: 10px 0;
}
.ui-form > .ctas .quantity-input > div > div:first-child {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #566481;
}
.ui-form > .ctas .quantity-input > div > div:last-child {
  text-align: right;
}
.ui-form > .ctas .quantity-input > div > div:last-child input {
  width: 40px;
  padding: 7px;
  border: 1px solid silver;
  border-radius: 3px;
  font-size: 13px;
  text-overflow: ellipsis;
}
.ui-form > .ctas .calculation {
  margin: 0 0 1rem 0;
}
.ui-form > .ctas .calculation > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem auto 0 auto;
}
.ui-form > .ctas .calculation > div:first-child {
  margin: 0 auto;
}
.ui-form > .ctas .calculation > div > div:first-child {
  flex: 1;
  text-align: left;
}
.ui-form > .ctas .calculation > div > div:last-child {
  text-align: right;
}
.ui-form > .ctas .calculation > div:last-child {
  border: 0;
  padding-bottom: 0;
}
.ui-form > .ctas .calculation > div:last-child > div:first-child {
  font-weight: 700;
}
.ui-form > .ctas .calculation > div:last-child > div:last-child {
  white-space: nowrap;
}
.ui-form > .ctas .calculation .sep {
  height: 0.5px;
  background-color: rgba(72, 84, 105, 0.9);
  margin: 0.75rem auto;
}
.ui-form > .ctas > div.blank {
  width: 55%;
  margin: 0 auto;
  padding: 7px 17px;
  border-radius: 3px;
  color: #707070;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}
.ui-form > .ctas > div.blank:hover {
  background: rgba(0, 0, 0, 0.1);
}
.ui-form > .ctas > div.regular {
  position: relative;
  margin: 0 auto;
  padding: 11px 17px;
  background-color: #00A7FF;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  font-family: Karla, Arial, sans-serif;
}
.ui-form > .ctas > div.regular:hover {
  background: #128ef2;
  cursor: pointer;
}
.ui-form > .ctas > div.regular:focus {
  outline: none;
}
.ui-form > .ctas > div.regular:focus > div.confirmation-button {
  opacity: 1;
}
.ui-form > .ctas > div.regular.disabled {
  opacity: 0.5;
}
.ui-form > .ctas > div.regular.confirmation-button,
.ui-form > .ctas > div.regular.confirmation-loading-button {
  background-color: #0B43A0;
}
.ui-form > .ctas > div.regular.confirmation-button:hover,
.ui-form > .ctas > div.regular.confirmation-loading-button:hover {
  background-color: #082f70;
}
.ui-form > .ctas > div.regular.confirmation-loading-button {
  pointer-events: none;
  cursor: not-allowed;
}
.ui-form > .ctas > div.regular > div.confirmation-button {
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  padding: 11px 0;
  border-radius: 10px;
  background: #0B43A0;
  color: #ffffff;
}
.ui-form > .ctas > div.regular > div.confirmation-loading-button {
  width: 100%;
  position: absolute;
  opacity: 1;
  top: 0px;
  left: 0;
  padding: 11px 0;
  border-radius: 10px;
  background: #0B43A0;
  color: #ffffff;
  pointer-events: none;
  cursor: not-allowed;
}
.ui-form > .ctas > div.regular > div.confirmation-loading-button:hover {
  background-color: #021431;
}
.ui-form .not-enough {
  position: absolute;
  top: -7px;
  color: #e70028 !important;
  font-size: 12px !important;
}
.ui-form .ui-form__terms {
  text-align: center;
  font-size: 0.6875rem;
  padding: 0 0 8px;
}
.ui-tabs {
  background-color: #444b5a;
  text-align: center;
}
.ui-tabs > div {
  display: inline-block;
  color: rgba(255, 255, 255, 0.3);
  font-weight: 600;
  font-size: 12px;
  border-bottom: 3px solid transparent;
  padding: 10px 25px;
  transition: color ease-out 0.3s, border-bottom ease-out 0.3s;
}
.ui-tabs > div.selected {
  color: #ffffff;
  border-bottom: 3px solid #42a5f5;
}
.ui-table {
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: auto;
}
.ui-table.no-scroll {
  max-height: none;
}
.ui-table > table {
  width: 100%;
}
.ui-table > table > thead > tr > th {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
}
.ui-table > table > tbody > tr > td {
  cursor: default;
  padding: 5px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
}
.ui-table > table > tbody > tr > td.name {
  max-width: 150px;
}
.ui-table > table > tbody > tr > td.date {
  max-width: 110px;
}
.ui-table > table > tbody > tr > td.profile {
  width: 200px;
}
.ui-table > table > tbody > tr > td.number {
  width: 100px;
}
.ui-table > table > tbody > tr > td.action {
  text-align: right;
}
.ui-table > table > tbody > tr > td.action > span.link {
  color: rgba(66, 165, 245, 0.6);
  font-weight: 600;
  padding: 0 5px;
  transition: color ease-out 0.3s;
}
.ui-table > table > tbody > tr > td.action > span.link:hover {
  color: #42a5f5;
}
.ui-table > table > tbody > tr:hover > td {
  background-color: rgba(0, 0, 0, 0.1);
}
.ui-table > table > tbody > tr.selected > td {
  background-color: rgba(66, 165, 245, 0.8);
  color: #ffffff;
}
.ui-table.autosize > table {
  width: auto;
  margin: 0 auto;
}
/*
	Auth
*/
.ui-auth {
  padding: 10px;
  text-align: center;
}
.ui-auth > div {
  background-color: #ffffff;
  margin: 0 auto;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.ui-auth > div > div {
  padding: 5px;
}
.ui-auth > div > div input {
  min-width: 200px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px;
}
.ui-dropdown {
  display: inline-block;
  margin: 10px auto;
}
.ui-dropdown > .label {
  background-color: #1eb1fa;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 3px 6px #d6d6d6;
  padding: 5px 15px;
  border-radius: 2px;
}
.ui-dropdown > .dropdown {
  position: relative;
}
.ui-dropdown > .dropdown > div {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  z-index: 5000;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}
.ui-dropdown > .dropdown > div > div {
  padding: 6px 12px;
  color: #989898;
  font-size: 12px;
  font-weight: 500i;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
}
.ui-dropdown > .dropdown > div > div:last-child {
  border-bottom: 0;
}
.ui-dropdown > .dropdown > div > div:hover {
  background-color: rgba(30, 177, 250, 0.1);
}
.ui-dropdown > .dropdown > div > div.selected {
  background-color: rgba(30, 177, 250, 0.1);
}
.layout-homepage .homepage-content > div.--with-padding {
  padding-top: 2rem;
}
@media only screen and (max-width: 768px) {
  .layout-homepage .homepage-content > div.--with-padding {
    padding-top: 1rem;
  }
}
.layout-homepage .--just-padding {
  padding-top: 2rem;
}
@media only screen and (max-width: 768px) {
  .layout-homepage .--just-padding {
    padding-top: 1rem;
  }
}
.bottom-panel {
  position: fixed;
  top: unset;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 51000;
  /* navbar is 50000 */
  /* ng-if show animation */
  /* ng-if hide animation */
}
.bottom-panel.--sidebar {
  z-index: 51021;
  /* must go above sidebar */
}
.bottom-panel.--sticky {
  height: unset;
  background-color: #FFFFFF;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -4px 8px rgba(2, 20, 49, 0.1);
}
.bottom-panel.--sticky > .panel {
  border-radius: inherit;
}
.bottom-panel .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 1;
}
.bottom-panel .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 85%;
  height: auto;
  overflow-y: auto;
  z-index: 2;
  box-shadow: 0 -10px 24px rgba(2, 20, 49, 0.15);
  border-radius: 20px 20px 0 0;
}
.bottom-panel .overlay.--set-height {
  height: 85%;
}
.bottom-panel .panel {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 20px 20px 0 0;
  height: 100%;
  touch-action: pan-y !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
@media only screen and (max-width: 480px) {
  .bottom-panel .panel {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
  }
}
.bottom-panel .panel.--alternate {
  background-color: #00A7FF;
}
.bottom-panel .panel.--alternate > .h-bar {
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none;
}
.bottom-panel .panel > div > div {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.bottom-panel .h-bar {
  width: 10rem;
  height: 3px;
  margin: 1rem auto;
  border-radius: 10px;
  background-color: rgba(2, 20, 49, 0.1);
  box-shadow: none;
  position: sticky;
  top: 0;
  z-index: 1;
}
.bottom-panel .h-bar + div {
  flex: 1;
  overflow: auto;
}
.bottom-panel .h-bar + div > .section__inner:first-child {
  padding-top: 0;
}
.bottom-panel.ng-enter {
  animation: slideUp 0.4s ease-out;
}
.bottom-panel.ng-leave {
  animation: slideDown 0.6s ease-in-out;
}
.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 25rem;
  z-index: 51000;
  /* navbar is 50000 */
  background-color: #F3F3F6;
  border-radius: 20px 0 0 20px;
  /* ng-if show animation */
  /* ng-if hide animation */
}
.side-panel > .bg {
  display: none;
}
.side-panel > .overlay {
  border-radius: 20px 0 0 20px;
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.1);
  border: none;
  z-index: 5001;
  box-sizing: border-box;
}
.side-panel > .overlay.--alternate {
  background-color: #00A7FF;
}
.side-panel > .overlay > .h-bar {
  display: none;
}
.side-panel > .overlay > .panel {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: relative;
}
.side-panel > .overlay > .panel > div {
  height: 100%;
}
.side-panel > .overlay > .panel .panel__content {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.side-panel.ng-enter {
  animation: slideLeft 0.4s ease-out;
  -webkit-animation: slideLeft 0.4s ease-out;
}
.side-panel.ng-leave {
  animation: slideRight 0.3s linear;
  -webkit-animation: slideRight 0.3s linear;
}
.flex-container {
  display: flex;
  align-items: center;
}
.align-start {
  align-items: start;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.flex-column {
  flex-direction: column;
}
.flex-separate {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: 100%;
  overflow: auto;
}
.flex-1 {
  flex: 1;
}
.overflow-x {
  overflow-x: auto;
}
.overflow-y {
  overflow-y: auto;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-italic {
  font-style: italic;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.relative-block {
  display: block;
  position: relative;
}
.mt-xs {
  margin-top: 0.5rem;
}
.mt-small {
  margin-top: 1rem;
}
.mt-medium {
  margin-top: 2rem;
}
.mt-large {
  margin-top: 4rem;
}
.mr-xs {
  margin-right: 0.5rem;
}
.mr-small {
  margin-right: 1rem;
}
.mr-medium {
  margin-right: 2rem;
}
.mb-xs {
  margin-bottom: 0.5rem;
}
.mb-small {
  margin-bottom: 1rem;
}
.mb-medium {
  margin-bottom: 2rem;
}
.mb-large {
  margin-bottom: 4rem;
}
.ml-xs {
  margin-left: 0.5rem;
}
.ml-small {
  margin-left: 1rem;
}
.ml-medium {
  margin-left: 2rem;
}
.pt-small {
  padding-top: 1rem;
}
.pb-small {
  padding-bottom: 1rem;
}
.pr-small {
  padding-right: 1rem;
}
.pr-medium {
  padding-right: 2rem;
}
.pl-medium {
  padding-left: 2rem;
}
.pt-medium {
  padding-top: 2rem;
}
.pb-medium {
  padding-bottom: 2rem;
}
.pt-large {
  padding-top: 4rem;
}
.pb-large {
  padding-bottom: 4rem;
}
.p-small {
  padding: 1rem;
}
.highlight {
  color: #00A7FF;
}
.dark {
  color: #021431;
}
.success {
  color: #2AC86E;
}
.error {
  color: #F2675B;
}
.warning {
  color: #F0C700;
}
.divider {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-color: rgba(2, 20, 49, 0.1);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
}
.divider.--withoutMargin {
  margin: 0;
}
[ng-click],
[ng-mouseup] {
  cursor: pointer;
}
/* For screen readers */
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.full-height {
  height: 100%;
}
ul,
ol,
dl {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-bottom: 0.5rem;
}
li:last-child {
  margin-bottom: 0;
}
.fade-in.ng-enter {
  animation: fadeIn 0.4s ease-out;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}
.disabled .card__imageWrapper {
  opacity: 0.5;
}
.grayscale {
  filter: grayscale(1);
}
.secondary-link {
  color: #99A1AC;
}
.flip {
  transform: rotate(180deg);
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.inactive *:not(.bttn *) {
  color: #BFC4CB;
}
.inactive .bttn.--primary {
  background-color: #BFC4CB;
}
/* Components */
.accordion {
  background-color: #FFFFFF;
  position: relative;
  border-radius: 5px;
}
.accordion + .accordion {
  margin-top: 1rem;
}
.accordion.--maxLimit {
  background-color: #00A7FF;
}
.accordion.--maxLimit * {
  color: #FFFFFF;
}
.accordion__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: unset;
  right: 1.25rem;
  top: 1.25rem;
  width: 15px;
  height: 15px;
}
.accordion__icon {
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-out;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: rotate(0);
}
.accordion__icon.--rotate {
  transform: rotate(90deg);
}
.accordion__inner {
  padding: 1.25rem;
}
.accordion__inner + .accordion__inner {
  border-top: 1px solid rgba(2, 20, 49, 0.1);
}
.accordion__inner + .accordion__inner.ng-enter {
  animation: 0.25s ease-out 0.5s unfold, 0.25s ease-out fadeIn;
}
.accordion__link {
  display: block;
  text-align: right;
  margin-top: 0.5rem;
}
.alert {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid transparent;
  padding: 0.675rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  /*
		Local mixin
	*/
}
.alert.--success {
  border-color: #2AC86E;
}
.alert.--success .alert__icon {
  color: #2AC86E;
}
.alert.--success .alert__message {
  color: #021431;
}
.alert.--warning {
  border-color: #F0C700;
}
.alert.--warning .alert__icon {
  color: #F0C700;
}
.alert.--warning .alert__message {
  color: #021431;
}
.alert.--error {
  border-color: #F2675B;
}
.alert.--error .alert__icon {
  color: #F2675B;
}
.alert.--error .alert__message {
  color: #021431;
}
.alert__icon {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin-right: 0.675rem;
}
.alert__icon.--medium {
  font-size: 1.5rem;
}
.alert__message {
  font-family: Karla, Arial, sans-serif;
}
.animated-digits__column {
  display: inline-block;
  width: 18px;
  height: 30px;
  overflow: hidden;
  text-align: center;
}
.animated-digits__digits {
  transition: transform 1s ease-out;
  transform: translateY(0);
}
.animated-digits__digits > div {
  width: 18px;
  height: 36px;
}
.animatedIcons {
  position: relative;
  width: 20px;
  height: 20px;
}
.animatedIcons + div {
  margin-left: 0.5rem;
  flex: 1;
}
.animatedIcons__stack {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.animatedIcons__circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.animatedIcons__circle.--yellow {
  background-color: #F0C700;
  animation: 0.8s ease-out forwards scaleUp;
}
.animatedIcons__circle.--white {
  background-color: #FFFFFF;
  animation: 0.8s ease-out forwards scaleUp;
}
.animatedIcons__circle.--grey {
  background-color: #7F8998;
  animation: 1s ease-out 0.7s forwards scaleDown;
}
.animatedIcons__icon {
  font-size: 0.75rem;
  position: absolute;
}
.animatedIcons__icon.--white {
  color: #FFFFFF;
}
.animatedIcons__icon.--blue {
  color: #00A7FF;
}
.animatedIcons__icon[data-step="1"] {
  animation: 1s ease-out forwards fadeOutAfter;
}
.animatedIcons__icon[data-step="2"] {
  animation: 1s ease-out 1.5s 1 forwards scaleDownRotate;
}
.animatedIcons__icon[data-step="3"] {
  opacity: 0;
  animation: 1s ease-out 1.25s 1 forwards scaleUpRotate;
}
.animatedRedeem {
  height: 100%;
  width: 100%;
}
.animatedRedeem__scene {
  width: 350px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.animatedRedeem__scene > * {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.animatedRedeem__confettiStack {
  z-index: 1;
}
.animatedRedeem__confetti {
  position: absolute;
}
.animatedRedeem__confetti[data-item="group"] {
  top: -85px;
  animation: 0.5s ease-out moveUpGroup forwards, 0.5s ease-out scaleUpGroup forwards, 1.75s ease-out 1s fadeOut forwards, 1s ease-out 1.5s moveDownGroup forwards;
}
.animatedRedeem__confetti[data-item="blue"],
.animatedRedeem__confetti[data-item="green"],
.animatedRedeem__confetti[data-item="white"] {
  left: 50%;
}
.animatedRedeem__confetti[data-item="blue"] {
  top: -35px;
  width: 1.25rem;
  animation: 0.5s linear halfSpin forwards, 0.5s ease-out moveLeftBlue forwards, 1.75s ease-out 1s fadeOut forwards, 1.5s ease-out 1.5s moveDownBlue forwards;
}
.animatedRedeem__confetti[data-item="green"] {
  top: -35px;
  width: 1.75rem;
  animation: 0.5s linear halfSpin forwards, 0.5s ease-out moveRightGreen forwards, 1.75s ease-out 1s fadeOut forwards, 1.5s ease-out 1.5s moveDownGreen forwards;
}
.animatedRedeem__confetti[data-item="white"] {
  top: -60px;
  width: 2rem;
  animation: 0.5s linear halfSpin forwards, 0.5s ease-out moveRightWhite forwards, 1.75s ease-out 1s fadeOut forwards, 1.5s ease-out 1.5s moveDownWhite forwards;
}
.animatedRedeem__logoWrapper {
  max-width: 100px;
  z-index: 2;
}
.animatedRedeem__logo {
  width: 100%;
  height: auto;
}
.animatedRedeem__copy {
  text-align: center;
  color: #FFFFFF;
  top: 4rem;
}
.animated-onboarding {
  margin-top: 1.5rem;
  /*
		Keyframes
	*/
}
.animated-onboarding * {
  box-sizing: border-box;
}
.animated-onboarding__scene {
  background-color: #00A7FF;
  width: 23.4375rem;
  height: 15.625rem;
}
.animated-onboarding__animation {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /*
			Animation types
		*/
}
.animated-onboarding__animation img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: inline-block;
}
.animated-onboarding__animation.--bounce .animated-onboarding__stack {
  animation: 3s bounceMoveVertical ease-in-out infinite;
}
.animated-onboarding__animation.--bounce .animated-onboarding__shadow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  animation: 3s bounceShadowOpacity ease-in-out infinite;
}
.animated-onboarding__animation.--bounce .animated-onboarding__shadow img {
  width: 18.75rem;
}
.animated-onboarding__animation.--bounce .animated-onboarding__tail {
  z-index: 2;
  bottom: 1.75rem;
  left: 2rem;
  animation: 3s bounceRotateTail ease-in-out infinite;
}
.animated-onboarding__animation.--bounce .animated-onboarding__tail img {
  width: 16.375rem;
}
.animated-onboarding__animation.--bounce .animated-onboarding__body {
  z-index: 3;
}
.animated-onboarding__animation.--bounce .animated-onboarding__body img {
  width: 14.375rem;
}
.animated-onboarding__animation.--bounce .animated-onboarding__tag {
  z-index: 4;
  top: 0;
  right: 3.875rem;
  animation: 3s bounceRotateTag ease-in-out infinite;
}
.animated-onboarding__animation.--bounce .animated-onboarding__tag img {
  width: 9.375rem;
}
.animated-onboarding__animation.--fly-hidden {
  visibility: hidden;
  opacity: 0;
}
.animated-onboarding__animation.--fly {
  visibility: visible;
  opacity: 1;
}
.animated-onboarding__animation.--fly .animated-onboarding__stack {
  transform: translateX(-100%);
  animation: 1.25s flyMoveHorizontal ease-out forwards;
}
.animated-onboarding__animation.--fly .animated-onboarding__motion {
  z-index: 1;
  animation: 1.25s flyMoveMotion ease-out forwards;
}
.animated-onboarding__animation.--fly .animated-onboarding__motion img {
  width: 12.8125rem;
}
.animated-onboarding__animation.--fly .animated-onboarding__tail {
  z-index: 2;
  animation: 1.1s flyMoveRotateTail ease-out forwards;
}
.animated-onboarding__animation.--fly .animated-onboarding__tail img {
  width: 10.625rem;
}
.animated-onboarding__animation.--fly .animated-onboarding__body {
  z-index: 3;
  top: 0;
  right: -0.9375rem;
  animation: 1.25s flyRotateBody ease-out forwards;
}
.animated-onboarding__animation.--fly .animated-onboarding__body img {
  width: 21.125rem;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__coins {
  transform: translate(0, 1.375rem);
}
.animated-onboarding__animation.--sparkle .animated-onboarding__coins img {
  width: 13.75rem;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star1 {
  top: 1.75rem;
  right: 9.875rem;
  animation: 1.5s sparkleStarOpacity infinite;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star1 img {
  width: 1.5625rem;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star2 {
  top: 2.6875rem;
  right: 9.5rem;
  animation: 1.75s sparkleStarOpacity 0.25s infinite;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star2 img {
  width: 1.125rem;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star3 {
  left: 7.8125rem;
  bottom: 5.9375rem;
  animation: 2s sparkleStarOpacity 0.5s infinite;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star3 img {
  width: 1.125rem;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star4 {
  right: 7.125rem;
  top: 3.6875rem;
  animation: 2.25s sparkleStarOpacity 0.75s infinite;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star4 img {
  width: 1.5625rem;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star5 {
  right: 7.625rem;
  bottom: 5.1875rem;
  animation: 2.5s sparkleStarOpacity 1s infinite;
}
.animated-onboarding__animation.--sparkle .animated-onboarding__star5 img {
  width: 1.125rem;
}
.animated-onboarding__stack {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.animated-onboarding__stack > span {
  position: absolute;
}
@keyframes bounceMoveVertical {
  30%,
  100% {
    transform: translate(0, 0);
  }
  90% {
    transform: translate(0, 0.625rem);
  }
}
@keyframes bounceShadowOpacity {
  0%,
  100% {
    opacity: 0.65;
  }
  85%,
  90% {
    opacity: 1;
  }
}
@keyframes bounceRotateTail {
  30%,
  100% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(-2deg);
  }
}
@keyframes bounceRotateTag {
  30%,
  100% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(2deg);
  }
}
@keyframes flyMoveHorizontal {
  100% {
    transform: translateX(0);
  }
}
@keyframes flyMoveMotion {
  0%,
  20% {
    transform: translate(0, -1rem);
  }
  70%,
  100% {
    transform: translate(-4rem, -1rem);
  }
}
@keyframes flyMoveRotateTail {
  0%,
  20% {
    transform: translate(0, 2.0625rem) rotate(2deg);
  }
  70%,
  100% {
    transform: translate(-1.875rem, 2.0625rem) rotate(0deg);
  }
}
@keyframes flyRotateBody {
  0%,
  20% {
    transform: rotate(3deg);
  }
  70%,
  100% {
    transform: rotate(0deg);
  }
}
@keyframes sparkleStarOpacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.barcode__image {
  margin-top: 1rem;
}
.barcode__value {
  font-size: 1rem;
  margin-top: 0.25rem;
}
.breadcrumbs {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}
.breadcrumbs > * {
  font-size: 0.875rem;
  color: #9098A5;
  margin-right: 0.25rem;
}
.breadcrumbs__path a {
  color: #9098A5;
}
.breadcrumbs__current {
  font-weight: 700;
}
.bttn {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: ease;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0;
}
.bttn:hover,
.bttn:focus {
  text-decoration: none;
}
.bttn > span {
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.bttn.--inline {
  display: inline-flex;
  width: auto;
  padding: 0.5rem 1rem;
}
.bttn.--primary {
  background-color: #00A7FF;
  color: #FFFFFF;
}
.bttn.--primary:hover,
.bttn.--primary:focus {
  background-color: #0293EC;
}
.bttn.--primary:active {
  background-color: #0760BB;
}
.bttn.--inverse {
  background-color: #FFFFFF;
  color: #00A7FF;
  border: 1px solid rgba(2, 20, 49, 0.1);
}
.bttn.--inverse:hover,
.bttn.--inverse:focus {
  background-color: #F5F5F7;
  border: 1px solid #F5F5F7;
}
.bttn.--secondary {
  background-color: #0B43A0;
  color: #FFFFFF;
}
.bttn.--secondary:hover,
.bttn.--secondary:focus {
  background-color: #093988;
}
.bttn.--secondaryAction {
  background-color: #99A1AC;
  color: #FFFFFF;
}
.bttn.--secondaryAction:hover,
.bttn.--secondaryAction:focus {
  background-color: #7B8593;
}
.bttn.--secondaryAction:active {
  background-color: #4D5A6D;
}
.bttn.--secondaryAction:disabled,
.bttn.--secondaryAction[disabled] {
  background-color: #DFE2E5;
}
.bttn.--alternateSecondary {
  background-color: #FFFFFF;
  color: #00A7FF;
  border: 1px solid #00A7FF;
}
.bttn.--alternateSecondary:hover,
.bttn.--alternateSecondary:focus {
  background-color: #00A7FF;
  color: #FFFFFF;
}
.bttn.--tertiary {
  background-color: #00A7FF;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
.bttn.--tertiary:hover,
.bttn.--tertiary:focus {
  background-color: #FFFFFF;
  color: #00A7FF;
}
.bttn.--tertiary:hover span,
.bttn.--tertiary:focus span {
  color: #00A7FF;
}
.bttn.--success {
  background-color: #2AC86E;
  color: #FFFFFF;
  cursor: not-allowed;
  pointer-events: none;
}
.bttn:disabled,
.bttn[disabled],
.bttn.--loading {
  cursor: not-allowed;
  pointer-events: none;
}
.bttn:disabled,
.bttn[disabled] {
  opacity: 0.5;
}
.bttn.--loading {
  opacity: 0.8;
}
.bttn.--small {
  border-radius: 0.313rem;
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
}
@media only screen and (max-width: 480px) {
  .bttn.--small {
    padding: 0.3125rem 1rem;
  }
}
.bttn.--medium {
  border-radius: 0.625rem;
  font-size: 1rem;
  padding: 0.75rem 3.125rem;
}
@media only screen and (max-width: 480px) {
  .bttn.--medium {
    padding: 0.75rem 1.125rem;
  }
}
.bttn.--large {
  border-radius: 0.625rem;
  font-size: 1.125rem;
  padding: 0.9375rem 4.6875rem;
}
@media only screen and (max-width: 480px) {
  .bttn.--large {
    padding: 0.9375rem 1.25rem;
  }
}
.bttn.--link,
.bttn.--secondaryLink,
.bttn.--tertiaryLink {
  background-color: transparent;
  justify-content: flex-start;
  font-size: 0.875rem;
  color: #00A7FF;
  font-family: Karla, Arial, sans-serif;
}
.bttn.--link:hover,
.bttn.--link:focus {
  color: #0293EC;
}
.bttn.--link:active {
  color: #0760BB;
}
.bttn.--secondaryLink {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  color: #808997;
  font-family: inherit;
  justify-content: center;
}
.bttn.--secondaryLink:hover,
.bttn.--secondaryLink:focus {
  background-color: #F1F2F3;
  color: #00A7FF;
}
.bttn.--tertiaryLink {
  color: #BFC4CB;
  font-size: 1rem;
  justify-content: center;
}
.bttn.--tertiaryLink:hover,
.bttn.--tertiaryLink:focus {
  text-decoration: underline;
}
.bttn.--ghost {
  background-color: transparent;
  color: #FFFFFF;
}
.bttn.--ghost:hover,
.bttn.--ghost:focus {
  color: #F5F5F7;
}
.bttn.--alternate {
  font-family: Karla, Arial, sans-serif;
}
.bttn.--center {
  justify-content: center;
}
.bttn__icon {
  color: inherit;
}
.bttn__icon.--right {
  margin-left: 0.5rem;
}
.bttn__icon.--left {
  margin-right: 0.5rem;
}
.card {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 3px solid #F3F3F6;
}
.card:hover .card__row--cta {
  border-color: #00A7FF;
  background-color: #00A7FF;
}
.card:hover .card__row--cta * {
  color: #FFFFFF;
}
.card:hover .card__row--filtered {
  border-color: #99A1AC;
  background-color: #99A1AC;
}
.card:hover .card__row--filtered * {
  color: #FFFFFF;
}
.card.--mini {
  border: none;
}
.card.--small {
  max-width: 120px;
  margin: 0 auto;
  border: none;
}
.card.--featured {
  max-width: 315px;
  margin: 0 auto;
  border: none;
}
.card.--giftcards {
  border: none;
}
.card.--giftcards .card__row {
  height: 3.75rem;
}
.card.--giftcards .card__label,
.card.--giftcards .card__label[ng-bind] {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}
.prizeout-widget .card.--selected {
  border: 3px solid #00A7FF;
}
.card__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.card__overlay small {
  position: absolute;
  right: 0.875rem;
  bottom: 0.5rem;
  color: #FFFFFF;
  font-family: Karla, Arial, sans-serif;
}
.card__imageWrapper {
  position: relative;
  margin: 0 auto;
  border-bottom: none;
}
.ui-offer-page .card__imageWrapper {
  border: none;
}
.card__imageWrapper > img {
  width: 100%;
}
.card__imageWrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  border-radius: 7px 7px 10px 10px;
}
.--small .card__imageWrapper > div {
  border-radius: 8px;
}
.--selected .card__imageWrapper > div {
  border-radius: 8px 8px 10px 10px;
}
.--featured .card__imageWrapper > div {
  box-shadow: 0px 3px 6px rgba(214, 214, 214, 0.9);
}
.card__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem;
}
@media only screen and (max-width: 1200px) {
  .card__row {
    padding: 0.5rem;
  }
}
.--featured .card__row {
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
}
.card__row--cta {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
  border-top: 1px solid rgba(2, 20, 49, 0.1);
  background-color: #FFFFFF;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
}
.--selected .card__row--cta {
  border-radius: 0 0 8px 8px;
}
.card__row--filtered {
  border-color: #E5E7EA;
  background-color: #E5E7EA;
}
.card__row--filtered * {
  color: #737D8E;
}
.card__row + .card__row:not(.card__row--cta) {
  padding-top: 0;
}
.card__row .discount-tag {
  margin-right: -0.625rem;
}
@media only screen and (max-width: 1200px) {
  .card__row .discount-tag {
    margin-right: -0.5rem;
  }
}
.card__row small:first-child {
  text-align: left;
  margin-right: 0.15rem;
}
.card__column {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.card__name {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 52%;
  height: 38px;
  text-align: left;
  font-weight: 700;
}
.card__name--full-width {
  width: 100%;
  margin-right: 0;
}
.--mini .card__name {
  width: 100%;
  height: auto;
}
.card__name span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__name span * {
  font-size: inherit;
  color: inherit;
}
.card__icon {
  margin-left: 0.15rem;
}
.card__label,
.card__label[ng-bind] {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 30px;
  overflow: hidden;
  font-weight: 400;
}
.card__label:first-child,
.card__label[ng-bind]:first-child {
  flex: 2;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 480px) {
  .card__label,
  .card__label[ng-bind] {
    height: 40px;
  }
}
/*
	Promo Cards (@TODO, demo code)
*/
.ui-promo-widget {
  height: 88px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
  background-repeat: repeat-x;
  background-position: -12px 0;
  background-size: cover;
  max-width: 100%;
  margin: 12px 8px;
  /* The themes that can be passed as variable */
}
.ui-promo-widget.dark {
  background-color: #60b8fb;
  color: #ffffff;
}
.ui-promo-widget.light {
  background-color: #ffffff;
  color: #021431;
}
.ui-promo-widget > div {
  width: 13.125rem;
}
.ui-promo-widget.promo-design-1 .promo-title {
  margin-bottom: 0.25rem;
}
.ui-promo-widget.promo-design-1.disabled {
  filter: grayscale(100%);
}
.ui-promo-widget.promo-design-2 .promo-title {
  margin-bottom: 0.5rem;
}
.ui-promo-widget.promo-design-2.disabled {
  filter: grayscale(100%);
}
.checkbox {
  position: relative;
  display: flex;
  /* Fake checkbox controller */
}
.checkbox:focus-within .checkbox__control:after {
  border: 2px solid #00A7FF;
}
.checkbox__input {
  /* Hide real input and use fake controller to style */
  /* Checked styles */
  /* Disabled styles */
  /* Disabled styles when not checked */
  /* Disabled styles when checked */
}
.checkbox__input[type="checkbox"]:not(:checked),
.checkbox__input[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.checkbox__input[type="checkbox"]:checked + .checkbox__control {
  /* Show the check when checked */
}
.checkbox__input[type="checkbox"]:checked + .checkbox__control:before {
  opacity: 1;
}
.checkbox__input[type="checkbox"]:not(:checked):disabled + .checkbox__control,
.checkbox__input[type="checkbox"]:checked:disabled + .checkbox__control {
  opacity: 0.5;
}
.checkbox__input[type="checkbox"]:not(:checked):disabled + .checkbox__control:after,
.checkbox__input[type="checkbox"]:checked:disabled + .checkbox__control:after {
  border: 1px solid rgba(2, 20, 49, 0.1);
  cursor: not-allowed;
}
.checkbox__input[type="checkbox"]:not(:checked):disabled + .checkbox__control:before {
  opacity: 0;
}
.checkbox__input[type="checkbox"]:checked:disabled + .checkbox__control:before {
  opacity: 1;
  color: #99A1AC;
}
.checkbox__control {
  user-select: none;
  position: relative;
  padding-left: 1.5rem;
  display: inline-block;
  /* Style the checkbox */
  /* Style the check, hide when not checked */
  /* Default checkbox border color */
}
.checkbox__control:before,
.checkbox__control:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.875rem;
  height: 0.875rem;
  z-index: 0;
  border-radius: 1px;
  margin-top: 0;
  cursor: pointer;
}
.checkbox__control:before {
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
  opacity: 0;
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  color: #00A7FF;
  font-size: 0.675rem;
  border: none;
  left: 4px;
  top: 3px;
}
.checkbox__control:after {
  border: 2px solid rgba(2, 20, 49, 0.25);
  border-radius: 4px;
}
.discount-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background-color: #0B43A0;
  color: #FFFFFF;
  border-radius: 0;
  box-sizing: border-box;
  position: relative;
  height: 30px;
}
.discount-tag.--small {
  height: 20px;
}
.discount-tag.--green {
  background-color: #2AC86E;
}
.discount-tag.--green:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  border: 10px solid #2AC86E;
  border-left-width: 9px;
  border-bottom-width: 10px;
  border-right-width: 2px;
  border-top-width: 10px;
  z-index: 1;
  left: -10px;
  border-left-color: transparent;
}
.discount-tag:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  border: 10px solid #0B43A0;
  border-left-width: 9px;
  border-bottom-width: 10px;
  border-right-width: 2px;
  border-top-width: 10px;
  z-index: 1;
  left: -10px;
  border-left-color: transparent;
}
.discount-tag.--pill {
  border-radius: 25px;
  padding: 8px 16px;
}
.discount-tag.--pill:before {
  border: 0;
}
.discount-tag.--inline {
  display: inline-flex;
  vertical-align: middle;
}
.discount-tag > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.discount-tag__image {
  max-width: 1.125rem;
  margin-right: 0.675rem;
}
.discount-tag__image.--small {
  max-width: 0.875rem;
  margin-right: 0.3125rem;
}
.discount-tag__meta {
  color: #FFFFFF;
  line-height: unset;
}
.entrance-modal {
  padding: 3rem 5rem;
  display: grid;
  gap: 2rem 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "giftcards copy" "giftcards cta";
}
.entrance-modal.--isSuccess {
  padding: 7.5rem 5rem;
}
@media only screen and (max-width: 768px) {
  .entrance-modal.--isSuccess {
    padding: 5rem 2.5rem;
  }
}
@media only screen and (max-width: 640px) {
  .entrance-modal.--isSuccess {
    padding: 3rem 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .entrance-modal.--isSuccess {
    padding: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .entrance-modal {
    padding: 3rem 2.5rem;
  }
}
@media only screen and (max-width: 640px) {
  .entrance-modal {
    grid-template-columns: 1fr;
    grid-template-areas: "copy" "giftcards" "cta";
    gap: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .entrance-modal {
    padding: 2rem;
  }
}
.entrance-modal__grid-item:first-child {
  grid-area: giftcards;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}
@media only screen and (max-width: 640px) {
  .entrance-modal__grid-item:first-child {
    justify-items: center;
  }
}
.entrance-modal__grid-item:nth-child(2) {
  grid-area: copy;
  align-self: end;
}
@media only screen and (max-width: 640px) {
  .entrance-modal__grid-item:nth-child(2) {
    align-self: start;
  }
}
.entrance-modal__grid-item:nth-child(3) {
  grid-area: cta;
}
.entrance-modal__headline {
  font-size: 1.875rem;
  font-size: clamp(1.5rem, (1rem + 1.6667vw), 2.25rem);
  min-height: 0vw;
  margin-bottom: 0.75rem;
}
.entrance-modal__brand {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
@media only screen and (max-width: 640px) {
  .entrance-modal__brand {
    align-items: center;
  }
}
.entrance-modal__giftcard-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 13.4375rem;
  border-radius: 13px;
  margin-bottom: 0.25rem;
  border: 3px solid transparent;
  padding: 3px;
}
.entrance-modal__giftcard-wrapper.--selected {
  border: 3px solid #00A7FF;
}
.--isSuccess .entrance-modal__giftcard-wrapper {
  max-width: 16.25rem;
}
@media only screen and (max-width: 480px) {
  .--isSuccess .entrance-modal__giftcard-wrapper {
    max-width: 9.375rem;
  }
}
@media only screen and (max-width: 480px) {
  .entrance-modal__giftcard-wrapper {
    max-width: 9.375rem;
  }
}
.entrance-modal__giftcard {
  width: 100%;
  border-radius: 10px;
}
.form {
  width: 100%;
}
.form * {
  box-sizing: border-box;
}
.form.--hasMultipleInputs .form__column:not(:last-child) {
  margin-right: 1.25rem;
}
.form.--hasMultipleInputs .form__inputWrapper {
  height: auto;
}
.form.--hasMultipleInputs .form__input {
  height: 3.125rem;
  text-align: center;
  font-size: 2rem;
}
.form.--hasMultipleInputs .form__input:focus:not(:placeholder-shown) {
  border-color: #00A7FF;
}
.form__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.form__row:not(:last-child) {
  margin-bottom: 0.5rem;
}
.form__row.--medium:not(:last-child) {
  margin-bottom: 1.5rem;
}
.form__row.--large:not(:last-child) {
  margin-bottom: 2.5rem;
}
.form__row.--isEdit {
  justify-content: space-between;
}
.form__column {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
}
.form__label {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  color: #021431;
  font-size: 0.75rem;
  padding-left: 0.5rem;
}
.form__inputWrapper {
  display: flex;
  flex-flow: column-reverse;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 3.75rem;
}
.form__inputWrapper.--hasIcon {
  position: relative;
}
.form__inputWrapper.--error .form__input {
  border-color: #F2675B;
}
.form__inputWrapper.--error .form__input:not(:placeholder-shown) + .form__label,
.form__inputWrapper.--error .form__input:placeholder-shown + .form__label,
.form__inputWrapper.--error .form__input:focus + .form__label {
  color: #F2675B;
}
.form__inputWrapper.--isCopied .form__eventFeedback {
  color: #2AC86E;
}
.form__inputWrapper.--isCopied .form__iconWrapper.--hasBackground {
  background-color: #2AC86E;
}
.form__input {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  width: 100%;
  border-radius: 10px;
  padding: 0.5rem;
  height: 2.5rem;
  line-height: 1;
  background-color: #FFFFFF;
  font-size: 1rem;
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  color: #021431;
  border: 2px solid #B7BBC0;
  -webkit-appearance: none;
  /*
			Floating Labels
		*/
}
.--hasIcon .form__input {
  padding: 0.5rem 2rem 0.5rem 0.5rem;
}
.form__input.--disabled {
  background-color: #E5E7EA !important;
  border-color: #E5E7EA !important;
}
[ngclipboard] .form__input {
  cursor: copy;
}
.form__input:placeholder-shown + .form__label {
  cursor: text;
  max-width: calc(100% - 0.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translateY(1.8rem);
  font-size: 1rem;
}
.form__input::-webkit-input-placeholder,
.form__input::placeholder {
  opacity: 0;
}
.form__input:-webkit-autofill {
  -webkit-background-clip: text;
}
.form__input:-webkit-autofill::first-line {
  font-size: 1rem;
}
.form__input:focus + .form__label,
.form__input:not(:placeholder-shown) + .form__label,
.form__input:-webkit-autofill + .form__label {
  transform: translateY(-3px);
  cursor: default;
  font-size: 0.875rem;
  padding-left: 0.3rem;
}
.form__input:focus {
  outline: 0;
  border-color: #00A7FF;
}
.form__input:focus + .form__label {
  color: #00A7FF;
}
.form__input:not(:placeholder-shown) {
  border-color: #B7BBC0;
}
.form__input:not(:placeholder-shown) + .form__label {
  color: #021431;
}
.--hasIcon .form__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 2.5rem;
  right: 0.5rem;
  left: unset;
}
.form__iconWrapper.--hasBackground {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: ease;
  background-color: #99A1AC;
  border-radius: 5px;
  width: 1.875rem;
  height: 1.875rem;
  right: 5px;
  bottom: 5px;
}
.form__iconWrapper.--hasBackground .form__icon {
  color: #FFFFFF;
}
[ngclipboard] .form__iconWrapper.--hasBackground {
  cursor: copy;
}
.form__icon {
  color: #00A7FF;
  font-size: 1rem;
  align-self: flex-end;
}
.--hasIcon .form__icon {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: ease;
  align-self: center;
  line-height: normal;
}
.form__icon.--grey {
  color: #9098A5;
}
.form__icon.--success {
  color: #2AC86E;
}
.form__icon.--error {
  color: #F2675B;
}
.form__caption {
  margin-top: 0.75rem;
}
.form__buttonWrapper {
  margin-top: 2rem;
}
.form__eventFeedback {
  position: absolute;
  top: 0;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Karla, Arial, sans-serif;
}
.form__eventFeedback.--right {
  right: 0.3rem;
}
.form__eventFeedback.--left {
  left: 0.3rem;
}
.form__errorMessage {
  text-align: center;
  margin-bottom: 0.75rem;
}
.form__errorMessage span {
  color: #F2675B;
  font-size: 0.875rem;
  font-weight: 700;
}
.form__select {
  border: 2px solid #B7BBC0;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 0.5rem 1.75rem 0.5rem 0.5rem;
  font-size: 100%;
  appearance: none;
  cursor: pointer;
  background-color: #FFFFFF;
}
.form__select + label {
  transform: translateY(-3px);
  cursor: default;
  font-size: 0.875rem;
  padding-left: 0.3rem;
  color: #021431;
}
.free-giftcard {
  display: grid;
  height: 100%;
  max-width: 25rem;
  grid-template-columns: auto;
  grid-template-rows: 44px 140px auto 195px;
  grid-template-areas: "close" "title" "content" "footer";
}
@media only screen and (max-width: 879px) {
  .free-giftcard {
    grid-template-rows: 140px auto 195px;
    grid-template-areas: "title" "content" "footer";
  }
}
.free-giftcard * {
  box-sizing: border-box;
}
.free-giftcard__close {
  grid-area: close;
  justify-self: end;
  align-self: start;
  color: #BFC4CB;
  padding: 1.875rem;
  font-size: 1rem;
}
@media only screen and (max-width: 879px) {
  .free-giftcard__close {
    padding: 1rem;
  }
}
.free-giftcard__title {
  grid-area: title;
  justify-self: stretch;
  align-self: start;
  padding: 1.875rem;
  text-align: center;
}
@media only screen and (max-width: 879px) {
  .free-giftcard__title {
    padding: 1rem;
  }
}
.free-giftcard__title > h2 {
  margin-bottom: 0.5rem;
}
.free-giftcard__content {
  grid-area: content;
  justify-self: stretch;
  align-self: center;
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 879px) {
  .free-giftcard__content {
    max-height: 240px;
  }
}
.free-giftcard__cards {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}
.free-giftcard__cards > div {
  margin: 0.25rem;
  width: calc(144px + (3px * 2) + (2px * 2));
}
.free-giftcard__cards > div > p {
  padding-left: 0.625rem;
}
.free-giftcard__cards > div.selected .free-giftcard__wrapper {
  border: 2px solid #00A7FF;
}
.free-giftcard__wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  width: calc(144px + (3px * 2) + (2px * 2));
  height: calc(96px + (3px * 2) + (2px * 2));
  padding: 3px;
}
.free-giftcard__inner {
  width: 144px;
  height: 96px;
  position: relative;
}
.free-giftcard__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(2, 20, 49, 0.05);
  border-radius: 10px;
}
.free-giftcard__image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
}
.free-giftcard__footer {
  grid-area: footer;
  justify-self: stretch;
  align-self: end;
  padding: 1.875rem 1.875rem 0.625rem;
}
@media only screen and (max-width: 879px) {
  .free-giftcard__footer {
    padding: 1rem 1rem 0.625rem;
  }
}
.free-giftcard__selected {
  border-radius: 10px;
  border: 1px solid #E5E7EA;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 879px) {
  .free-giftcard__selected {
    margin-bottom: 1.25rem;
  }
}
.free-giftcard__giftcard-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 56px;
  margin-right: 0.75rem;
}
.free-giftcard__giftcard-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.free-giftcard__terms {
  text-align: center;
  margin-top: 0.25rem;
}
.giftcard-image {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 3px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.giftcard-image * {
  box-sizing: border-box;
}
.giftcard-image__wrapper {
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  z-index: 0;
  padding-top: 63.33333%;
}
@supports (aspect-ratio: 300 / 190) {
  .giftcard-image__wrapper {
    aspect-ratio: 1.57894737;
    padding-top: 0;
  }
}
.giftcard-image__wrapper.--circ {
  border-radius: 50%;
  padding-top: 100%;
  border: 2px solid transparent;
}
@supports (aspect-ratio: 1 / 1) {
  .giftcard-image__wrapper.--circ {
    aspect-ratio: 1;
    padding-top: 0;
  }
}
.giftcard-image__wrapper.--circ.--selected {
  border: 2px solid #00A7FF;
}
.giftcard-image__wrapper > .giftcard-image__image,
.giftcard-image__wrapper > .giftcard-image__clear-overlay {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
}
.giftcard-image__image {
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  transform: scale(1.04);
}
.giftcard-image__clear-overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(2, 20, 49, 0.05);
  z-index: 2;
}
.gmap-slider .glider-contain {
  margin-left: -1.875rem;
  width: calc(100% + (1.875rem + 1.875rem));
}
.gmap-slider .glider {
  padding-left: 1.875rem;
}
.gmap-slider .glider-slide:last-child,
.gmap-slider .glider-slide:last-child > .ui-toggles__item,
.gmap-slider .glider-slide--wide > .ui-toggles__item {
  margin-right: 1.875rem;
}
.gmap-slider__title {
  margin-bottom: 0.25rem;
}
.gmap {
  overflow: hidden;
  border: 1px solid #e7e9eb;
  border-radius: 12px;
  display: grid;
  grid-template-rows: 35px 150px;
  grid-template-columns: 100%;
  grid-template-areas: "address" "distance" "map";
}
.gmap__address {
  grid-area: address;
  justify-self: start;
  align-self: center;
  padding: 0 10px;
  font-weight: 700;
}
.gmap__address span {
  display: inline-block;
  width: 14px;
  color: #43a0fb;
}
.gmap__distance {
  grid-area: distance;
  justify-self: start;
  align-self: center;
  padding: 0 10px;
}
.gmap__distance span {
  display: inline-block;
  width: 14px;
  color: #43a0fb;
}
.gmap__map {
  position: relative;
}
.gmap__map iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
.grid {
  display: grid;
  grid-gap: 0.75rem;
  /* One column on the first row, 2 columns following */
  /* One column on the first row, 2 columns following */
}
.grid.--giftcards {
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
}
@media only screen and (max-width: 480px) {
  .grid.--giftcards {
    grid-template-columns: 1fr;
  }
}
.grid.--columnsOneTwo {
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}
@media only screen and (max-width: 480px) {
  .grid.--columnsOneTwo {
    grid-template-columns: 1fr;
    justify-items: stretch;
  }
}
.grid.--columnsOneTwo .grid__item:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 18.75rem;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .grid.--columnsOneTwo .grid__item:first-child {
    grid-column-start: auto;
    grid-column-end: auto;
    max-width: 100%;
  }
}
.grid.--columnsTwo {
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}
@media only screen and (max-width: 480px) {
  .grid.--columnsTwo {
    grid-template-columns: 1fr;
    justify-items: stretch;
  }
}
.hamburger {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border: 0;
  background-color: transparent;
  margin-top: 7px;
}
.hamburger.--isActive .hamburger__inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger.--isActive .hamburger__inner::before,
.hamburger.--isActive .hamburger__inner::after {
  background-color: #FFFFFF;
}
.hamburger.--isActive .hamburger__inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.hamburger.--isActive .hamburger__inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
  width: 100%;
}
.hamburger__wrapper {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 20px;
}
.hamburger__inner,
.hamburger__inner::before,
.hamburger__inner::after {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  position: absolute;
  width: 24px;
  height: 2px;
  border-radius: 3px;
  background-color: #FFFFFF;
}
.hamburger__inner {
  top: 2px;
  display: block;
  margin-top: -2px;
}
.hamburger__inner::before,
.hamburger__inner::after {
  display: block;
  content: '';
}
.hamburger__inner::before {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  top: 8px;
}
.hamburger__inner::after {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  top: 16px;
  width: 50%;
  right: 0;
}
.iconText {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
}
.--maxLimit .iconText {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0;
}
.iconText.--status {
  margin-bottom: 0.25rem;
}
.iconText.--status:last-child {
  margin-bottom: 0;
}
.iconText.--vertical {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.iconText.--vertical .iconText__icon {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  color: #2AC86E;
}
.iconText.--vertical .iconText__text {
  color: #2AC86E;
  font-family: Karla, Arial, sans-serif;
}
.iconText__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
.--status .iconText__iconWrapper {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.25rem;
}
.iconText__iconWrapper.--unlocked,
.iconText__iconWrapper.--yellow {
  background-color: #F0C700;
}
.iconText__iconWrapper.--locked,
.iconText__iconWrapper.--grey {
  background-color: #7F8998;
}
.iconText__iconWrapper.--blue {
  background-color: #00A7FF;
}
.iconText__iconWrapper.--green {
  background-color: #2AC86E;
}
.iconText__iconWrapper.--white {
  background-color: #FFFFFF;
}
.iconText__iconWrapper.--red {
  background-color: #F2675B;
}
.iconText__icon {
  color: #FFFFFF;
  font-size: 0.75rem;
}
.iconText__icon.--yellow {
  color: #F0C700;
}
.iconText__icon.--grey {
  color: #7F8998;
}
.iconText__icon.--blue {
  color: #00A7FF;
}
.iconText__icon.--green {
  color: #2AC86E;
}
.iconText__icon.--white {
  color: #FFFFFF;
}
.iconText__icon.--red {
  color: #F2675B;
}
.iconText__subtitle:not(:first-child) {
  margin-top: 0.5rem;
}
.iconText__steps {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
}
.instagram-posts__title {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 12px 4px 0;
  color: #818896;
  font-size: 1rem;
  font-weight: 400;
}
.instagram-posts__title > span {
  line-height: 1;
}
.instagram-posts__title > span:first-child {
  margin-right: 4px;
}
.instagram-posts__images {
  position: relative;
}
.instagram-posts__images > .ratio-guide {
  width: 100%;
}
.instagram-posts__images > .image-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 13.4375rem;
  justify-content: stretch !important;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "grid_0 grid_1 grid_2" "grid_3 grid_4 grid_5";
}
@media only screen and (max-width: 768px) {
  .instagram-posts__images > .image-grid {
    height: 16.083125rem;
  }
}
@media only screen and (max-width: 480px) {
  .instagram-posts__images > .image-grid {
    height: 13.193125rem;
  }
}
.instagram-posts__images > .image-grid > div {
  justify-self: stretch;
  align-self: stretch;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.instagram-posts__images > .image-grid > .grid_0 {
  grid-area: grid_0;
  border-radius: 12px 0 0 0;
}
.instagram-posts__images > .image-grid > .grid_1 {
  grid-area: grid_1;
}
.instagram-posts__images > .image-grid > .grid_2 {
  grid-area: grid_2;
  border-radius: 0 12px 0 0;
}
.instagram-posts__images > .image-grid > .grid_3 {
  grid-area: grid_3;
  border-radius: 0 0 0 12px;
}
.instagram-posts__images > .image-grid > .grid_4 {
  grid-area: grid_4;
}
.instagram-posts__images > .image-grid > .grid_5 {
  grid-area: grid_5;
  border-radius: 0 0 12px 0;
}
.message {
  text-align: center;
  margin-bottom: 3.5rem;
}
.message__header {
  margin-bottom: 0.75rem;
  color: #021431;
  font-size: 40px;
  font-weight: 500;
}
.message__subheader {
  color: #02143180;
  font-size: 18px;
  font-weight: 500;
}
.message__title,
.message__heading {
  margin-bottom: 0.5rem;
}
.modal {
  background-color: #FFFFFF;
  border-radius: 20px;
  position: relative;
  color: #021431;
  height: 100%;
  max-height: 85vh;
  overflow: auto;
  box-shadow: 0 0 10px rgba(2, 20, 49, 0.15);
}
.modal.--fullScreen {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-height: 100vh;
  z-index: 52000;
  top: 0;
  left: 0;
  position: fixed;
  border-radius: 0;
  text-align: center;
}
.modal.--fullScreen .modal__inner {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}
.modal.--iframe .modal__inner {
  position: relative;
  padding: 0;
}
.modal.--handleError .modal__body {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: center;
  height: 70%;
}
.modal.--handleError .modal__body * {
  color: #F2675B;
}
.modal.--handleError .modal__graphics {
  justify-content: flex-end;
}
.modal.--handleError .modal__copy {
  align-self: center;
}
.modal.--blueBackground {
  background: #00A7FF;
}
.modal.--blueBackground * {
  color: #FFFFFF;
}
.modal__inner {
  padding: 2.5rem;
}
@media only screen and (max-width: 480px) {
  .modal__inner {
    padding: 1.5rem;
  }
}
.modal__close {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 52001;
  background-color: unset;
  border: unset;
}
.modal__close:hover {
  background-color: #e5e7ea;
  border-radius: 0.5rem;
}
.modal__close > i {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  font-size: 1.75rem;
}
.modal__close > i:hover {
  color: #808997;
}
@media only screen and (max-width: 480px) {
  .modal__close {
    top: 0.5rem;
    right: 0.5rem;
  }
}
.modal__header {
  margin-top: 1.25rem;
}
.bottom-panel .modal__header {
  margin-top: 0;
}
.modal__subtitle {
  margin-top: 0.75rem;
}
.modal__caption {
  margin-top: 1rem;
}
.modal__body {
  margin-top: 2rem;
}
.modal__body p:not(:last-child) {
  margin-bottom: 1rem;
}
.modal__body > img {
  max-width: 70%;
  border-radius: 8px;
}
.modal__buttonWrapper {
  margin-top: 2rem;
}
.modal__buttonWrapper .bttn + .bttn {
  margin-top: 1rem;
}
.modal__graphics {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.modal__graphics i {
  font-size: 6.25rem;
  z-index: 2;
  position: relative;
}
.modal__graphics img {
  z-index: 1;
  position: relative;
}
.modal__graphics img:first-child {
  transform: translateY(1rem);
}
.modal__graphics img:last-child {
  transform: rotate(180deg) translateY(1rem);
}
.navigation {
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  transform: translateY(-100%);
  opacity: 0;
}
.navigation__list {
  padding-left: 0;
  margin: 0;
}
.navigation__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.4s ease-out;
  margin-top: -30px;
}
.navigation__item:not(:last-child) {
  padding-bottom: 0;
}
.navigation.--expanded {
  transform: translateY(0);
  opacity: 1;
}
.navigation.--expanded .navigation__item {
  margin-top: 0;
}
.navigation.--expanded .navigation__item:not(:last-child) {
  padding-bottom: 1.2rem;
}
.navigation__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 2rem;
}
.navigation__icon {
  color: #00A7FF;
  font-size: 0.8rem;
  display: inline-block;
  width: 100%;
  max-width: 1rem;
}
.navigation__link {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  font-size: 1rem;
  font-weight: 700;
  font-family: Karla, Arial, sans-serif;
  color: #021431;
  cursor: pointer;
}
.navigation__link:hover {
  color: #00A7FF;
}
ui-points-alert {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.points-alert {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  background-color: #FEF9E5;
  border-radius: 10px;
}
.points-alert__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.points-alert__message {
  margin-left: 0.625rem;
}
.progressBar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.progressBar.--horizontal {
  height: 0.875rem;
}
.progressBar.--semiCircle {
  align-items: flex-start;
  justify-content: center;
  margin: 2rem 0 1rem;
  min-height: 6.25rem;
}
.progressBar.--withPendingText {
  min-height: calc(6.25rem + 1.25rem);
}
.progressBar__column {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.progressBar__column:last-child:not(:only-child) {
  margin-left: 1rem;
  align-self: center;
}
.progressBar__circWrapper {
  position: relative;
  width: 11.5625rem;
  height: 6.25rem;
}
.progressBar__circWrapper + .progressBar__textWrapper {
  position: absolute;
  left: 0;
  top: 2.5rem;
  width: 100%;
  text-align: center;
}
.progressBar__circ {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.progressBar__circ.--pending {
  z-index: 1;
}
.progressBar__circ > svg {
  width: 100%;
  height: 100%;
  stroke-linecap: round;
}
.progressBar__bar {
  border-radius: 10px;
  flex: 1;
  height: 100%;
  margin-right: 1rem;
  background-color: #CCD0D6;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.progressBar__progress {
  transition-delay: 0s;
  transition-duration: 1.5s;
  transition-property: width;
  transition-timing-function: ease;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: inherit;
}
.progressBar__progress.--pending {
  z-index: 2;
  background-color: #83CEFB;
}
.progressBar__progress.--current {
  z-index: 3;
}
.progressBar__progress.--unlocked {
  background-color: #F0C700;
}
.progressBar__progress.--locked {
  background-color: #00A7FF;
}
.progressBar__textWrapper {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.progressBar__text {
  color: #021431;
}
.progressBar__text.--target {
  font-size: 0.75rem;
  text-align: center;
}
.progressBar__text.--next {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.675rem;
  line-height: 1.2;
}
.progressBar__text.--next > span {
  font-weight: 400;
}
.progressBar__text.--light {
  color: #9098A5;
  text-transform: none;
}
.progressBar__text.--pending {
  color: #00A7FF;
  margin-top: 0.25rem;
}
.progressBar__text.--pending.--small {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.progressBar__label {
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.radio:not(:last-child) {
  margin-bottom: 0.75rem;
}
.radio:hover .radio__input[type="radio"]:not(:checked) + label:after {
  background: #00A7FF;
  opacity: 0.25;
  transform: scale(1);
}
.radio__input[type="radio"]:checked,
.radio__input[type="radio"]:not(:checked) {
  left: -9999px;
  position: absolute;
}
.radio__input[type="radio"]:checked + label,
.radio__input[type="radio"]:not(:checked) + label {
  color: #CCD0D5;
  cursor: pointer;
  line-height: 20px;
  padding-left: 28px;
  position: relative;
  user-select: none;
}
.radio__input[type="radio"]:checked + label:before,
.radio__input[type="radio"]:not(:checked) + label:before {
  background: #FFFFFF;
  border: 3px solid #BFC4CB;
  border-radius: 100%;
  content: '';
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  box-sizing: border-box;
}
.radio__input[type="radio"]:checked + label:after,
.radio__input[type="radio"]:not(:checked) + label:after {
  background: #00A7FF;
  border-radius: 100%;
  content: '';
  height: 10px;
  left: 5px;
  position: absolute;
  top: 5px;
  transition: all 0.2s ease;
  width: 10px;
}
.radio__input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.radio__input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.rewardsCard__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  background-color: #FFFFFF;
  border-radius: 5px;
  height: 100px;
}
.rewardsCard__item:not(:last-child) {
  margin-bottom: 1.25rem;
}
.rewardsCard__imageWrapper {
  position: relative;
  margin: 0;
  width: auto;
  height: 100%;
}
.rewardsCard__imageWrapper > img {
  width: auto;
  height: 100%;
}
.rewardsCard__imageWrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  border-radius: 4px 5px 5px 4px;
}
.rewardsCard__copy {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  flex: 2;
}
.rewardsCard__subheading {
  margin-bottom: 0.35rem;
}
.rewardsCard__description {
  margin-bottom: 0.5rem;
}
.rewardsCard__description > * {
  font-size: 0.75rem;
  line-height: 1.2;
}
.section {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* Use for side/bottom panels to control width */
}
.section * {
  box-sizing: border-box;
}
.section.--alternate {
  background-color: #F3F3F6;
}
.section.--loading {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.section.--loading .section__icon {
  color: #00A7FF;
  font-size: 1.5rem;
  margin-bottom: 25px;
}
.section__wrapper {
  position: relative;
  z-index: 51012;
  /* Needs to go over navigation */
}
.section__inner {
  padding: 1.875rem 1.875rem 1rem;
}
@media only screen and (max-width: 480px) {
  .section__inner {
    padding: 1.25rem 1.25rem 0.625rem;
  }
}
.section__inner.--alternate {
  background-color: #F3F3F6;
  border-top: 1px solid rgba(2, 20, 49, 0.1);
}
.section__inner.--animatedRedeem {
  height: 250px;
  background-color: #00A7FF;
  animation: fadeIn 0.25s ease-out;
}
.section__content {
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
  /* To separate the inner content within content section */
}
.--expanded .section__content {
  max-width: 31.25rem;
}
.section__content.--separate {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}
.section__content + .section__content {
  margin-top: 1.875rem;
}
@media only screen and (max-width: 480px) {
  .section__content + .section__content {
    margin-top: 1.25rem;
  }
}
.section__header {
  margin-bottom: 1rem;
}
.section__header.--small {
  margin-bottom: 1.5rem;
}
.section__header.--small-margin {
  margin-bottom: 0.5rem;
}
.section__header.--medium {
  margin-bottom: 2rem;
}
.section__header.--large {
  margin-bottom: 3rem;
}
.section__header.flex-separate {
  margin-bottom: 0;
}
.section__heading:not(:only-child),
.section__title:not(:only-child),
.section__paragraph:not(:only-child) {
  margin-bottom: 1rem;
}
.section__heading {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.section__paragraph {
  font-size: 1rem;
}
.section__subheading:not(:last-child) {
  margin-bottom: 0.5rem;
}
.section__subheading + .section__subheading {
  display: block;
}
.section__imageWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.section__imageWrapper.--medium {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.section__image {
  width: 100%;
  max-width: 245px;
  height: auto;
}
.section__image.--small {
  max-width: 100px;
}
.section__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.section__column.--one {
  flex: 1;
}
.section__column.--two {
  flex: 2;
}
.section__column.--three {
  flex: 3;
}
.section__bullets {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.section__bullet {
  list-style-type: disc;
  margin-bottom: 0.25rem;
  font-family: Karla, Arial, sans-serif;
}
.section__buttonWrapper {
  margin-top: 1.5rem;
}
.select-dropdown {
  font-size: 0.875rem;
}
.select-dropdown__dropdown {
  transition-delay: 0s;
  transition-duration: 0.8s;
  transition-property: all;
  transition-timing-function: ease-out;
  background: #FFFFFF;
  border-radius: 0.5rem;
  display: inline-block;
  font-family: Karla, Arial, sans-serif;
  font-weight: 500;
  outline: none;
  position: relative;
  user-select: none;
  width: 100%;
  border: 1px solid #F5F5F7;
}
.select-dropdown__dropdown.--change-border-radius {
  border-radius: 0.5rem 0.5rem 0 0;
}
.select-dropdown__selected-option-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  padding: 0.75rem 0.875rem;
}
.select-dropdown__selected-option-container.--small {
  padding: 0.5rem;
}
.select-dropdown__selected-option-container.--withoutOptions {
  pointer-events: none;
  cursor: default;
}
.select-dropdown__image {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 0.35rem;
}
.select-dropdown__label {
  color: #021431;
  font-family: Karla, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
}
.select-dropdown__icon {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  color: #CCD0D5;
  margin-left: 0.625rem;
}
.select-dropdown__icon.--rotated {
  transform: rotate(-180deg);
}
.select-dropdown__ul {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  background: #FFFFFF;
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  list-style: none;
  overflow-y: auto;
  position: absolute;
  margin-top: 0;
  max-height: 0;
  padding: 0;
  width: inherit;
  z-index: 100;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.select-dropdown__ul::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 8px;
}
.select-dropdown__ul::-webkit-scrollbar {
  display: none;
}
.select-dropdown__ul:hover::-webkit-scrollbar {
  display: initial;
  width: 0.5rem;
}
.select-dropdown__ul.--show {
  max-height: 250px;
}
.select-dropdown__li {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  color: #99A1AC;
  cursor: pointer;
  font-family: Karla, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0.5625rem 0.875rem;
  position: relative;
}
.select-dropdown__li.--selected {
  color: #021431;
  pointer-events: none;
}
.select-dropdown__li.--small {
  padding: 0.5rem;
}
.select-dropdown__li:hover {
  background: #F5F5F7;
}
.select-dropdown__li:not(:last-child):after {
  background: #F5F5F7;
  bottom: 0;
  content: ' ';
  height: 0.0625rem;
  left: 0;
  position: absolute;
  transform: translateX(0.875rem);
  width: calc(100% - 1.75rem);
}
.sidebar-arrow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  border: unset;
  background-color: unset;
  border-radius: 0.5rem;
}
.sidebar-arrow:hover {
  background-color: #e5e7ea;
}
.sidebar-arrow__icon {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #021431;
  font-size: 0.75rem;
}
.sidebarCallout {
  display: flex;
  flex-flow: nowrap;
  align-items: flex-start;
  justify-content: center;
}
.sidebarCallout.--create-account {
  align-items: center;
  background-color: #021431;
  padding: 1rem;
  border-radius: 12px;
  color: #FFFFFF;
}
.sidebarCallout.--create-account .sidebarCallout__message {
  margin-right: 0.75rem;
}
.sidebarCallout.--alternate {
  background-color: #F3F3F6;
  flex-flow: column;
  align-items: stretch;
  margin-bottom: 2rem;
  border-radius: 5px;
}
.sidebarCallout.--alternate > div {
  padding: 1rem 1.25rem;
}
.sidebarCallout__header {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}
.sidebarCallout__pointsWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.sidebarCallout__points {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.sidebarCallout__body {
  border-top: 1px solid rgba(2, 20, 49, 0.1);
}
.sidebarCallout__title {
  margin-left: 0.5rem;
}
.sidebarCallout__subtitle {
  margin-bottom: 0.25rem;
}
.sidebarCallout__description {
  margin-bottom: 0.5rem;
}
.sidebarCallout__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  margin-top: 0.25rem;
}
.--alternate .sidebarCallout__iconWrapper {
  margin-top: 0;
}
.sidebarCallout__iconWrapper:hover .sidebarCallout__icon {
  color: #00A7FF;
}
.sidebarCallout__icon {
  font-size: 1.25rem;
  width: 100%;
  max-width: 1.5rem;
}
.sign-in-error {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  margin: 2rem auto;
}
.sign-in-error__alert {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #F2675B;
  padding: 0.675rem;
  border-radius: 10px;
}
.sign-in-error__icon {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #F2675B;
  font-size: 1rem;
  margin-right: 0.675rem;
}
.sign-in-error__message {
  margin: 1rem 0;
  display: block;
}
.sign-in-error__buttonWrapper {
  margin-top: 2.875rem;
}
.sticky.side-panel-layout {
  padding-top: 0;
  transition: 0.3s all ease-out;
}
@media only screen and (max-width: 480px) {
  .sticky.side-panel-layout.--isSticky {
    padding-top: 0.75rem;
  }
}
.sticky__component {
  top: 0;
  position: sticky;
  background-color: #F3F3F6;
  z-index: 2;
  border-bottom: 2px solid transparent;
  transition: 0.3s top ease-out, 0.3s border ease-out;
}
.--isSticky .sticky__component {
  /*
					If padding/margin changes when it gets sticky, it'll bounce. padding/margin should be on the element itself
				*/
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: -3rem;
  margin-right: -3rem;
  /*padding-top: 1rem;*/
  border-color: rgba(2, 20, 49, 0.1);
}
@media only screen and (min-width: 1600px) {
  .--isSticky .sticky__component {
    /*
					If padding/margin changes when it gets sticky, it'll bounce. padding/margin should be on the element itself
				*/
    padding-left: 9rem;
    padding-right: 9rem;
    margin-left: -9rem;
    margin-right: -9rem;
  }
}
@media only screen and (max-width: 1440px) {
  .--isSticky .sticky__component {
    /*
					If padding/margin changes when it gets sticky, it'll bounce. padding/margin should be on the element itself
				*/
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .--isSticky .sticky__component {
    /*
					If padding/margin changes when it gets sticky, it'll bounce. padding/margin should be on the element itself
				*/
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .--isSticky .sticky__component {
    /*
					If padding/margin changes when it gets sticky, it'll bounce. padding/margin should be on the element itself
				*/
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media only screen and (max-width: 480px) {
  .--isSticky .sticky__component {
    top: -3rem;
  }
}
.--isSticky .sticky__component > div {
  padding: 0;
}
.sticky__slider {
  /*padding-bottom: 1rem;*/
}
.tabs {
  position: relative;
  background-color: #F3F3F6;
}
.tabs__list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2rem;
  left: 0;
  width: 100%;
  text-align: center;
}
.tabs__item {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: ease;
  flex: 1;
  color: #021431;
  font-family: Karla, Arial, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  /* Determine width of bar by amount of tab items */
}
.tabs__item.--current {
  color: #00A7FF;
}
.tabs__item:first-child.--current ~ .tabs__bar {
  left: 0;
}
.tabs__item:nth-child(2).--current {
  /* Determine how far the bar should move to reach the 2nd item, depending on if there are 2 or 3 tabs */
}
.--2 .tabs__item:nth-child(2).--current ~ .tabs__bar {
  left: calc(100% / 2);
}
.--3 .tabs__item:nth-child(2).--current ~ .tabs__bar {
  left: calc(100% / 3);
}
.tabs__item:nth-child(3).--current ~ .tabs__bar {
  left: calc(calc(100% / 3) * 2);
}
.--2 .tabs__item ~ .tabs__bar {
  width: calc(100% / 2);
}
.--3 .tabs__item ~ .tabs__bar {
  width: calc(100% / 3);
}
.tabs__bar {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: left;
  transition-timing-function: ease;
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 5px;
  background-color: #00A7FF;
}
@media only screen and (max-width: 480px) {
  .tabs__bar {
    bottom: -9px;
  }
}
.tabs__section {
  /* Fade in when switching tabs */
}
.tabs__section.ng-enter {
  animation: fadeIn 0.4s ease-out;
  -webkit-animation: fadeIn 0.4s ease-out;
}
.tabs-list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.tabs-list__tab {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: ease;
  flex: 1;
  color: #99A1AC;
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0;
  background-color: unset;
  border: none;
}
.tabs-list__tab.--current {
  color: #00A7FF;
  background-color: #E4F5FF;
  padding: 0.5rem;
  border-radius: 10px;
  font-weight: 700;
}
.tabs-modern__header {
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 5200;
  padding: 1.875rem 1.875rem 1rem;
}
@media only screen and (max-width: 480px) {
  .tabs-modern__header {
    padding: 1.25rem;
  }
}
.tabs-modern__header + .section__inner {
  border: none;
}
.tabs-modern__iconWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: unset;
  top: 1rem;
  right: 1rem;
  width: 20px;
  height: 20px;
}
.tabs-modern__iconWrapper:hover .sidebar__icon {
  color: #00A7FF;
}
.tabs-modern__icon {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #021431;
  font-size: 1.65rem;
  width: 100%;
  height: 100%;
}
.tabs-modern__heading {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.tabs-modern__list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.tabs-modern__item {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: ease;
  flex: 1;
  color: #99A1AC;
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0;
}
.tabs-modern__item.--current {
  color: #00A7FF;
  background-color: #E4F5FF;
  padding: 0.5rem;
  border-radius: 10px;
  font-weight: 700;
}
.tabs-modern__section {
  /* Fade in when switching tabs */
}
.tabs-modern__section.ng-enter {
  animation: fadeIn 0.4s ease-out;
  -webkit-animation: fadeIn 0.4s ease-out;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  height: 22px;
  line-height: normal;
  width: 40px;
}
.toggle-switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-switch__input:checked + .toggle-switch__slider {
  background-color: #2AC86E;
}
.toggle-switch__input:checked + .toggle-switch__slider:before {
  background: #FFFFFF;
  transform: translateX(17px);
}
.toggle-switch__input:checked + .toggle-switch__slider:after {
  content: none;
  display: block;
  left: 7px;
  text-align: left;
}
.toggle-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E5E7EA;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.toggle-switch__slider:before {
  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: ease;
  background-color: #CCD0D5;
  border-radius: 50%;
  bottom: 4px;
  content: '';
  height: 17px;
  left: 3px;
  position: absolute;
  top: 2px;
  width: 17px;
}
.toggle-switch__slider:after {
  color: #FFFFFF;
  content: none;
  font-size: 14px;
  right: 7px;
  top: 2px;
  text-align: right;
  position: absolute;
}
.tooltip {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #BFC4CB;
  width: 1.5rem;
  height: 1.5rem;
}
.tooltip__link {
  color: #BFC4CB;
  text-decoration: none;
}
.tooltip__link:hover {
  text-decoration: none;
}
.tooltip__icon {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
}
.shopify-slider__title {
  color: #818896;
  margin-bottom: 5px;
}
.shopify-product {
  justify-content: stretch !important;
  border: 1px solid #e7e9eb;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 5rem;
  /* 60px + 10px margin around */
  grid-template-rows: auto auto;
  grid-template-areas: "image title" "image cost";
}
.shopify-product__image {
  grid-area: image;
  justify-self: stretch;
  align-self: stretch;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 7px;
  height: 60px;
  margin: 10px;
}
.shopify-product__title {
  grid-area: title;
  justify-self: start;
  align-self: end;
  padding: 0 10px;
  padding-bottom: 5px;
  font-weight: 600;
}
.shopify-product__cost {
  grid-area: cost;
  justify-self: start;
  align-self: start;
  padding: 0 10px;
}
/* Modules */
/*
	Overrides for glider.js package
*/
.glider::-webkit-scrollbar,
.glider::-webkit-scrollbar-thumb {
  display: none !important;
}
@supports (scrollbar-width: none) {
  .glider {
    scrollbar-width: none;
  }
}
.glider.--small .glider-slide {
  min-width: 76px !important;
}
.glider.--small .glider-slide:not(:first-child) {
  margin-left: 0.5rem;
}
.glider .glider-slide {
  min-width: 150px !important;
}
.glider .glider-slide--wide {
  min-width: 175px !important;
  width: 100% !important;
  margin-right: 1.25rem;
  margin-left: 0.5rem;
}
.glider .glider-slide:not(:first-child) {
  margin-left: 1rem;
}
@media only screen and (max-width: 480px) {
  .glider .glider-slide:not(:first-child) {
    margin-left: 0.25rem;
  }
}
.glider ~ .glider-prev {
  left: 1.5rem;
}
.glider ~ .glider-prev.--sidebar {
  left: 0.9rem;
}
.glider ~ .glider-prev i {
  margin-right: 2px;
}
.glider ~ .glider-next {
  right: 1.5rem;
  left: auto;
}
.glider ~ .glider-next.--sidebar {
  right: 0.9rem;
}
.glider ~ .glider-next i {
  margin-left: 2px;
}
.glider ~ .glider-prev,
.glider ~ .glider-next {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;
  background-color: rgba(2, 20, 49, 0.25);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.glider ~ .glider-prev > i,
.glider ~ .glider-next > i {
  color: #FFFFFF;
}
.glider ~ .glider-prev.disabled,
.glider ~ .glider-next.disabled {
  opacity: 0;
}
.glider ~ .glider-prev:hover,
.glider ~ .glider-next:hover,
.glider ~ .glider-prev:focus,
.glider ~ .glider-next:focus {
  background-color: rgba(2, 20, 49, 0.35);
}
@media only screen and (max-width: 768px) {
  .glider ~ .glider-prev,
  .glider ~ .glider-next {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
.glider ~ .glider-prev.--small,
.glider ~ .glider-next.--small {
  width: 1.8rem;
  height: 1.8rem;
}
.glider ~ .glider-dots {
  position: absolute;
  top: -1rem;
  right: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .glider ~ .glider-dots {
    right: 1rem;
  }
}
.glider ~ .glider-dots > .glider-dot {
  background: #E5E7EA;
  width: 6px;
  height: 6px;
  margin: 2px;
}
.glider ~ .glider-dots > .glider-dot:hover,
.glider ~ .glider-dots > .glider-dot:focus,
.glider ~ .glider-dots > .glider-dot:active,
.glider ~ .glider-dots > .glider-dot.active {
  background: #BFC4CB;
}
/*
	Glider.js default css
*/
.glider,
.glider-contain {
  margin: 0 auto;
  position: relative;
}
.glider,
.glider-track {
  transform: translateZ(0);
}
.glider-dot,
.glider-next,
.glider-prev {
  border: 0;
  padding: 0;
  user-select: none;
  outline: 0;
}
.glider-contain {
  width: 100%;
}
.glider {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
.glider-track {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 1;
}
.glider.draggable {
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}
.glider.draggable .glider-slide img {
  user-select: none;
  pointer-events: none;
}
.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.glider-slide {
  user-select: none;
  justify-content: center;
  align-content: center;
  width: 100%;
  min-width: 150px;
}
.glider-slide img {
  max-width: 100%;
}
.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}
.glider-next,
.glider-prev {
  position: absolute;
  background: 0 0;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  left: -23px;
  top: 30%;
  cursor: pointer;
  color: #666;
  opacity: 1;
  line-height: 1;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.glider-next:focus,
.glider-next:hover,
.glider-prev:focus,
.glider-prev:hover {
  color: #ccc;
}
.glider-next {
  right: -23px;
  left: auto;
}
.glider-next.disabled,
.glider-prev.disabled {
  opacity: 0.25;
  color: #666;
  cursor: default;
}
.glider-hide {
  opacity: 0;
}
.glider-dots {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}
.glider-dot {
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: #ccc;
  width: 12px;
  height: 12px;
  margin: 7px;
}
.glider-dot:focus,
.glider-dot:hover {
  background: #ddd;
}
.glider-dot.active {
  background: #a89cc8;
}
@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }
  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 99px;
    background-color: rgba(156, 156, 156, 0.25);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
  }
}
.hero {
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  background-color: transparent;
}
@media only screen and (min-width: 1800px) {
  .hero {
    height: 350px;
  }
}
@media only screen and (max-width: 1440px) {
  .hero {
    height: 220px;
  }
}
@media only screen and (max-width: 640px) {
  .hero {
    height: 150px;
  }
}
.hero > div {
  display: flex;
  height: 280px;
}
@media only screen and (min-width: 1800px) {
  .hero > div {
    height: 350px;
  }
}
@media only screen and (max-width: 1440px) {
  .hero > div {
    height: 220px;
  }
}
@media only screen and (max-width: 640px) {
  .hero > div {
    height: 150px;
  }
}
.hero__content {
  background-color: #FFFFFF;
  border-radius: 180px 10px 10px 180px;
  height: 100%;
  width: 70%;
  margin: 0 0 0 auto;
  box-sizing: border-box;
}
.hero__content a {
  text-decoration: underline;
  margin-top: 0.5rem;
}
@media only screen and (max-width: 480px) {
  .hero__content a {
    margin-top: 0.25rem;
  }
}
@media only screen and (min-width: 1600px) {
  .hero__content {
    width: 60%;
  }
}
@media only screen and (max-width: 1200px) {
  .hero__content {
    width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .hero__content {
    width: 85%;
  }
}
@media only screen and (max-width: 879px) {
  .hero__content {
    width: 80%;
  }
}
@media only screen and (max-width: 640px) {
  .hero__content {
    width: 70%;
    border-radius: 130px 9px 9px 130px;
  }
}
@media only screen and (max-width: 375px) {
  .hero__content {
    width: 78%;
  }
}
.hero__content.--alternate {
  background-color: transparent;
  border-radius: unset;
  height: 100%;
  width: 50%;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .hero__content.--alternate {
    width: 55%;
  }
}
.hero__inner {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 2rem 2rem 5rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1440px) {
  .hero__inner {
    padding: 2rem 1rem 2rem 4rem;
  }
}
@media only screen and (max-width: 480px) {
  .hero__inner {
    padding: 1rem 1rem 1rem 2.5rem;
  }
}
.--alternate .hero__inner {
  padding: 2rem;
}
@media only screen and (max-width: 640px) {
  .--alternate .hero__inner {
    padding: 1rem;
  }
}
.hero__logo {
  height: 100%;
  max-height: 25px;
  width: auto;
  max-width: 100%;
}
@media only screen and (max-width: 1440px) {
  .hero__logo {
    margin-bottom: 0.5rem;
    max-height: 20px;
  }
}
.hero__logo img {
  width: auto;
  height: 100%;
  display: inline-block !important;
}
@media only screen and (max-width: 1440px) {
  .hero__heading {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .hero__heading {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}
.hero__subheading {
  line-height: 1.3;
}
@media only screen and (max-width: 1440px) {
  .hero__subheading {
    display: none;
  }
}
.hero__thumbnail {
  height: calc(25% - .39rem) !important;
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  overflow: hidden;
}
.hero__thumbnail:not(:last-child) {
  margin-bottom: 0.5rem;
}
.hero__thumbnail img {
  max-width: 100%;
  max-height: 100%;
}
.hero .hero-slider {
  cursor: pointer;
}
.hero .hero-slider-for {
  width: calc(calc(100% - 175px) - 4px - .5rem);
  max-width: 1322px;
  height: 280px;
  overflow: hidden;
  margin-right: 0.5rem;
}
@media only screen and (max-width: 1200px) and (min-width: 961px) {
  .hero .hero-slider-for {
    width: calc(calc(100% - 100px) - 4px - .5rem);
  }
}
@media only screen and (min-width: 1800px) {
  .hero .hero-slider-for {
    height: 350px;
  }
}
@media only screen and (max-width: 1440px) {
  .hero .hero-slider-for {
    width: 100%;
    margin-right: 0;
    height: 220px;
  }
}
@media only screen and (max-width: 640px) {
  .hero .hero-slider-for {
    height: 150px;
  }
}
.hero .hero-slider-for.--no-thumbs {
  width: 100%;
  margin-right: 0;
}
.hero .hero-slider-for .slick-slide {
  height: 280px;
}
@media only screen and (min-width: 1800px) {
  .hero .hero-slider-for .slick-slide {
    height: 350px;
  }
}
@media only screen and (max-width: 1440px) {
  .hero .hero-slider-for .slick-slide {
    width: 100% !important;
    max-width: min(72vw, 580px) !important;
    margin-right: 1rem;
    will-change: transform;
    height: 220px;
  }
}
@media only screen and (max-width: 640px) {
  .hero .hero-slider-for .slick-slide {
    height: 150px;
  }
}
@media only screen and (max-width: 480px) {
  .hero .hero-slider-for .slick-slide {
    width: 100% !important;
    max-width: min(78vw, 350px) !important;
    margin-right: 1rem;
  }
}
.hero .hero-slider-for .slick-list {
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border-radius: 0;
}
.hero .hero-slider-for .slick-track {
  width: 100%;
  box-sizing: border-box;
}
.hero .hero-slider-for .slick-track .hero__backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  outline: none;
  border-radius: 10px;
}
.hero .hero-slider-for .slick-track .hero__backgroundImage.--promo {
  background-position: center right;
}
@media only screen and (max-width: 480px) {
  .hero .hero-slider-for .slick-track .hero__backgroundImage.--promo {
    background-position-x: -50px;
  }
}
.hero .hero-slider-nav {
  width: calc(175px + 4px + .5rem);
  height: 280px;
}
@media only screen and (min-width: 1800px) {
  .hero .hero-slider-nav {
    height: 350px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 961px) {
  .hero .hero-slider-nav {
    width: calc(100px + 4px + .5rem);
  }
}
@media only screen and (max-width: 1440px) {
  .hero .hero-slider-nav {
    display: none;
  }
}
.hero .hero-slider-nav.--no-thumbs {
  display: none;
}
.hero .hero-slider-nav .slick-track,
.hero .hero-slider-nav .slick-list {
  height: 280px !important;
}
@media only screen and (min-width: 1800px) {
  .hero .hero-slider-nav .slick-track,
  .hero .hero-slider-nav .slick-list {
    height: 350px !important;
  }
}
.hero .hero-slider-nav .slick-list,
.hero .hero-slider-nav .slick-track,
.hero .hero-slider-nav .slick-slide {
  box-sizing: border-box;
}
.hero .hero-slider-nav .slick-slide {
  outline: none;
  border: 2px solid transparent;
}
.hero .hero-slider-nav .slick-slide.slick-current {
  border-color: #00A7FF;
}
.vertical-offers__heading {
  margin-bottom: 0.75rem;
}
.vertical-offers .offers {
  display: grid;
  gap: 1.75rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(11.25rem, 1fr));
}
@media only screen and (min-width: 1441px) {
  .vertical-offers .offers {
    gap: 1.75rem 3.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .vertical-offers .offers {
    grid-template-columns: repeat(auto-fill, minmax(9.875rem, 1fr));
    gap: 0.25rem;
  }
}
.is-small-cards .card {
  border: none !important;
}
.is-small-cards .card__row + .card__row {
  display: none;
}
.is-small-cards .card__row {
  flex-flow: column;
  align-items: flex-start;
  padding-left: 3px;
}
.is-small-cards .card__name {
  width: 100%;
  height: auto;
  order: 2;
  align-items: flex-start;
}
.is-small-cards .card__row .discount-tag {
  margin-bottom: 4px;
}
.is-small-cards .card__row .discount-tag::before {
  border-right-width: 10px;
  border-left-width: 0;
  left: unset;
  border-right-color: transparent;
  right: -10px;
}
.is-small-cards .giftcard-image {
  padding: 3px 3px 0;
}
@media only screen and (max-width: 480px) {
  .is-small-cards .vertical-offers .offers {
    grid-template-columns: repeat(auto-fill, minmax(6.875rem, 1fr));
    gap: 0.875rem 0.25rem;
  }
  .is-small-cards .vertical-offers .offers .glider .glider-slide:not(:first-child) {
    margin-left: 0.25rem;
  }
}
.horizontal-offers,
.promo-offers {
  background-color: #F3F3F6;
}
.horizontal-offers .glider-contain,
.promo-offers .glider-contain {
  margin-left: -3rem;
  width: calc(100% + (3rem + 2.25rem));
}
.horizontal-offers .glider,
.promo-offers .glider {
  padding-left: 3rem;
}
.horizontal-offers .glider-slide:last-child,
.promo-offers .glider-slide:last-child,
.horizontal-offers .glider-slide:last-child > .ui-toggles__item,
.promo-offers .glider-slide:last-child > .ui-toggles__item,
.horizontal-offers .glider-slide--wide > .ui-toggles__item,
.promo-offers .glider-slide--wide > .ui-toggles__item {
  margin-right: 2.25rem;
}
@media only screen and (min-width: 1600px) {
  .horizontal-offers .glider-contain,
  .promo-offers .glider-contain {
    margin-left: -9rem;
    width: calc(100% + (9rem + 5rem));
  }
  .horizontal-offers .glider,
  .promo-offers .glider {
    padding-left: 9rem;
  }
  .horizontal-offers .glider-slide:last-child,
  .promo-offers .glider-slide:last-child,
  .horizontal-offers .glider-slide:last-child > .ui-toggles__item,
  .promo-offers .glider-slide:last-child > .ui-toggles__item,
  .horizontal-offers .glider-slide--wide > .ui-toggles__item,
  .promo-offers .glider-slide--wide > .ui-toggles__item {
    margin-right: 5rem;
  }
}
@media only screen and (max-width: 1440px) {
  .horizontal-offers .glider-contain,
  .promo-offers .glider-contain {
    margin-left: -2rem;
    width: calc(100% + (2rem + 2.5rem));
  }
  .horizontal-offers .glider,
  .promo-offers .glider {
    padding-left: 2rem;
  }
  .horizontal-offers .glider-slide:last-child,
  .promo-offers .glider-slide:last-child,
  .horizontal-offers .glider-slide:last-child > .ui-toggles__item,
  .promo-offers .glider-slide:last-child > .ui-toggles__item,
  .horizontal-offers .glider-slide--wide > .ui-toggles__item,
  .promo-offers .glider-slide--wide > .ui-toggles__item {
    margin-right: 2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .horizontal-offers .glider-contain,
  .promo-offers .glider-contain {
    margin-left: -1.875rem;
    width: calc(100% + (1.875rem + 1.875rem));
  }
  .horizontal-offers .glider,
  .promo-offers .glider {
    padding-left: 1.875rem;
  }
  .horizontal-offers .glider-slide:last-child,
  .promo-offers .glider-slide:last-child,
  .horizontal-offers .glider-slide:last-child > .ui-toggles__item,
  .promo-offers .glider-slide:last-child > .ui-toggles__item,
  .horizontal-offers .glider-slide--wide > .ui-toggles__item,
  .promo-offers .glider-slide--wide > .ui-toggles__item {
    margin-right: 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .horizontal-offers .glider-contain,
  .promo-offers .glider-contain {
    margin-left: -1rem;
    width: calc(100% + (1rem + 1rem));
  }
  .horizontal-offers .glider,
  .promo-offers .glider {
    padding-left: 1rem;
  }
  .horizontal-offers .glider-slide:last-child,
  .promo-offers .glider-slide:last-child,
  .horizontal-offers .glider-slide:last-child > .ui-toggles__item,
  .promo-offers .glider-slide:last-child > .ui-toggles__item,
  .horizontal-offers .glider-slide--wide > .ui-toggles__item,
  .promo-offers .glider-slide--wide > .ui-toggles__item {
    margin-right: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .horizontal-offers .glider-contain,
  .promo-offers .glider-contain {
    margin-left: -0.5rem;
    width: calc(100% + (0.5rem + 1rem));
  }
  .horizontal-offers .glider,
  .promo-offers .glider {
    padding-left: 0.5rem;
  }
  .horizontal-offers .glider-slide:last-child,
  .promo-offers .glider-slide:last-child,
  .horizontal-offers .glider-slide:last-child > .ui-toggles__item,
  .promo-offers .glider-slide:last-child > .ui-toggles__item,
  .horizontal-offers .glider-slide--wide > .ui-toggles__item,
  .promo-offers .glider-slide--wide > .ui-toggles__item {
    margin-right: 1rem;
  }
}
.horizontal-offers__heading,
.promo-offers__heading {
  margin-bottom: 0.75rem;
}
.promo-offers.--full-width .glider-contain,
.gmap-slider.--full-width .glider-contain,
.promo-offers.--full-width .glider-slide,
.gmap-slider.--full-width .glider-slide,
.promo-offers.--full-width .glider-track,
.gmap-slider.--full-width .glider-track {
  width: 100% !important;
}
.promo-offers.--full-width .glider-contain,
.gmap-slider.--full-width .glider-contain,
.promo-offers.--full-width .glider-slide,
.gmap-slider.--full-width .glider-slide {
  margin: 0 !important;
}
.promo-offers.--full-width .glider,
.gmap-slider.--full-width .glider {
  padding: 0 !important;
}
.progress-bar {
  border-radius: 5px;
  width: 80%;
  height: 20px;
  margin: 10px auto;
  border: 1px solid #5069A0;
  background-color: #E9E9E9;
  display: table;
}
.progress-bar > .progress {
  display: table-cell;
  height: 20px;
  border-radius: 5px;
  background-color: #1EB1FA;
  min-width: 5px;
}
.progress-bar > .progress.with-pending {
  border-radius: 5px 0 0 5px;
}
.progress-bar > .pending {
  display: table-cell;
  background-image: linear-gradient(45deg, #1eb1fa 25%, #e9e9e9 25%, #e9e9e9 50%, #1eb1fa 50%, #1eb1fa 75%, #e9e9e9 75%, #e9e9e9 100%);
  background-size: 10px 10px;
  height: 20px;
  border-radius: 0 5px 5px 0;
}
.progress-bar > .empty {
  display: table-cell;
  height: 20px;
}
.progress-bar > .error {
  display: table-cell;
  background-color: #D50000;
  height: 20px;
  border-radius: 0 5px 5px 0;
}
.giftcard-preview {
  padding: 40px 30px;
  text-align: center;
}
.giftcard-preview > div {
  display: inline-block;
  width: 225px;
  height: 120px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.giftcard-preview > div > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.giftcard-preview > div > .info {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.giftcard-preview > div > .info > .name {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
}
.giftcard-preview > div > .info > .gc {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
}
.ui-offer-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
}
.ui-offer-page .merchant-content-wrapper {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  /* 
			Set max height for top part (card, title, desc) to scroll content
		*/
}
@media only screen and (max-width: 879px) {
  .ui-offer-page .merchant-content-wrapper {
    padding: 0 1.5rem 1.5rem;
  }
}
@media only screen and (max-width: 375px) {
  .ui-offer-page .merchant-content-wrapper {
    max-height: 36vh;
  }
}
@media only screen and (max-width: 768px) and (max-height: 1024px) and (orientation: portrait) {
  .ui-offer-page .merchant-content-wrapper {
    max-height: 50vh;
  }
}
@media only screen and (max-width: 375px) and (max-height: 812px) and (orientation: portrait) {
  .ui-offer-page .merchant-content-wrapper {
    max-height: 43vh;
  }
}
@media only screen and (max-width: 414px) and (max-height: 736px) and (orientation: portrait) {
  .ui-offer-page .merchant-content-wrapper {
    max-height: 40vh;
  }
}
@media only screen and (max-width: 375px) and (max-height: 666px) and (orientation: portrait) {
  .ui-offer-page .merchant-content-wrapper {
    max-height: 31vh;
  }
}
.ui-offer-page .merchant-content-wrapper .gift-card-container {
  width: 240px;
  height: 100%;
  position: relative;
  background-position: top center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  padding: 7px;
}
.ui-offer-page .merchant-content-wrapper .gift-card-container .merchant-info {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 10px auto;
  color: #ffffff;
  font-weight: 500;
}
.ui-offer-page .merchant-content-wrapper .gift-card-container .merchant-info .card-title {
  width: 60%;
  height: 41px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.ui-offer-page .merchant-content-wrapper .gift-card-container .merchant-info .card-value {
  font-size: 18px;
}
.ui-offer-page .merchant-content-wrapper .gift-card-wrapper {
  width: 165px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 13px;
}
.ui-offer-page .merchant-content-wrapper .gift-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 30px;
  height: 104px;
  width: 165px;
  display: table;
  margin: 0 auto 10px auto;
  left: 50%;
  border-radius: 13px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.ui-offer-page .merchant-content-wrapper .gift-card > div {
  display: table-cell;
  padding: 15px 0;
  vertical-align: middle;
  text-align: center;
  border-radius: 13px;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .card-value {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 7px;
  font-size: 24px;
  font-weight: 700;
  color: #1eb1fa;
  text-align: center;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .sep {
  width: 50px;
  height: 2px;
  background-color: #ffffff;
  margin: 7px auto;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .text {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 10px 35px;
  line-height: 20px;
}
.ui-offer-page .merchant-content-wrapper .gift-card > div > .more {
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.ui-offer-page .description-container {
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  overflow: hidden;
}
.ui-offer-page .description-container .merchant-description {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  height: 28px;
  text-align: left;
  overflow: hidden;
}
.ui-offer-page .description-container .merchant-description a {
  color: #ffffff;
}
.ui-offer-page .description-container .merchant-description a:hover {
  text-decoration: underline;
}
.ui-offer-page .description-container .merchant-description.show {
  overflow: visible;
  height: auto;
}
.ui-offer-page .description-container .read-more,
.ui-offer-page .description-container .read-less {
  margin: 3px 0;
  font-size: 12px;
}
.ui-offer-page .description-container .read-more:hover,
.ui-offer-page .description-container .read-less:hover {
  text-decoration: underline;
}
.ui-offer-widget-B {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
}
.ui-offer-widget-B > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.ui-offer-widget-B > .name {
  position: absolute;
  top: 17px;
  left: 17px;
}
.ui-offer-widget-B > .name > .merchant {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}
.ui-offer-widget-B > .name > .gc {
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
}
.ui-offer-widget-B > .info {
  position: absolute;
  bottom: 17px;
  left: 17px;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #f3941d;
  border-radius: 3px;
  padding: 3px 10px;
}
.ui-offer-widget {
  text-align: center;
  /* ng-if show animation */
  /* ng-if hide animation */
}
.ui-offer-widget.ng-enter {
  transition: 0.3s ease-out all;
  opacity: 0;
}
.ui-offer-widget.ng-enter.ng-enter-active {
  opacity: 1;
}
.ui-offer-widget.ng-leave {
  transition: 0.3s ease-out all;
  opacity: 1;
}
.ui-offer-widget.ng-leave.ng-leave-active {
  opacity: 0;
}
.powered-title {
  background-color: #fdfdff;
  padding: 14px;
  text-align: center;
}
.powered-title.shadow {
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.powered-title > div {
  display: inline-block;
  margin: 0 auto;
}
.powered-title > div > .text {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: rgba(64, 64, 64, 0.9);
  text-shadow: 0 1px 2px #d6d6d6;
}
.powered-title > div > .powered {
  font-size: 7px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(112, 112, 112, 0.9);
  text-align: right;
  vertical-align: middle;
}
.powered-title > div > .powered > img {
  margin-left: 4px;
  vertical-align: middle;
}
.cashout_confirmation {
  background-color: #0F567A;
  padding: 15px;
}
.cashout_confirmation > .title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}
.cashout_confirmation > .text {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.cashout_confirmation > .debug {
  font-size: 9px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 10px 0;
}
.invalid-country > div {
  text-align: center;
  padding: 30px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #0F79AF;
}
.pointsSlider {
  overflow: hidden;
}
.pointsSlider * {
  box-sizing: border-box;
}
.pointsSlider__scrollList {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.pointsSlider__scrollItem {
  margin-bottom: 0;
  padding: 0 1rem 0 0;
}
.pointsSlider__scrollItem:focus {
  outline: none;
}
@media only screen and (max-width: 768px) {
  .redeem-homepage {
    min-height: calc(100vh - 46px);
  }
}
.redeem-homepage * {
  box-sizing: border-box;
}
.redeem-homepage__inner {
  max-width: 1080px;
  margin: 0 auto;
  padding: 1.25rem;
}
@media only screen and (max-width: 768px) {
  .redeem-homepage__inner {
    width: 100%;
  }
}
.redeem-homepage__auth {
  background-color: #FFFFFF;
  max-width: 31.25rem;
  margin: 0 auto;
  padding: 1rem;
}
@media only screen and (max-width: 768px) {
  .redeem-homepage__auth {
    height: calc(100vh - 46px);
    overflow: auto;
  }
}
.redeem-homepage__header {
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 5200;
}
.redeem-homepage__header .redeem-homepage__inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .redeem-homepage__header > div {
    flex: 1;
  }
}
.redeem-homepage__heading {
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .redeem-homepage__heading {
    margin-bottom: 0.25rem;
  }
}
.redeem-homepage__description {
  margin-bottom: 1.25rem;
}
@media only screen and (max-width: 768px) {
  .redeem-homepage__description {
    margin-bottom: 1.125rem;
  }
}
.value-slider {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /*
		https://github.com/angular-slider/angularjs-slider
		slider overrides
	*/
}
@media only screen and (max-width: 1200px) {
  .value-slider {
    flex-flow: column;
    align-items: stretch;
  }
}
@media only screen and (max-width: 879px) {
  .value-slider {
    flex-flow: row nowrap;
  }
}
@media only screen and (max-width: 640px) {
  .value-slider {
    flex-flow: column;
    align-items: stretch;
  }
}
.value-slider__message {
  margin-right: 2rem;
}
@media only screen and (min-width: 1441px) {
  .value-slider__message {
    margin-right: 4rem;
  }
}
@media only screen and (min-width: 1600px) {
  .value-slider__message {
    margin-right: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .value-slider__message {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 879px) {
  .value-slider__message {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 640px) {
  .value-slider__message {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.value-slider__object {
  flex: 1;
  max-width: 650px;
}
.value-slider .rzslider .rz-bar-wrapper {
  max-width: 100%;
}
.value-slider .rzslider .rz-bar {
  background-color: #83CEFB;
  height: 5px;
}
.value-slider .rzslider .rz-selection {
  background-color: #00A7FF;
}
.value-slider .rzslider .rz-tick {
  width: 0;
  height: 0;
}
.value-slider .rzslider .rz-tick-value {
  color: #99A1AC;
  font-size: 0.875rem;
  top: -28px;
  margin-left: -4px;
}
@media only screen and (min-width: 1441px) {
  .value-slider .rzslider .rz-tick-value {
    font-size: 0.85rem;
  }
}
@media only screen and (min-width: 1600px) {
  .value-slider .rzslider .rz-tick-value {
    margin-right: 5rem;
  }
}
@media only screen and (max-width: 480px) {
  .value-slider .rzslider .rz-tick-value {
    margin-left: -1px;
  }
}
.value-slider .rzslider .rz-pointer {
  width: 24px;
  height: 24px;
  top: -10px;
  margin-left: 0;
  background-color: #00A7FF;
  box-shadow: 0 2px 4px rgba(2, 20, 49, 0.2);
}
.value-slider .rzslider .rz-pointer::after {
  display: none;
}
.value-slider .rzslider .rz-pointer:focus,
.value-slider .rzslider .rz-pointer.rz-active {
  box-shadow: 0px 0px 0px 2px #83CEFB;
  outline: none;
}
.value-slider .rzslider .rz-bubble {
  visibility: hidden !important;
  display: none !important;
}
.value-slider .rzslider .rz-selected:last-child .rz-tick-value,
.value-slider .rzslider .rz-selected.--current .rz-tick-value {
  color: #00A7FF;
  font-weight: 600;
}
/*
	RZSlider default css
*/
/*! angularjs-slider - v7.0.0 - 
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervi.eu>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> - 
 https://github.com/angular-slider/angularjs-slider - 
 2019-02-23 */
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  user-select: none;
}
.rzslider.noanimate * {
  transition: none !important;
}
.rzslider.with-legend {
  margin-bottom: 40px;
}
.rzslider[disabled] {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}
.rzslider[disabled] .rz-draggable {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-selection {
  background: #8b91a2;
}
.rzslider[disabled] .rz-tick {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-tick.rz-selected {
  background: #8b91a2;
}
.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}
.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
  transition: all linear 0.3s;
}
.rzslider .rz-draggable {
  cursor: move;
}
.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.rzslider .rz-bar-wrapper.rz-transparent .rz-bar {
  background: transparent;
}
.rzslider .rz-bar-wrapper.rz-left-out-selection .rz-bar {
  background: #df002d;
}
.rzslider .rz-bar-wrapper.rz-right-out-selection .rz-bar {
  background: #03a688;
}
.rzslider .rz-selection {
  z-index: 2;
  background: #0db9f0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  transition: background-color linear 0.3s;
}
.rzslider .rz-restricted {
  z-index: 3;
  background: #ff0000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.rzslider .rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #0db9f0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  transition: all linear 0.3s;
}
.rzslider .rz-pointer:after {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  content: '';
}
.rzslider .rz-pointer:hover:after {
  background-color: #ffffff;
}
.rzslider .rz-pointer.rz-active {
  z-index: 4;
}
.rzslider .rz-pointer.rz-active:after {
  background-color: #451aff;
}
.rzslider .rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default;
  transition: all linear 0.3s;
}
.rzslider .rz-bubble.rz-limit {
  color: #55637d;
  transition: none;
}
.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}
.rzslider .rz-ticks-values-under .rz-tick-value {
  top: auto;
  bottom: -32px;
}
.rzslider .rz-tick {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-left: 11px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%;
  transition: background-color linear 0.3s;
}
.rzslider .rz-tick.rz-selected {
  background: #0db9f0;
}
.rzslider .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0);
}
.rzslider .rz-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0);
}
.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline;
}
.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px;
}
.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%;
}
.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important;
}
.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px;
}
.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
}
.rzslider.rz-vertical .rz-tick {
  margin-top: 11px;
  margin-left: auto;
  vertical-align: middle;
}
.rzslider.rz-vertical .rz-tick-value {
  top: auto;
  left: 24px;
  transform: translate(0, -28%);
}
.rzslider.rz-vertical .rz-tick-legend {
  top: auto;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%);
}
.rzslider.rz-vertical .rz-ticks-values-under .rz-tick-value {
  right: 24px;
  bottom: auto;
  left: auto;
}
.value-selector__box {
  background-color: #e6f4fe;
  padding: 5px;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.sticky__slider .value-selector__box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.value-selector__label {
  display: inline-block;
  color: #021431;
  font-size: 1rem;
  font-weight: 400;
  padding: 5px;
  align-self: center;
}
.value-selector__sep {
  display: inline-block;
  background-color: #9bd4fd;
  width: 2px;
  height: 18px;
  align-self: center;
}
.value-selector__value {
  display: inline-block;
  color: #021431;
  font-size: 1rem;
  font-weight: 600;
  padding: 5px;
  align-self: center;
}
.value-selector__edit {
  display: inline-block;
  color: #40BDFF;
  font-size: 0.9rem;
  font-weight: 400;
  align-self: center;
}
.value-selector-box .hover-button {
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: 0.3s background-color ease-out;
  background-color: rgba(0, 0, 0, 0);
}
.value-selector-box .hover-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.value-selector-box .hover-button span {
  align-self: center;
}
.website-homepage {
  background-color: #FFFFFF;
}
.website-homepage * {
  box-sizing: border-box;
}
.website-homepage__section {
  height: 100%;
  min-height: 100vh;
  display: grid;
}
.website-homepage__section.--signup {
  position: relative;
  grid-template-columns: repeat(2, 1fr);
}
.website-homepage__section.--signup .website-homepage__content:first-child .website-homepage__inner {
  margin: 2rem calc((3.375rem * 2) + 2rem) 2rem 2rem;
}
@media only screen and (max-width: 879px) {
  .website-homepage__section.--signup .website-homepage__content:first-child .website-homepage__inner {
    margin: 0;
  }
}
.website-homepage__section.--signup .website-homepage__content.--alternate .website-homepage__inner {
  margin: 2rem 2rem 2rem calc((3.375rem * 2) + 2rem);
}
@media only screen and (max-width: 879px) {
  .website-homepage__section.--signup .website-homepage__content.--alternate .website-homepage__inner {
    margin: 0;
  }
}
.website-homepage__section.--login {
  grid-template-columns: 40% 60%;
}
.website-homepage__section.--login .website-homepage__content .website-homepage__inner {
  padding: 2rem;
}
.website-homepage__section.--login .website-homepage__content.--alternate {
  background: url('https://assets.prizeout.com/secure-widget/secure-widget-banner-repeat.svg') repeat top center;
  background-size: cover;
}
@media only screen and (max-width: 879px) {
  .website-homepage__section.--login .website-homepage__content.--alternate {
    display: none;
  }
}
@media only screen and (max-width: 879px) {
  .website-homepage__section.--signup,
  .website-homepage__section.--login {
    grid-template-columns: 1fr;
  }
}
.website-homepage__content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #FFFFFF;
}
.website-homepage__content.--alternate {
  background-color: #00A7FF;
}
@media only screen and (max-width: 879px) {
  .website-homepage__content {
    flex-flow: column;
    justify-content: flex-start;
  }
}
.website-homepage__inner {
  width: 100%;
  max-width: 29rem;
}
@media only screen and (max-width: 879px) {
  .website-homepage__inner {
    padding: 2rem;
  }
}
.website-homepage__header {
  margin-bottom: 1rem;
}
.website-homepage__logo {
  margin-bottom: 2.5rem;
  max-width: 7.5rem;
  width: 100%;
  display: inline-block;
}
@media only screen and (max-width: 480px) {
  .website-homepage__logo {
    margin-bottom: 1rem;
  }
}
.website-homepage__h1 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 480px) {
  .website-homepage__h1 {
    margin-bottom: 0.5rem;
  }
}
.website-homepage__sticky-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 879px) {
  .website-homepage__sticky-image {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    position: static;
    transform: translate(0, 0);
    background-color: #00A7FF;
  }
}
.website-homepage__sticky-image img {
  max-width: 31.25rem;
  width: 100%;
}
.website-homepage__info:not(:last-child) {
  margin-bottom: 2.5rem;
}
.website-homepage__info h3,
.website-homepage__info p,
.website-homepage__info small {
  color: #FFFFFF;
  font-family: Karla, Arial, sans-serif;
}
.website-homepage__info h3 {
  margin-bottom: 0.25rem;
}
.website-homepage__switch-country {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.5rem;
}
.website-homepage__flag-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.25rem;
}
.website-homepage__flag {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.website-root {
  min-height: calc(100vh - (46px + 4rem));
  /*
		Local mixins
	*/
}
.website-root__section {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: grid;
  min-height: calc(100vh - (46px + 4rem));
  /*
			iOS-specific
		*/
}
@media only screen and (min-width: 1600px) {
  .website-root__section {
    padding: 2rem 9rem;
  }
}
@media only screen and (max-width: 1024px) {
  .website-root__section {
    padding: 2rem 1.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .website-root__section {
    padding: 2rem 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .website-root__section {
    padding: 1.25rem 1.25rem;
  }
}
@supports (-webkit-touch-callout: none) {
  .website-root__section {
    min-height: -webkit-fill-available;
  }
}
.website-root__section.--adaptive {
  min-height: initial;
}
.sidebar-opened .website-root__section {
  width: calc(100% - 25rem);
}
@media only screen and (max-width: 879px) {
  .sidebar-opened .website-root__section {
    width: calc(100% - 19rem);
  }
}
@media only screen and (max-width: 640px) {
  .sidebar-opened .website-root__section {
    width: 100%;
  }
}
.sidebar-expanded .website-root__section {
  width: calc(100% - 31.25rem);
}
@media only screen and (max-width: 879px) {
  .sidebar-expanded .website-root__section {
    width: 100%;
  }
}
.website-root__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background: url('https://assets.prizeout.com/secure-widget/secure-widget-welcome-background.svg') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  width: 100%;
}
.website-root__content > div {
  flex: 1;
}
.website-root__content > div:first-child {
  padding: 3.125rem;
}
.website-root__content > div:nth-child(2) {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
}
.website-root__content.--solid > div:first-child {
  padding: 3.125rem;
}
.website-root__content.--solid > div:nth-child(2) {
  align-self: flex-start;
}
@media only screen and (max-width: 768px) {
  .website-root__content {
    flex-flow: column;
    align-items: stretch;
    padding: 3rem 3rem 0;
  }
  .website-root__content.--solid {
    padding: 0 3rem 3rem;
  }
  .website-root__content.--solid > div:first-child {
    order: 2;
    padding: 0;
  }
  .website-root__content.--solid > div:nth-child(2) {
    order: 1;
    align-self: center;
    align-items: flex-start;
  }
  .website-root__content.--solid > div:nth-child(2) .website-root__graphic {
    margin: 0 0 1rem;
  }
  .website-root__content > div:first-child,
  .website-root__content > div:nth-child(2) {
    padding: 0;
    justify-content: center;
  }
  .website-root__content .website-root__copy {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .website-root__content {
    padding: 1rem 2rem;
  }
}
.sidebar-opened .website-root__content > div {
  flex: 1;
}
.sidebar-opened .website-root__content > div:first-child {
  padding: 3.125rem;
}
.sidebar-opened .website-root__content > div:nth-child(2) {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
}
.sidebar-opened .website-root__content.--solid > div:first-child {
  padding: 3.125rem;
}
.sidebar-opened .website-root__content.--solid > div:nth-child(2) {
  align-self: flex-start;
}
@media only screen and (max-width: 1200px) {
  .sidebar-opened .website-root__content {
    flex-flow: column;
    align-items: stretch;
    padding: 3rem 3rem 0;
  }
  .sidebar-opened .website-root__content.--solid {
    padding: 0 3rem 3rem;
  }
  .sidebar-opened .website-root__content.--solid > div:first-child {
    order: 2;
    padding: 0;
  }
  .sidebar-opened .website-root__content.--solid > div:nth-child(2) {
    order: 1;
    align-self: center;
    align-items: flex-start;
  }
  .sidebar-opened .website-root__content.--solid > div:nth-child(2) .website-root__graphic {
    margin: 0 0 1rem;
  }
  .sidebar-opened .website-root__content > div:first-child,
  .sidebar-opened .website-root__content > div:nth-child(2) {
    padding: 0;
    justify-content: center;
  }
  .sidebar-opened .website-root__content .website-root__copy {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .sidebar-opened .website-root__content {
    padding: 1rem 2rem;
  }
}
.website-root__content.--solid {
  background: #BFE9FF;
}
.website-root__content.--solid .website-root__copy * {
  color: #021431;
}
.website-root__content.--solid .website-root__graphic {
  margin: 0 2.25rem 1rem 0;
}
@media only screen and (max-width: 480px) {
  .website-root__content .bttn.--inline {
    width: 100%;
  }
}
.website-root__copy {
  flex: 1;
}
.website-root__copy * {
  color: #FFFFFF;
}
.website-root__graphic {
  max-width: 25rem;
  margin: 2.25rem 0 1rem;
  flex: 1;
}
@media only screen and (max-width: 480px) {
  .website-root__graphic {
    margin: 2rem 0 0;
  }
}
.website-root__graphic img {
  width: 100%;
  display: inline-block;
}
.bank-accounts > div > div {
  display: grid;
  grid-template-columns: 1.875rem auto 4.375rem;
  grid-template-rows: auto auto;
  grid-template-areas: "radio name number" "radio type number";
}
.bank-accounts__radio {
  grid-area: radio;
  font-size: 1rem;
  align-self: center;
}
.bank-accounts__name {
  grid-area: name;
  font-weight: 700;
}
.bank-accounts__type {
  grid-area: type;
}
.bank-accounts__number {
  grid-area: number;
  align-self: end;
}
/* Panels */
.ui-account {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 1.875rem;
}
@media only screen and (max-width: 768px) {
  .ui-account {
    padding: 1rem;
  }
}
.ui-account__title {
  text-align: center;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 768px) {
  .ui-account__title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
}
[ui-your-giftcards] {
  position: relative;
}
.tab-box {
  margin: 10px 0;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdddf;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.24);
}
.tab-box > .tabs {
  display: table;
  width: 100%;
  background-color: #D8D8D8;
  border-radius: 10px 10px 0 0;
}
.tab-box > .tabs > div {
  display: table-cell;
  border-radius: 10px 10px 0 0;
  text-align: center;
  width: 50%;
  padding: 10px 10px;
  background-color: #D8D8D8;
  color: #595959;
  font-size: 13px;
  font-weight: 700;
}
.tab-box > .tabs > div.selected {
  background-color: #ffffff;
  color: #021431;
}
.tab-box .body {
  padding-bottom: 10px;
}
.tab-box .body .item {
  border: solid #e1e1e1;
  border-width: 1px 1px 0 1px;
}
.tab-box .body .item.even {
  background: #f8f9fb;
}
.tab-box .body .item.last {
  border-width: 1px;
}
.tab-box .body .item .separator {
  width: 90%;
  height: 1px;
  background: #e1e8eb;
  margin: 3px auto;
}
.tab-box .body .empty {
  padding: 2rem 0;
  color: #4f566b;
  text-align: center;
}
.tab-box .body .pagination-container {
  width: 100%;
  position: sticky;
  bottom: -2px;
  left: 0;
  margin-top: 46px;
}
.tab-box .body .pagination-container > div {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
}
.tab-box .body .pagination-container > div > div {
  width: 100%;
  position: relative;
  display: inline-block;
  transform: translateY(-100%);
}
.tab-box table.my-giftcards {
  border-spacing: 0;
}
.tab-box .fixed-floating-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffcc00;
  text-align: center;
  z-index: 5000;
}
.tab-box .fixed-floating-bottom > div {
  position: relative;
  display: inline-block;
  transform: translateY(-100%);
}
.tab-box .ui-pagination {
  background: #d3d9e7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  border: 1px solid #5069a0;
  border-width: 0 1px;
}
.tab-box .ui-pagination .pagination {
  display: inline-block;
  margin: 2px 0;
  background: #d3d9e7;
  border: 2px solid #5069a0;
  border-radius: 10px;
}
.tab-box .ui-pagination > div {
  display: table-cell;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}
.details-form {
  padding: 2rem 2rem 0;
}
.details-form > div {
  margin-bottom: 15px;
}
.details-form > div > div {
  margin-bottom: 5px;
}
.details-form > div > div:first-child {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Karla', sans-serif;
  color: rgba(2, 20, 49, 0.5);
}
.details-form > div > div:last-child input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 7px;
  background-color: #ffffff;
  border: 1px solid #dcdddf;
  transition: 0.25s ease-in-out;
}
.details-form > div > div:last-child input:focus {
  outline: none;
  box-shadow: 0 0 0 3px #afc1de;
  border-color: transparent;
}
.details-form > div > div:last-child input[readonly] {
  background-color: #f4f5f7;
  color: #6c748e;
  border-color: transparent;
  pointer-events: none;
}
.details-form > div > div:last-child .action {
  text-align: right;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  text-decoration: underline;
}
.details-form > div > div:last-child .text-confirm {
  text-align: right;
  font-size: 11px;
  color: #039BE5;
  padding: 5px 0;
}
.details-form > div > div:last-child .text-warning {
  text-align: right;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
}
.secondary-email-container .read-only {
  border-radius: 5px;
}
.secondary-email-container .read-only div {
  display: flex;
  align-items: center;
  margin: 0 5px;
  opacity: 0.8;
  transition: 0.25s ease-in-out;
}
.secondary-email-container .read-only div:hover {
  opacity: 1;
}
.checkout-confirmation .panel__content {
  justify-content: space-between !important;
}
.checkout-confirmation .body {
  width: 100%;
  padding: 2rem 3rem 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .checkout-confirmation .body {
    padding: 0 2rem;
    margin-bottom: 7rem;
  }
}
.checkout-confirmation .body img {
  width: 100%;
  max-width: 75%;
  display: block;
}
@media only screen and (max-width: 480px) {
  .checkout-confirmation .body img {
    margin-bottom: 2rem;
  }
}
.checkout-confirmation__details {
  text-align: center;
  margin-top: 2rem;
}
.checkout-confirmation__cost {
  margin-bottom: 0.875rem;
}
.checkout-confirmation__cost.--sup::first-letter {
  vertical-align: super;
  font-size: 1.125rem;
}
.checkout-page[ng-click] {
  cursor: default;
}
.checkout-page .ui-offer-page .carousel-wrapper {
  width: 100%;
  height: 170px;
  overflow: hidden;
  margin-bottom: 1.25rem;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .carousel-wrapper {
    height: 100px;
  }
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .carousel-wrapper {
    margin-bottom: 1rem;
  }
}
.checkout-page .ui-offer-page .carousel-wrapper--no-thumbs {
  justify-content: center;
}
.checkout-page .ui-offer-page .carousel-wrapper--no-thumbs .checkout-slider.checkout-slider-for {
  margin: auto;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-for {
  width: calc((100% - .5rem) - 43px);
  max-width: 268px;
  height: 170px;
  overflow: hidden;
  margin-right: 0.5rem;
  cursor: default;
  border: none;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-for {
    max-width: 158px;
    height: 100px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-for .card__imageWrapper {
  max-width: 268px;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-for .card__imageWrapper > div {
  border-radius: unset;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-for .card__imageWrapper {
    max-width: 158px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav {
  width: 43px;
  height: 170px;
  overflow: hidden;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav {
    width: 40px;
    height: 100px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__imageRepeater {
  border: 1px solid transparent;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__imageWrapper {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid transparent;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__imageWrapper > img {
  display: inline-block;
  height: 100%;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .card__imageWrapper {
    width: 33px;
    height: 33px;
  }
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-slide {
  outline: none;
  cursor: pointer;
}
.checkout-page .ui-offer-page .checkout-slider.checkout-slider-nav .slick-slide.slick-current .card__imageWrapper {
  border: 2px solid #00A7FF;
}
.checkout-page .ui-offer-page .checkout-slider .slick-track,
.checkout-page .ui-offer-page .checkout-slider .slick-list {
  height: 170px !important;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout-slider .slick-track,
  .checkout-page .ui-offer-page .checkout-slider .slick-list {
    height: 100px !important;
  }
}
.checkout-page .ui-offer-page .checkout-slider .slick-list,
.checkout-page .ui-offer-page .checkout-slider .slick-track,
.checkout-page .ui-offer-page .checkout-slider .slick-slide {
  box-sizing: border-box;
}
.checkout-page .ui-offer-page .checkout__tags {
  height: calc(30px + 1rem);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
}
.checkout-page .ui-offer-page .checkout__tags > * {
  margin-right: 0.5rem;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .checkout__description {
    margin-bottom: 0;
  }
}
.checkout-page .ui-offer-page .checkout__description.--bogo > p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2AC86E;
}
.checkout-page .ui-offer-page .checkout__small {
  color: #00A7FF;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.checkout-page .ui-offer-page .checkout__subheading {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
}
.checkout-page .ui-offer-page .checkout__subheading .discount-tag {
  margin-left: 0.5rem;
}
.checkout-page .ui-offer-page .ui-form .regular {
  padding: 0.75rem 0;
  width: inherit;
}
@media only screen and (max-width: 480px) {
  .checkout-page .ui-offer-page .ui-toggles-wrapper .glider-contain {
    margin-left: -1.25rem;
    width: calc(100% + (1.25rem + 1.25rem));
  }
  .checkout-page .ui-offer-page .ui-toggles-wrapper .glider {
    padding-left: 1.25rem;
  }
  .checkout-page .ui-offer-page .ui-toggles-wrapper .glider-slide:last-child,
  .checkout-page .ui-offer-page .ui-toggles-wrapper .glider-slide:last-child > .ui-toggles__item,
  .checkout-page .ui-offer-page .ui-toggles-wrapper .glider-slide--wide > .ui-toggles__item {
    margin-right: 1.25rem;
  }
}
.onboarding {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  max-width: 25rem;
  margin: 0 auto;
}
.onboarding * {
  box-sizing: border-box;
}
.overlay.--alternate .onboarding .onboarding__dots,
.panel.--alternate .onboarding .onboarding__dots {
  margin-bottom: 1.5rem;
}
.overlay.--alternate .onboarding .onboarding__dots .slick-dots li:first-child,
.panel.--alternate .onboarding .onboarding__dots .slick-dots li:first-child {
  display: none;
}
.overlay.--alternate .onboarding .onboarding__dots .slick-dots li.slick-active button,
.panel.--alternate .onboarding .onboarding__dots .slick-dots li.slick-active button {
  color: #FFFFFF;
}
.overlay.--alternate .onboarding .onboarding__dots .slick-dots li button,
.panel.--alternate .onboarding .onboarding__dots .slick-dots li button {
  color: rgba(255, 255, 255, 0.5);
}
.overlay.--alternate .onboarding .onboarding__message,
.panel.--alternate .onboarding .onboarding__message {
  color: #FFFFFF;
}
.onboarding__slider {
  height: 100%;
  padding-top: 1rem;
}
@media only screen and (max-width: 879px) {
  .onboarding__slider {
    padding-top: 0;
  }
}
.onboarding__slider .slick-track {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}
.onboarding__slider .slick-list {
  height: 100%;
}
.onboarding__slider .slick-slide {
  outline: none;
  will-change: transform;
}
.onboarding__slider .slick-slide img {
  width: 100%;
}
@media only screen and (max-width: 879px) {
  .onboarding__slider .slick-prev {
    display: none !important;
  }
}
.onboarding__slide {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  display: flex !important;
  text-align: center;
}
.onboarding__message {
  padding: 1.875rem 1.875rem 0.5rem;
}
@media only screen and (max-width: 879px) {
  .onboarding__message {
    padding: 1.5rem 1.5rem 0.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .onboarding__message {
    padding: 1rem 1rem 0.5rem;
  }
}
.onboarding__title {
  margin-bottom: 0.5rem;
}
.onboarding__logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.onboarding__logo img {
  max-width: 6.25rem;
  width: 100%;
  display: inline-block;
}
.onboarding__dots .slick-dots {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 10px;
  text-align: center;
}
.onboarding__dots .slick-dots li {
  margin: 0;
}
.onboarding__dots .slick-dots li button {
  padding: 0 2px;
  background: none;
  font-size: 9px;
  color: #E5E7EA;
  border: 0;
  outline: none;
}
.onboarding__dots .slick-dots li button:focus {
  outline: none;
}
.onboarding__dots .slick-dots li button:hover {
  cursor: pointer;
}
.onboarding__dots .slick-dots li.slick-active button {
  color: #00A7FF;
}
.onboarding__controls {
  padding: 1.875rem;
}
.onboarding__controls .bttn + .bttn {
  margin-top: 0.875rem;
}
@media only screen and (max-width: 879px) {
  .onboarding__controls {
    padding: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .onboarding__controls {
    padding: 1rem;
  }
}
.onboarding__skip {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0;
}
.onboarding__skip .bttn.--ghost {
  color: #A7E0FF;
  width: min-content;
}
/* 
	Default Slick Slider CSS
*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.profile__row {
  margin-bottom: 1.25rem;
}
.profile__key {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1rem;
}
.profile__key.--small {
  margin-bottom: 0.5rem;
}
.profile__valuesWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.profile__value {
  color: #9098A5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.profile__statusWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  margin-left: 1rem;
}
.profile__status + .profile__icon {
  margin-left: 1rem;
  color: #BFC4CB;
}
.profile__icon {
  font-size: 1rem;
}
.profile__icon.--alert {
  font-size: 1.5rem;
}
.profile__icon + .profile__status {
  margin-left: 0.5rem;
}
.redeemPanel .carousel-wrapper {
  width: 100%;
  height: 170px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
@media only screen and (max-width: 480px) {
  .redeemPanel .carousel-wrapper {
    height: 158px;
  }
}
@media only screen and (max-width: 480px) {
  .redeemPanel .carousel-wrapper {
    margin-bottom: 1rem;
  }
}
.redeemPanel .carousel-wrapper--no-thumbs {
  justify-content: center;
}
.redeemPanel .carousel-wrapper--no-thumbs .redeem-slider.redeem-slider-for {
  margin: auto;
}
.redeemPanel .redeem-slider.redeem-slider-for {
  width: calc((100% - .5rem) - 43px);
  max-width: 290px;
  height: 170px;
  overflow: hidden;
  margin-right: 0.5rem;
  cursor: default;
  border: none;
}
@media only screen and (max-width: 480px) {
  .redeemPanel .redeem-slider.redeem-slider-for {
    max-width: 250px;
    height: 158px;
  }
}
.redeemPanel .redeem-slider.redeem-slider-for .card__imageWrapper {
  max-width: 290px;
}
.redeemPanel .redeem-slider.redeem-slider-for .card__imageWrapper > div {
  border-radius: unset;
}
@media only screen and (max-width: 480px) {
  .redeemPanel .redeem-slider.redeem-slider-for .card__imageWrapper {
    max-width: 250px;
  }
}
.redeemPanel .redeem-slider.redeem-slider-nav {
  width: 43px;
  height: 170px;
  overflow: hidden;
}
@media only screen and (max-width: 480px) {
  .redeemPanel .redeem-slider.redeem-slider-nav {
    width: 40px;
    height: 158px;
  }
}
.redeemPanel .redeem-slider.redeem-slider-nav .card__image_repeater {
  border: 1px solid transparent;
}
.redeemPanel .redeem-slider.redeem-slider-nav .card__imageWrapper {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid transparent;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.redeemPanel .redeem-slider.redeem-slider-nav .card__imageWrapper > img {
  display: inline-block;
  height: 100%;
}
@media only screen and (max-width: 480px) {
  .redeemPanel .redeem-slider.redeem-slider-nav .card__imageWrapper {
    width: 33px;
    height: 33px;
  }
}
.redeemPanel .redeem-slider.redeem-slider-nav .slick-slide {
  outline: none;
  cursor: pointer;
}
.redeemPanel .redeem-slider.redeem-slider-nav .slick-slide.slick-current .card__imageWrapper {
  border: 2px solid #00A7FF;
}
.redeemPanel .redeem-slider .slick-track,
.redeemPanel .redeem-slider .slick-list {
  height: 170px !important;
}
@media only screen and (max-width: 480px) {
  .redeemPanel .redeem-slider .slick-track,
  .redeemPanel .redeem-slider .slick-list {
    height: 158px !important;
  }
}
.redeemPanel .redeem-slider .slick-list,
.redeemPanel .redeem-slider .slick-track,
.redeemPanel .redeem-slider .slick-slide {
  box-sizing: border-box;
}
/*
	Sidebar component wrapper
*/
sidebar {
  width: 31.25rem;
  position: absolute;
  left: unset;
  right: 0;
  bottom: 0;
  top: 46px;
  z-index: 51010;
  /* ng-if show animation */
  /* ng-if hide animation */
}
@media only screen and (max-width: 768px) {
  sidebar {
    width: 19rem;
  }
  .sidebar-expanded sidebar {
    width: 100%;
  }
}
sidebar.ng-enter {
  animation: slideLeft 0.4s ease-out;
  -webkit-animation: slideLeft 0.4s ease-out;
}
sidebar.ng-leave {
  animation: slideRight 0.3s linear;
  -webkit-animation: slideRight 0.3s linear;
}
.sidebar {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  background-color: #FFFFFF;
  height: 100%;
  width: 25rem;
  position: absolute;
  left: unset;
  right: 0;
  bottom: 0;
  border-radius: 20px 0 0 20px;
  z-index: 51001;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.sidebar.--expanded {
  width: 31.25rem;
}
@media only screen and (max-width: 768px) {
  .sidebar.--expanded {
    width: 100%;
    border-radius: 0;
  }
}
@media only screen and (max-width: 879px) {
  .sidebar {
    width: 19rem;
  }
}
.sidebar__panel {
  height: 100%;
}
.sidebar__panel.--alternate {
  background-color: #F3F3F6;
}
.sidebar__panel.ng-enter {
  animation: fadeIn 0.4s ease-out;
  -webkit-animation: fadeIn 0.4s ease-out;
}
/* Survey */
.checkbox-step__grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem 1.5rem;
}
@media only screen and (max-width: 480px) {
  .checkbox-step__grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem 0.5rem;
  }
}
.checkbox-step__item.--selected .checkbox__control::before {
  opacity: 1;
}
.confirmation-step__layout {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.confirmation-step__image {
  max-width: 290px;
  width: 100%;
}
.confirmation-step__image img {
  width: 100%;
  height: auto;
  display: inline-block;
}
.generic-inputs-step {
  max-width: 20rem;
  width: 100%;
  margin: 0 auto;
}
.multi-select-step {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.multi-select-step__grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, calc(5rem + (0.75rem * 2)));
}
@media only screen and (max-width: 480px) {
  .multi-select-step__grid {
    grid-gap: 0.5rem;
  }
}
.multi-select-step__item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
}
.multi-select-step__item.--selected {
  border: 2px solid #00A7FF;
}
.multi-select-step__image {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  border: 1px solid rgba(2, 20, 49, 0.1);
  border-radius: 5px;
}
.multi-select-step__text {
  margin-top: 0.5rem;
  line-height: 1;
  font-weight: 700;
}
.stepperWrapper {
  background-color: white;
  height: auto;
}
.stepperWrapper .stepper {
  display: flex;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.stepperWrapper .stepper .step {
  padding: 1rem;
  position: relative;
  width: calc(100% / 5);
  z-index: 2;
}
.stepperWrapper .stepper .step .step__circle {
  border: 3px solid #E5E7EA;
  border-radius: 50%;
  font-size: 0.5rem;
  font-weight: 600;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1;
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 2;
}
.stepperWrapper .stepper .step.active .step__circle::after {
  background-color: #00A7FF;
  content: '';
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.stepperWrapper .stepper .step.active .step__bar--left {
  background-color: #00A7FF;
  border-color: #00A7FF;
  width: calc(100% - 8px);
  z-index: 3;
}
.stepperWrapper .stepper .step.partial .step__circle {
  background: #FFFFFF;
}
.stepperWrapper .stepper .step.partial .step__circle::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.stepperWrapper .stepper .step.done {
  z-index: 3;
}
.stepperWrapper .stepper .step.done .step__circle {
  background-color: #00A7FF;
  border: 3px solid #00A7FF;
  color: #FFFFFF;
}
.stepperWrapper .stepper .step.done .step__circle:before {
  content: "\f00c";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  font-size: 0.675rem;
  left: 2px;
  position: absolute;
  top: 3px;
  z-index: 4;
}
.stepperWrapper .stepper .step.done .step__circle * {
  display: none;
}
.stepperWrapper .stepper .step.done .step__bar--left {
  background-color: #00A7FF;
  border-color: #00A7FF;
  width: calc(100% - 1rem);
  z-index: 3;
}
.stepperWrapper .stepper .step .step__bar--left {
  background-color: #E5E7EA;
  border: solid #E5E7EA;
  border-width: 1px 0;
  height: 1px;
  position: absolute;
  width: calc(100% - 1rem);
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -50%;
}
.survey__wrapper {
  background-color: #FFFFFF;
  border: 1px solid rgba(2, 20, 49, 0.1);
  border-radius: 20px;
  padding: 2rem;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 480px) {
  .survey__wrapper {
    border: none;
    padding: 0;
  }
}
.survey__inner {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  display: grid;
  justify-items: stretch;
  grid-template-rows: auto 1fr;
  height: 100%;
  grid-template-areas: "header" "content";
}
.survey__header {
  grid-area: header;
  text-align: center;
}
.survey__content {
  grid-area: content;
  height: 100%;
}
.survey__buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}
.survey__buttons.--center {
  justify-content: center;
}
.survey__buttons button + button {
  margin-left: 0.5rem;
}
@media only screen and (max-width: 480px) {
  .survey__buttons {
    flex-flow: column;
    justify-content: center;
  }
  .survey__buttons button + button {
    margin: 0.5rem 0 0;
  }
  .survey__buttons .bttn.--inline {
    width: 100%;
  }
}
.survey__stepper {
  margin-bottom: 2rem;
}
.survey__step {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}
.survey__step > div {
  align-self: center;
  padding: 2rem 0;
}
@media only screen and (max-width: 480px) {
  .survey__step > div {
    align-self: start;
  }
}
.survey__message h1 {
  margin-bottom: 0.5rem;
}
