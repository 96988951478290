.confirmation-step {
	@image-width: 290px;
	
	&__layout {
		.flexbox(@flexFlow: column);
		
		text-align: center;
	}
	
	&__image {
		max-width: @image-width;
		width: 100%;
		
		img {
			width: 100%;
			height: auto;
			display: inline-block;
		}
	}
}