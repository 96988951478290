.sign-in-error {
	.flexbox(@flexFlow: column; @alignItems: stretch);
	
	margin: 2rem auto;
	
	&__alert {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start);
		
		border: 2px solid @red;
		padding: .675rem;
		border-radius: @default-border-radius;
	}
	
	&__icon {
		.flexbox();
		
		color: @red;
		font-size: 1rem;
		margin-right: .675rem; 
	}
	
	&__message {
		margin: 1rem 0;
		display: block;
	}
	
	&__buttonWrapper {
		margin-top: 2.875rem;
	}
}
