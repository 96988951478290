.card {
	@selected-border-radius: 8px;

	background-color: @white;
	border-radius: 12px;
	border: 3px solid @light-grey-background-color;

	&:hover {
		.card__row--cta {
			border-color: @blue-highlight;
			background-color: @blue-highlight;

			* {
				color: @white;
			}
		}

		.card__row--filtered {
			border-color: @selected-grey-background-color;
			background-color: @selected-grey-background-color;

			* {
				color: @white;
			}
		}
	}

	&.--mini {
		border: none;
	}

	&.--small {
		max-width: 120px;
		margin: 0 auto;
		border: none;
	}

	&.--featured {
		max-width: 315px;
		margin: 0 auto;
		border: none;
	}
	
	// My Giftcards panel
	&.--giftcards {
		border: none;
		.card__row {
			height: 3.75rem;
		}
		
		.card__label,
		.card__label[ng-bind] {
			.flexbox(@justifyContent: flex-start);
		}
	}

	&.--selected {
		.prizeout-widget & {
			border: 3px solid @blue-highlight;
		}
	}

	&__overlay {
		background-color: rgba(0,0,0,.5);
		-webkit-backdrop-filter: blur(8px);
		backdrop-filter: blur(8px);

		small {
			position: absolute;
			right: .875rem;
			bottom: .5rem;
			color: @white;
			font-family: @body-font-family;
		}
	}

	&__imageWrapper {
		position: relative;
		margin: 0 auto;
		border-bottom: none;

		.ui-offer-page & {
			border: none;
		}

		& > img {
			width: 100%;
		}

		& > div {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			box-sizing: border-box;
			border-radius: 7px 7px @default-border-radius @default-border-radius;
			
			.--small & {
				border-radius: 8px;
			}

			.--selected & {
				border-radius: @selected-border-radius @selected-border-radius @default-border-radius @default-border-radius;
			}

			.--featured & {
				box-shadow: @featured-card-box-shadow;
			}
		}
	}

	&__row {
		.flexbox(@flexFlow: row nowrap; @justifyContent: space-between);

		padding: .625rem;

		@media @laptop-down {
			padding: .5rem;
		}

		.--featured & {
			align-items: flex-start;
			justify-content: space-between;
			padding: 1rem 0;
		}

		&--cta {
			@transition-props: ~'background-color, border-color';

			.transition(@prop: @transition-props);

			border-top:	1px solid @divider-border-color;
			background-color: @white;
			cursor:	pointer;
			border-radius: 0 0 @default-border-radius @default-border-radius;

			.--selected & {
				border-radius: 0 0 @selected-border-radius @selected-border-radius;
			}
		}

		&--filtered {
			border-color: @filtered-grey-background-color;
			background-color: @filtered-grey-background-color;

			* {
				color: @filtered-grey-color;
			}
		}

		+ .card__row:not(.card__row--cta) {
			padding-top: 0;
		}

		.discount-tag {
			margin-right: -.625rem;

			@media @laptop-down {
				margin-right: -.5rem;
			}
		}

		small {
			&:first-child {
				text-align: left;
				margin-right: .15rem;
			}
		}
	}

	&__column {
		.flexbox(@flexFlow: column; @alignItems: stretch;)
	}

	&__name {
		.flexbox(@justifyContent: flex-start);

		width: 52%;
		height: 38px;
		text-align: left;
		font-weight: 700;

		&--full-width {
			width: 100%;
			margin-right: 0;
		}

		.--mini & {
			width: 100%;
			height: auto;
		}

		span {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;

			* {
				font-size: inherit;
    			color: inherit;
			}
		}
	}

	&__icon {
		margin-left: .15rem;
	}

	&__title {
		&:extend(h2);
	}

	&__label,
	&__label[ng-bind] {
		.flexbox(@display: inline-flex);

		height: 30px;
		overflow: hidden;
		font-weight: 400;

		&:first-child {
			flex: 2;
			margin-right: .5rem;
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		@media @mobile-portrait-down {
			height: 40px;
		}
	}
}


/*
	Promo Cards (@TODO, demo code)
*/

.ui-promo-widget {
	height:				88px;
	border-radius:		10px;
	display: 			flex;
    align-items: 		center;
    justify-content: 	flex-start;
    padding-left: 		12px;
    background-repeat: 	repeat-x;
    background-position:-12px 0;
    background-size:	cover;
	max-width: 			100%;
	margin: 			12px 8px;
	
	/* The themes that can be passed as variable */
	@dark-themed: {
		background-color: 	#60b8fb;
		color: 				#ffffff;
	}
	@light-themed: {
		background-color: 	#ffffff;
		color: 				#021431;
	}
	&.dark {
		@dark-themed();
	}
	&.light {
		@light-themed();
	}
	
	& > div {
		width: 13.125rem;
	}
	&.promo-design-1 {
		.promo-title {
			margin-bottom: .25rem;
		}
		&.disabled {
			filter: grayscale(100%);
		}
	}
	&.promo-design-2 {
		.promo-title {
			margin-bottom: .5rem;
		}
		&.disabled {
			filter: grayscale(100%);
		}
	}
	
}
