.alert {
	.flexbox(@justifyContent: flex-start; @flexFlow: row nowrap);
		
	border: 2px solid transparent;
	padding: .675rem;
	border-radius: @default-border-radius;
	margin-bottom: 1rem;
	width: 100%;
	box-sizing: border-box;
	
	&.--success {
		.alert-color(@green, @default-text-color);
	}
	
	&.--warning {
		.alert-color(@yellow, @default-text-color);
	}
	
	&.--error {
		.alert-color(@red, @default-text-color);
	}
	
	&__icon {
		.flexbox();
		
		font-size: 1rem;
		margin-right: .675rem; 
		
		&.--medium {
			font-size: 1.5rem;
		}
	}
	
	&__message {
		font-family: @body-font-family;
	}
	
	
	/*
		Local mixin
	*/
	
	.alert-color(@iconColor, @messageColor) {
		border-color: @iconColor;
		
		.alert__icon {
			color: @iconColor;
		}
		
		.alert__message {
			color: @messageColor;
		}
	}
}
