.bttn {
	@small-border-radius: .313rem;
	@medium-border-radius: .625rem;
	@large-border-radius: .625rem;

	@small-padding: .5rem 1.25rem;
	@medium-padding: .75rem 3.125rem;
	@large-padding: .9375rem 4.6875rem;
	@small-padding-mobile: .3125rem 1rem;
	@medium-padding-mobile: .75rem 1.125rem;
	@large-padding-mobile: .9375rem 1.25rem;

	.flexbox();
	.transition(@prop: background-color);

	border: none;
	box-shadow: none;
	outline: none;
	cursor: pointer;
	border-radius: @default-border-radius;
	width: 100%;
	font-family: @body-font-family;
	font-weight: 700;
	font-size: 1rem;
	box-sizing: border-box;
	text-decoration: none;
	padding: 0;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	> span {
		font-family: inherit;
		font-weight: inherit;
		line-height: inherit;
	}

	&.--inline {
		display: inline-flex;
		width: auto;
		padding: .5rem 1rem;
	}

	&.--primary {
		background-color: @blue-highlight;
		color: @white;

		&:hover,
		&:focus {
			background-color: #0293EC;
		}

		&:active {
			background-color: #0760BB;
		}
	}

	&.--inverse {
		background-color: @white;
		color: @blue-highlight;
		border: 1px solid @divider-border-color;

		&:hover,
		&:focus {
			background-color: #F5F5F7;
			border: 1px solid #F5F5F7;
		}
	}

	&.--secondary {
		background-color: @secondary-dark-blue;
		color: @white;

		&:hover,
		&:focus {
			background-color: darken(@secondary-dark-blue, 5%);
		}
	}

	&.--secondaryAction {
		background-color: #99A1AC;
		color: @white;

		&:hover,
		&:focus {
			background-color: #7B8593;
		}

		&:active {
			background-color: #4D5A6D;
		}

		&:disabled,
		&[disabled] {
			background-color: #DFE2E5;
		}
	}

	&.--alternateSecondary {
		background-color: @white;
		color: @blue-highlight;
		border: 1px solid @blue-highlight;

		&:hover,
		&:focus {
			background-color: @blue-highlight;
			color: @white;
		}
	}

	&.--tertiary {
		background-color: @blue-highlight;
		color: @white;
		border: 2px solid @white;

		&:hover,
		&:focus {
			background-color: @white;
			color: @blue-highlight;

			span {
				color: @blue-highlight;
			}
		}
	}

	&.--success {
		background-color: @green;
		color: @white;
		cursor: not-allowed;
		pointer-events: none;
	}

	&:disabled,
	&[disabled],
	&.--loading {
		cursor: not-allowed;
		pointer-events: none;
	}

	&:disabled,
	&[disabled] {
		opacity: .5;
	}

	&.--loading {
		opacity: .8;
	}

	&.--small {
		border-radius: @small-border-radius;
		font-size: .875rem;
		padding: @small-padding;

		@media @mobile-portrait-down {
			padding: @small-padding-mobile;
		}
	}

	&.--medium {
		border-radius: @medium-border-radius;
		font-size: 1rem;
		padding: @medium-padding;

		@media @mobile-portrait-down {
			padding: @medium-padding-mobile;
		}
	}

	&.--large {
		border-radius: @large-border-radius;
		font-size: 1.125rem;
		padding: @large-padding;

		@media @mobile-portrait-down {
			padding: @large-padding-mobile;
		}
	}

	&.--link {
		background-color: transparent;
		justify-content: flex-start;
		font-size: .875rem;
		color: @blue-highlight;
		font-family: @body-font-family;

		&:hover,
		&:focus {
			color: #0293EC;
		}

		&:active {
			color: #0760BB;
		}
	}

	&.--secondaryLink {
		.transition();

		&:extend(.bttn.--link);

		color: #808997;
		font-family: inherit;
		justify-content: center;

		&:hover,
		&:focus {
			background-color: #F1F2F3;
			color: @blue-highlight;
		}
	}

	&.--tertiaryLink {
		&:extend(.bttn.--link);

		color: #BFC4CB;
		font-size: 1rem;
		justify-content: center;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	&.--ghost {
		background-color: transparent;
		color: @white;

		&:hover,
		&:focus {
			color: #F5F5F7;
		}
	}

	&.--alternate {
		font-family: @body-font-family;
	}

	&.--center {
		justify-content: center;
	}

	&__icon {
		color: inherit;

		&.--right {
			margin-left: .5rem;
		}

		&.--left {
			margin-right: .5rem;
		}
	}
}
