/*
	Sidebar component wrapper
*/
sidebar {
	width: @sidebar-width-expanded;
	position: absolute;
	left: unset;
	right: 0;
	bottom: 0;
	top: @header-height;
	z-index: 51010;
	
	@media @tablet-portrait-down {
		width: @sidebar-width-mobile;
		
		.sidebar-expanded & {
			width: 100%;
		}
	}
	
	/* ng-if show animation */
	&.ng-enter {
		animation: slideLeft 0.4s ease-out;
		-webkit-animation: slideLeft 0.4s ease-out;
	}
	
	/* ng-if hide animation */
	&.ng-leave {
		animation: slideRight 0.3s linear;
		-webkit-animation: slideRight 0.3s linear;
	}
}

.sidebar {
	.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between;);
	.transition();
	
	background-color: @white;
	height: 100%;
	width: @side-panel-width;
	position: absolute;
	left: unset;
	right: 0;
	bottom: 0;
	border-radius: @tertiary-border-radius 0 0 @tertiary-border-radius;
	z-index: 51001;
	box-shadow: @panel-box-shadow;
	overflow: auto;
	
	&.--expanded {
		width: @sidebar-width-expanded;
		
		@media @tablet-portrait-down {
			width: 100%;
			border-radius: 0;
		}
	}
	
	@media @split-screen-down {
		width: @sidebar-width-mobile;
	}
	
	&__panel {
		height: 100%;
		
		&.--alternate {
			background-color: @light-grey-background-color;
		}
		
		&.ng-enter {
			animation: fadeIn 0.4s ease-out;
			-webkit-animation: fadeIn 0.4s ease-out;
		}
	}
}