.breadcrumbs {
	.flexbox(@justifyContent: flex-start;);

	> * {
		font-size: .875rem;
		color: @light-grey-text-color;
		margin-right: .25rem;
	}

	&__path {
		a {
			color: @light-grey-text-color;
		}	
	}
	
	&__current {
		font-weight: 700;
	}
}
