.accordion {
	background-color: @white;
	position: relative;
	border-radius: @alternate-border-radius;

	+ .accordion {
		margin-top: 1rem;
	}
	
	&.--maxLimit {
		background-color: @blue-highlight;
		
		* {
			color: @white;
		}
	}

	&__iconWrapper {
		.flexbox();
		
		position: absolute;
		left: unset;
		right: @inner-padding-small;
		top: @inner-padding-small;
		width: 15px;
		height: 15px;
	}

	&__icon {
		.transition(@duration: .2s; @timing: ease-out;);

		width: 100%;
		height: 100%;
		text-align: center;
		transform: rotate(0);

		&.--rotate {
			transform: rotate(90deg);
		}
	}

	&__inner {
		padding: @inner-padding-small;

		+ .accordion__inner {
			border-top: 1px solid @divider-border-color;

			&.ng-enter {
				animation:
					.25s ease-out .5s unfold,
					.25s ease-out fadeIn;
			}
		}
	}

	&__link {
		display: block;
		text-align: right;
		margin-top: .5rem;
	}
}
