body {
	font-family: @body-font-family;
	font-weight: 400;
	font-size: .875rem;
	color: @default-text-color;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
	font-weight: 700;
}

h1 {
	font-size: 1.875rem;
	font-family: @body-font-family;
}

h2 {
	font-size: 1.5rem;
	font-family: @body-font-family;
}

h3 {
	font-size: 1rem;
}

h4 {
	font-size: 1.125rem;
}

h5 {
	font-size: .875rem;
}

p {
	font-size: .875rem;
	line-height: 1.5;
}

small {
	display: inline-block;
	font-size: .75rem;
	line-height: 1.3;
}

strong {
	color: inherit;
	font-weight: 700;
}

span,
span[ng-bind] {
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	text-transform: inherit;
}

[ng-bind-html] {
	ol,
	ul,
	dl {
		padding-left: revert;
	}

	li {
		list-style-type: disc;
	}

	> * {
		&:not(a) {
			margin-bottom: .75rem;
			color: inherit;
			font-family: inherit;
		}
	}
}



/*
	Typography utility classes
*/

// Supported text above
.eyebrow {
	color: @blue-highlight;
	font-size: .875rem;
	text-transform: uppercase;
	margin-bottom: .5rem;

	&.--small {
		font-size: .75rem;
		margin-bottom: .25rem;
	}
}

.text-strong {
	&:extend(strong);
}

.text-regular {
	font-weight: 400;
}

.text-extra-small {
	font-size: .675rem;
}

.text-large {
	font-size: 1.25;
}

.text-extra-large {
	font-size: 2.625rem;
}

.text-light {
	color: @light-grey-text-color;
}

.text-dark {
	color: @default-text-color;
}

.text-white {
	color: @white;
}

.text-trim {
	> * {
		&:extend(.text-light);

		line-height: 1.5;
	}

	// Defaults in case code is passed as description data
	pre,
	code {
		font-family: inherit;
		white-space: normal;
		margin: 0;
	}
}
