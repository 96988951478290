.bank-accounts {
	& > div > div {
		display: grid;
	    grid-template-columns: 1.875rem auto 4.375rem;
	    grid-template-rows: auto auto;
	    grid-template-areas: 
	        "radio name number"
	        "radio type number";
	}
	
	&__radio {
		grid-area:	radio;
		font-size:	1rem;
		align-self: center;
	}
	
	&__name {
		grid-area:	name;
		font-weight: 700;
	}
	
	&__type {
		grid-area:	type;
	}
	
	&__number {
		grid-area:	number;
		align-self: end;
	}
	
	&__type,
	&__number {
		&:extend(.text-light);
	}
}
