.animatedIcons {
	position: relative;
	width: 20px;
	height: 20px;
	
	+ div {
		margin-left: .5rem;
		flex: 1;
	}
	
	&__stack {
		.flexbox();
		
		position: absolute;
		top: 0;
		left: 0;
	  	width: 100%;
	  	height: 100%;
	}
	
	&__circle {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		
		&.--yellow {
			background-color: @yellow;
			animation: .8s ease-out forwards scaleUp;
		}
		
		&.--white {
			background-color: @white;
			animation: .8s ease-out forwards scaleUp;
		}
		
		&.--grey {
		  	background-color: @locked-color;
		  	animation: 1s ease-out .7s forwards scaleDown;
		}
	}
	
	&__icon {
		font-size: .75rem;
		position: absolute;
		
		&.--white {
			color: @white;
		}
		
		&.--blue {
			color: @blue-highlight;
		}
		
		&[data-step="1"] {
			animation: 1s ease-out forwards fadeOutAfter;
		}
		  
		&[data-step="2"] {
			animation: 1s ease-out 1.5s 1 forwards scaleDownRotate;
		}
		  
		&[data-step="3"] {
			opacity: 0;
			animation: 1s ease-out 1.25s 1 forwards scaleUpRotate;
		}
	}
}
