.animatedRedeem {
	height: 100%;
	width: 100%;
	
	&__scene {
		width: 350px;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		> * {
			position: absolute;
			margin: 0 auto;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	&__confettiStack {
		z-index: 1;
	}
	
	&__confetti {
		position: absolute;
		
		&[data-item="group"] {
			top: -85px;
			animation:
				.5s ease-out moveUpGroup forwards,
				.5s ease-out scaleUpGroup forwards,
				1.75s ease-out 1s fadeOut forwards,
				1s ease-out 1.5s moveDownGroup forwards;
		}
		
		&[data-item="blue"],
		&[data-item="green"],
		&[data-item="white"] {
			left: 50%;
		}
		
		&[data-item="blue"] {
			top: -35px;
			width: 1.25rem;
			animation:
				.5s linear halfSpin forwards,
				.5s ease-out moveLeftBlue forwards,
				1.75s ease-out 1s fadeOut forwards,
				1.5s ease-out 1.5s moveDownBlue forwards;
		}
		
		&[data-item="green"] {
			top: -35px;
			width: 1.75rem;
			animation:
				.5s linear halfSpin forwards,
				.5s ease-out moveRightGreen forwards,
				1.75s ease-out 1s fadeOut forwards,
				1.5s ease-out 1.5s moveDownGreen forwards;
		}
		
		&[data-item="white"] {
			top: -60px;
			width: 2rem;
			animation:
				.5s linear halfSpin forwards,
				.5s ease-out moveRightWhite forwards,
				1.75s ease-out 1s fadeOut forwards,
				1.5s ease-out 1.5s moveDownWhite forwards;
		}
	}
	
	&__logoWrapper {
		max-width: 100px;
		z-index: 2;
	}
	
	&__logo {
		width: 100%;
		height: auto;
	}
	
	&__copy {
		text-align: center;
		color: @white;
		top: 4rem;
	}
}
  