.ui-pagination {
	& > table {
		width:	100%;
		& > tbody {
			& > tr {
				& > td {
					padding:		5px;
					text-align:		center;

					ul.pagination {
						& > li {
							display:		inline-block;
							padding:		3px 8px;
							font-size:		15px;
							font-weight:	500;
							color:			#4f566b;
							border-radius:	3px;
							transition:		.15s ease-in-out;
							&:hover {
								background:	#f4f5f7;
							}
							&.active {
								font-weight:		600;
								background-color:	rgba(66, 165, 245, 1);
								color:				#ffffff;
							}
							&.disabled {
								pointer-events: 	none;
								color:				#00000029;
							}
						}
					}
				}
			}
		}
	}
}


.ui-nav {

	/*
		Large bubble menu:
		https://i.imgur.com/bWc7rTK.png
	*/
	&.bubble-menu {
		text-align:		center;
		margin:			30px 0;
		& > a {
			cursor:				pointer;
			display:			inline-block;
			text-decoration:	none;
			margin:				10px;
			border-radius:		10px;
			border:				1px solid rgba(0, 0, 0, 0.2);
			padding:			20px;
			background-color:	#F8F9FC;
			width:				180px;
			box-shadow:			1px 1px 5px rgba(0,0,0,0.1);
			transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			& > .icon {
				text-align:		center;
				font-size:		45px;
				color:			#42A5F5;
				transition:		color 0.2s ease-out;
			}
			& > .label {
				text-align:		center;
				font-size:		14px;
				color:			#333333;
				font-weight:	600;
			}
			&:hover {
				background-color:	rgba(3, 155, 229, 0.05);
				text-decoration:	none;
				transform:			scale(1.2);
				& > .icon {
					color:			#29B6F6;
				}
			}
		}
	}

	/*
		Medium bubble toggle
	*/
	&.bubble-toggle {
		text-align:		center;
		margin:			30px 0;
		& > div {
			cursor:				pointer;
			display:			inline-block;
			text-decoration:	none;
			margin:				10px;
			border-radius:		10px;
			border:				1px solid rgba(0, 0, 0, 0.2);
			padding:			10px;
			background-color:	#F8F9FC;
			width:				150px;
			box-shadow:			1px 1px 5px rgba(0,0,0,0.1);
			transition:			background-color 0.2s ease-out, color 0.2s ease-out;
			& > .icon {
				text-align:		center;
				font-size:		35px;
				color:			#42A5F5;
				transition:		color 0.2s ease-out;
			}
			& > .label {
				text-align:		center;
				font-size:		14px;
				color:			#333333;
				font-weight:	600;
			}
			&:hover {
				background-color:	rgba(3, 155, 229, 0.05);
				text-decoration:	none;
				& > .icon {
					color:			#29B6F6;
				}
			}
			&.selected {
				background-color:	rgba(66, 165, 245, 1);
				color:				#ffffff;
				text-decoration:	none;
				& > .icon {
					color:			#ffffff;
				}
				& > .label {
					color:			#ffffff;
					font-weight:	700;
				}
			}
		}
	}

	/*
		CTAs: Large buttons in a line
	*/

	&.ctas {
		text-align:		center;
		margin:			0 auto;
		padding:		10px 0;

		div, a {
			cursor:				pointer;
			display:			inline-block;
			vertical-align:		middle;
			text-decoration:	none;
			margin:				10px;
			border-radius:		5px;
			padding:			0 25px;
			background-color:	#1EB1FA;
			color:				#ffffff;
			font-size:			14px;
			line-height:		45px;
			font-weight:		600;
			box-shadow:			1px 1px 5px rgba(0, 0, 0, 0.2);
			transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			position:			relative;

			&:hover {
				background-color:	darken(rgba(66, 165, 245, 1), 10%);
				text-decoration:	none;
			}

			&.main {
				// background-color:	rgba(77, 182, 172, 1);
				background-color:	#1eb1fa;
				&:hover {
					// background-color:	darken(rgba(77, 182, 172, 1), 10%);
					background-color:	#128ef2;
					text-decoration:	none;
				}
			}
			&.blank {
				background-color:	transparent;
				border:				0;
				box-shadow:			none;
				color:				#333333;
				&:hover {
					background:			rgba(0, 0, 0, 0.1);
					text-decoration:	none;
				}
			}

			&.danger {
				background-color:	rgba(229, 115, 115, 1);
				color:				#ffffff;
				&:hover {
					background-color:	darken(rgba(229, 115, 115, 1), 10%);
					text-decoration:	none;
				}
			}

			&.admin {
				background-color:	rgba(120, 144, 156, 1);
				color:				#ffffff;
				&:hover {
					background-color:	darken(rgba(120, 144, 156, 1), 10%);
					text-decoration:	none;
				}
			}

			&.positive {
				background-color:	rgba(102, 187, 106, 1);
				color:				#ffffff;
				&:hover {
					background-color:	darken(rgba(102, 187, 106, 1), 10%);
					text-decoration:	none;
				}
			}

			&.negative {
				background-color:	rgba(251, 140, 0, 1);
				color:				#ffffff;
				&:hover {
					background-color:	darken(rgba(251, 140, 0, 1), 10%);
					text-decoration:	none;
				}
			}

			&.disabled {
				background-color:	rgba(0,0,0,0.5);
				color:				#333333;
				text-decoration:	none;
				cursor:				default;
				&:hover {
					background-color:	rgba(0,0,0,0.5);
					color:				#333333;
					text-decoration:	none;
					cursor:				default;
				}
			}

			&.sep {
				padding:			0 !important;
				width:				1px;
				height:				20px;
				background-color:	rgba(0,0,0,0.6);
				cursor:				default;
			}

			.alert-dot {
				position:			absolute;
				top:				5px;
				right:				10px;
				width:				10px;
				height:				10px;
				background-color:	rgba(255, 0, 0, 1);
				border-radius:		50%;
			}
		}

		&.small {
			div, a {
				font-size:			12px;
				line-height:		30px;
				padding:			2px 30px;
				margin:				5px;
			}
		}
		&.align-right {
			text-align:	right;
		}
		&.align-left {
			text-align:	left;
		}

		/*&.scroll-fixed {
			position:	absolute;
		}*/
	}
}
