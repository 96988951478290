.section {
	.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between;);

	height: 100%;
	width: 100%;

	* {
		box-sizing: border-box;
	}
	
	&.--alternate {
		background-color: @light-grey-background-color;
	}

	&.--loading {
		.flexbox(@flexFlow: column);

		.section__icon {
			color: @blue-highlight;
			font-size: 1.5rem;
			margin-bottom: 25px;
		}
	}
	
	&__wrapper {
		position:	relative;
		z-index:	51012; /* Needs to go over navigation */
	}

	&__inner {
		padding: 1.875rem 1.875rem 1rem;

		@media @mobile-portrait-down {
			padding: 1.25rem 1.25rem .625rem;
		}

		&.--alternate {
			background-color: @light-grey-background-color;
			border-top: 1px solid @divider-border-color;
		}
		
		&.--animatedRedeem {
			height: 250px;
			background-color: @blue-highlight;
			animation: fadeIn .25s ease-out;
		}
	}

	/* Use for side/bottom panels to control width */
	&__content {
		width: 100%;
		max-width: @side-panel-width;
		margin: 0 auto;
		
		.--expanded & {
			max-width: @sidebar-width-expanded;
		}

		/* To separate the inner content within content section */
		&.--separate {
			.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between);

			height: 100%;
		}
		
		+ .section__content {
			margin-top: @inner-padding-default;

			@media @mobile-portrait-down {
				margin-top: @inner-padding-small;
			}
		}
	}

	&__header {
		margin-bottom: 1rem;
		
		&.--small {
			margin-bottom: 1.5rem;
		}
		
		&.--small-margin {
			margin-bottom: 0.5rem;
		}

		&.--medium {
			margin-bottom: 2rem;
		}

		&.--large {
			margin-bottom: 3rem;
		}
		
		&.flex-separate {
			margin-bottom: 0;
		}
	}

	&__heading,
	&__title,
	&__paragraph {
		&:not(:only-child) {
			margin-bottom: 1rem;
		}
	}
	
	&__heading {
		.flexbox(@justifyContent: flex-start; @flexFlow: nowrap);
	}
	
	&__paragraph {
		font-size: 1rem;
	}

	&__subheading {
		&:not(:last-child) {
			margin-bottom: .5rem;
		}
		
		+ .section__subheading {
			display: block;
		}
	}

	&__imageWrapper {
		.flexbox();

		&.--medium {
			margin-top: 1rem;
			margin-bottom: 2rem;
		}
	}

	&__image {
		width: 100%;
		max-width: 245px;
		height: auto;

		&.--small {
			max-width: 100px;
		}
	}

	&__row {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start);
	}

	&__column {
		&.--one {
			flex: 1;
		}

		&.--two {
			flex: 2;
		}

		&.--three {
			flex: 3;
		}
	}

	&__bullets {
		padding-left: 1rem;
		margin-bottom: 1rem;
	}

	&__bullet {
		list-style-type: disc;
		margin-bottom: .25rem;
		font-family: @body-font-family;
	}
	
	&__buttonWrapper {
		margin-top: 1.5rem;
	}
}
