.survey {
	@survey-width: 40rem;
	@spacing: 2rem;
	
	&__wrapper {
		background-color: @white;
		border: 1px solid @divider-border-color;
		border-radius: @tertiary-border-radius;
		padding: @spacing;
		width: 100%;
		height: 100%;
		
		@media @mobile-portrait-down {
			border: none;
			padding: 0;
		}
	}
	
	&__inner {
		width: 100%;
		max-width: @survey-width;
		margin: 0 auto;
		display: grid;
		justify-items: stretch;
		grid-template-rows: auto 1fr;
		height: 100%;
		grid-template-areas:
			"header"
			"content";
	}
	
	&__header {
		grid-area: header;
		text-align: center;
	}
	
	&__content {
		grid-area: content;
		height: 100%;
	}
	
	&__buttons {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-end);
		
		&.--center {
			justify-content: center;
		}
		
		button + button {
			margin-left: .5rem;
		}
		
		@media @mobile-portrait-down {
			flex-flow: column;
			justify-content: center;
			
			button + button {
				margin: .5rem 0 0;
			}
			
			.bttn.--inline {
				width: 100%;
			}
		}
	}
	
	&__stepper {
		margin-bottom: @spacing;
	}
	
	&__step {
		display: grid;
		grid-template-rows: 1fr auto;
		height: 100%;
		
		> div {
			align-self: center;
			padding: @spacing 0;
			
			@media @mobile-portrait-down {
				align-self: start;
			}
		}
	}
	
	&__message {
		h1 {
			margin-bottom: .5rem;
		}
	}
}