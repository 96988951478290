.value-slider {
	margin:				0 auto;
	width:				100%;
	box-sizing:			border-box;
	display: 			flex;
	align-items: 		center;
	justify-content: 	flex-start;
	padding-top: 		1rem;
	padding-bottom: 	1rem;

	@media only screen and (max-width: 1200px) {
		flex-flow: 		column;
		align-items: 	stretch;
	}

	@media @split-screen-down {
		flex-flow: row nowrap;
	}

	@media @mobile-landscape-down {
		flex-flow: 		column;
		align-items: 	stretch;
	}

	&__message {
		margin-right: 2rem;

		@media @desktop-up {
			margin-right: 4rem;
		}

		@media @monitor-up {
			margin-right: 5rem;
		}

		@media @laptop-down {
			margin-right: 	0;
			margin-bottom: 	1rem;
		}

		@media @split-screen-down {
			margin-right: 	2rem;
			margin-bottom: 	0;
		}

		@media @mobile-landscape-down {
			margin-right: 	0;
			margin-bottom: 	1rem;
		}
	}

	&__object {
		flex: 		1;
		max-width: 	650px;
	}

	/*
		https://github.com/angular-slider/angularjs-slider
		slider overrides
	*/
	.rzslider {
		.rz-bar-wrapper {
			max-width:	100%;
		}

		.rz-bar {
			background-color:	@blue-highlight-slider;
			height: 			5px;
		}

		.rz-selection {
			background-color: @blue-highlight;
		}

		.rz-tick {
			width: 	0;
			height: 0;
		}

		.rz-tick-value {
			color:		@dark-grey-text-color;
			font-size:	.875rem;
			top:		-28px;
			margin-left: -4px;

			@media @desktop-up {
				font-size: 	.85rem;
			}
	
			@media @monitor-up {
				margin-right: 5rem;
			}

			@media @mobile-portrait-down {
				margin-left: 	-1px;
			}
		}

		.rz-pointer {
			width: 				24px;
			height: 			24px;
			top: 				-10px;
			margin-left: 		0;
			background-color: 	@blue-highlight;
			box-shadow: 		0 2px 4px rgba(2, 20, 49, 0.2);
	
			&::after {
				display: none;
			}

			&:focus,
			&.rz-active {
				box-shadow:	@focus-box-shadow;
				outline:	none;
			}
		}

		.rz-bubble {
			visibility:	hidden !important;
			display: 	none !important;
		}

		.rz-selected {
			&:last-child,
			&.--current {
				.rz-tick-value {
					color: 			@blue-highlight;
					font-weight: 	600;
				}
			}
		}
	}
}

/*
	RZSlider default css
*/
/*! angularjs-slider - v7.0.0 - 
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervi.eu>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> - 
 https://github.com/angular-slider/angularjs-slider - 
 2019-02-23 */
 
.rzslider {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 4px;
	margin: 35px 0 15px 0;
	vertical-align: middle;
	user-select: none;
}

.rzslider.noanimate * {
	transition: none !important;
}

.rzslider.with-legend {
	margin-bottom: 40px;
}

.rzslider[disabled] {
	cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
	cursor: not-allowed;
	background-color: #d8e0f3;
}

.rzslider[disabled] .rz-draggable {
	cursor: not-allowed;
}

.rzslider[disabled] .rz-selection {
	background: #8b91a2;
}

.rzslider[disabled] .rz-tick {
	cursor: not-allowed;
}

.rzslider[disabled] .rz-tick.rz-selected {
	background: #8b91a2;
}

.rzslider span {
	position: absolute;
	display: inline-block;
	white-space: nowrap;
}

.rzslider .rz-base {
	width: 100%;
	height: 100%;
	padding: 0;
}

.rzslider .rz-bar-wrapper {
	left: 0;
	z-index: 1;
	width: 100%;
	height: 32px;
	padding-top: 16px;
	margin-top: -16px;
	box-sizing: border-box;
	transition: all linear 0.3s;
}

.rzslider .rz-draggable {
	cursor: move;
}

.rzslider .rz-bar {
	left: 0;
	z-index: 1;
	width: 100%;
	height: 4px;
	background: #d8e0f3;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}

.rzslider .rz-bar-wrapper.rz-transparent .rz-bar {
	background: transparent;
}

.rzslider .rz-bar-wrapper.rz-left-out-selection .rz-bar {
	background: #df002d;
}

.rzslider .rz-bar-wrapper.rz-right-out-selection .rz-bar {
	background: #03a688;
}

.rzslider .rz-selection {
	z-index: 2;
	background: #0db9f0;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	transition: background-color linear 0.3s;
}

.rzslider .rz-restricted {
	z-index: 3;
	background: #ff0000;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}

.rzslider .rz-pointer {
	top: -14px;
	z-index: 3;
	width: 32px;
	height: 32px;
	cursor: pointer;
	background-color: #0db9f0;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	transition: all linear 0.3s;
}

.rzslider .rz-pointer:after {
	position: absolute;
	top: 12px;
	left: 12px;
	width: 8px;
	height: 8px;
	background: #ffffff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	content: '';
}

.rzslider .rz-pointer:hover:after {
	background-color: #ffffff;
}

.rzslider .rz-pointer.rz-active {
	z-index: 4;
}

.rzslider .rz-pointer.rz-active:after {
	background-color: #451aff;
}

.rzslider .rz-bubble {
	bottom: 16px;
	padding: 1px 3px;
	color: #55637d;
	cursor: default;
	transition: all linear 0.3s;
}

.rzslider .rz-bubble.rz-limit {
	color: #55637d;
	transition: none;
}

.rzslider .rz-ticks {
	position: absolute;
	top: -3px;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 0;
	margin: 0;
	list-style: none;
	box-sizing: border-box;
}

.rzslider .rz-ticks-values-under .rz-tick-value {
	top: auto;
	bottom: -32px;
}

.rzslider .rz-tick {
	position: absolute;
	top: 0;
	left: 0;
	width: 10px;
	height: 10px;
	margin-left: 11px;
	text-align: center;
	cursor: pointer;
	background: #d8e0f3;
	border-radius: 50%;
	transition: background-color linear 0.3s;
}

.rzslider .rz-tick.rz-selected {
	background: #0db9f0;
}

.rzslider .rz-tick-value {
	position: absolute;
	top: -30px;
	transform: translate(-50%, 0);
}

.rzslider .rz-tick-legend {
	position: absolute;
	top: 24px;
	max-width: 50px;
	white-space: normal;
	transform: translate(-50%, 0);
}

.rzslider.rz-vertical {
	position: relative;
	width: 4px;
	height: 100%;
	padding: 0;
	margin: 0 20px;
	vertical-align: baseline;
}

.rzslider.rz-vertical .rz-base {
	width: 100%;
	height: 100%;
	padding: 0;
}

.rzslider.rz-vertical .rz-bar-wrapper {
	top: auto;
	left: 0;
	width: 32px;
	height: 100%;
	padding: 0 0 0 16px;
	margin: 0 0 0 -16px;
}

.rzslider.rz-vertical .rz-bar {
	bottom: 0;
	left: auto;
	width: 4px;
	height: 100%;
}

.rzslider.rz-vertical .rz-pointer {
	top: auto;
	bottom: 0;
	left: -14px !important;
}

.rzslider.rz-vertical .rz-bubble {
	bottom: 0;
	left: 16px !important;
	margin-left: 3px;
}

.rzslider.rz-vertical .rz-ticks {
	top: 0;
	left: -3px;
	z-index: 1;
	width: 0;
	height: 100%;
}

.rzslider.rz-vertical .rz-tick {
	margin-top: 11px;
	margin-left: auto;
	vertical-align: middle;
}

.rzslider.rz-vertical .rz-tick-value {
	top: auto;
	left: 24px;
	transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-tick-legend {
	top: auto;
	right: 24px;
	max-width: none;
	white-space: nowrap;
	transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks-values-under .rz-tick-value {
	right: 24px;
	bottom: auto;
	left: auto;
}
