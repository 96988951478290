.navigation {
	transition: transform 0.4s ease-out, opacity 0.4s ease-out;
	transform: translateY(-100%);
	opacity: 0;
	
	&__list {
		padding-left: 0;
		margin: 0;
	}

	&__item {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start;);
		
		transition: all 0.4s ease-out;
		margin-top:	-30px;
		
		&:not(:last-child) {
			padding-bottom: 0;
		}
	}
	
	&.--expanded {
		transform: translateY(0);
		opacity: 1;
		
		.navigation__item {
			margin-top:	0;
			
			&:not(:last-child) {
				padding-bottom: 1.2rem;
			}
		}
	}

	&__iconWrapper {
		.flexbox(@justifyContent: flex-start;);

		width: 2rem;
	}

	&__icon {
		color: @blue-highlight;
		font-size: .8rem;
		display: inline-block;
		width: 100%;
		max-width: 1rem;
	}

	&__link {
		.transition();

		font-size: 1rem;
		font-weight: 700;
		font-family: @body-font-family;
		color: @default-text-color;
		cursor: pointer;

		&:hover {
			color: @blue-highlight;
		}
	}
}
