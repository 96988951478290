.tooltip {
	.flexbox(@display: inline-flex);

	color: @default-grey-text-color;
	width: 1.5rem;
	height: 1.5rem;

	&__link {
		color: @default-grey-text-color;
		text-decoration: none;
		
		&:hover {
			text-decoration: none;
		}
	}

	&__icon {
		.flexbox();

		width: 100%;
		height: 100%;
		font-size: .875rem;
	}
}
