.sidebar-arrow {
	.flexbox();
	.transition();
	
	width: 1.75rem;
	height: 1.75rem;
	margin-right: .5rem;
	margin-left: -.5rem;
	border: unset;
	background-color: unset;
	border-radius: .5rem;
	
	&:hover {
		background-color: #e5e7ea;
	}
	
	&__icon {
		.transition();
		.flexbox();
	
		color: @default-text-color;
		font-size: .75rem;
	}
}
