.sidebarCallout {
	.flexbox(@flexFlow: nowrap; @alignItems: flex-start;);
	
	&.--create-account {
		align-items: center;
		background-color: @default-text-color;
		padding: 1rem;
		border-radius: 12px;
		color: @white;
		
		.sidebarCallout__message {
			margin-right: .75rem;
		}
	}
	
	&.--alternate {
		background-color: @light-grey-background-color;
		flex-flow: column;
		align-items: stretch;
		margin-bottom: 2rem;
		border-radius: @alternate-border-radius;
		
		> div {
			padding: 1rem 1.25rem;
		}
	}
	
	&__header {
		.flexbox(@flexFlow: column; @alignItems: stretch);
	}
	
	&__pointsWrapper {
		.flexbox(@flexFlow: row nowrap; @justifyContent: space-between);
	}
	
	&__points {
		.flexbox();
	}
	
	&__body {
		border-top: 1px solid @divider-border-color;
	}
	
	&__title {
		margin-left: .5rem;
	}

	&__subtitle {
		margin-bottom: .25rem;
	}
	
	&__description {
		margin-bottom: .5rem;
	}

	&__iconWrapper {
		.flexbox();
		.transition();
		
		margin-top: .25rem;
		
		.--alternate & {
			margin-top: 0;
		}

		&:hover {
			.sidebarCallout__icon {
				color: @blue-highlight;
			}
		}
	}

	&__icon {
		font-size: 1.25rem;
		width: 100%;
		max-width: 1.5rem;
	}
}
