.free-giftcard {
	@padding: 1.875rem;
	@padding-mobile: 1rem;
	@card-width: 144px;
	@card-height: 96px;
	@card-padding: 3px;
	@selected-card-border-width: 2px;
	
	display: grid;
	height:	100%;
	max-width: @side-panel-width;
	grid-template-columns: auto;
    grid-template-rows: 44px 140px auto 195px;
    grid-template-areas: 
        "close"
        "title"
        "content"
        "footer";
		
	@media @split-screen-down {
		grid-template-rows: 140px auto 195px;
		grid-template-areas: 
			"title"
			"content"
			"footer";
	}
	
	* {
		box-sizing: border-box;
	}
    
	&__close {
		grid-area:		close;
		justify-self:	end;
        align-self:		start;
        color:			#BFC4CB;
        padding:		@padding;
        font-size:		1rem;
		
		@media @split-screen-down {
			padding: @padding-mobile;
		}
	}
	&__title {
		grid-area: 		title;
		justify-self:	stretch;
        align-self:		start;
        padding:		@padding;
		text-align: 	center;
		
		@media @split-screen-down {
			padding: @padding-mobile;
		}
		
        & > h2 {
	        margin-bottom:	.5rem;
        }
	}
	&__content {
		grid-area:		content;
		justify-self:	stretch;
        align-self:		center;
        max-height:		100%;
        overflow-y:		auto;
		margin-bottom: 	1rem;
		
		@media @split-screen-down {
			max-height:	240px;
		}
	}
	
	&__cards {
		.flexbox(@alignItems: flex-start);
		
		& > div {
			margin: .25rem;
			width: ~'calc(@{card-width} + (@{card-padding} * 2) + (@{selected-card-border-width} * 2))';
			& > p {
				padding-left: 	.625rem;
			}
			&.selected {
				.free-giftcard__wrapper {
					border:	@selected-card-border-width solid @blue-highlight;
				}
			}
		}
	}
	
	&__wrapper {
		.flexbox();
		
		border-radius: 14px;
		width: ~'calc(@{card-width} + (@{card-padding} * 2) + (@{selected-card-border-width} * 2))';
		height: ~'calc(@{card-height} + (@{card-padding} * 2) + (@{selected-card-border-width} * 2))';
		padding: @card-padding;
	}
	
	&__inner {
		width: @card-width;
		height: @card-height;
		position: relative;
	}
	
	&__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: transparent;
		box-shadow: inset 0 0 0 2px rgba(2, 20, 49, 0.05);
		border-radius: @default-border-radius;
	}
	
	&__image {
		border-radius: @default-border-radius;
		width: 100%;
		height: 100%;
		object-fit: cover;
		box-sizing: border-box;
	}
	
	&__footer {
		grid-area: footer;
		justify-self:	stretch;
        align-self:		end;
        padding:		@padding @padding .625rem;;
		
		@media @split-screen-down {
			padding: @padding-mobile @padding-mobile .625rem;
		}
	}
	
	&__selected {
		border-radius: @default-border-radius;
		border:	1px solid #E5E7EA;
		padding: .5rem;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		align-content: stretch;
		margin-bottom: 2rem;
		
		@media @split-screen-down {
			margin-bottom:	1.25rem;
		}
	}
	
	&__giftcard-wrapper {
		.flexbox();
		
		width:	88px;
		height: 56px;
		margin-right: .75rem;
		img {
			width:	100%;
			height: 100%;
			object-fit: cover;
			border-radius:	4px;
		}
	}
	
	&__details {
		
	}
	
	&__terms {
		&:extend(.text-light);
		
		text-align:		center;
		margin-top: 	.25rem;
	}
}
