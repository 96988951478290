.redeem-homepage {
	@media @tablet-portrait-down {
		min-height: ~'calc(100vh - @{header-height})';
	}
	
    * {
		box-sizing: border-box;
	}
	
	&__inner {
		max-width: 1080px;
    	margin: 0 auto;
    	padding: 1.25rem;
		
		@media @tablet-portrait-down {
			width: 100%;
		}
	}
	
	&__auth {
		background-color: @white;
		max-width: @sidebar-width-expanded;
		margin: 0 auto;
		padding: 1rem;
		
		@media @tablet-portrait-down {
			height: ~'calc(100vh - @{header-height})';
			overflow: auto;
		}
	}
	
	&__header {
		.redeem-homepage__inner {
			.flexbox(@flexFlow: row nowrap; @justifyContent: space-between);
		}
		
		background-color: @white;
		position: sticky;
		top: 0;
		z-index: 5200;
		
		@media @tablet-portrait-down {
			> div {
				flex: 1;
			}
		}
	}
	
	&__heading {
		margin-bottom: .5rem;
		
		@media @tablet-portrait-down {
			margin-bottom: .25rem;
		}
	}
	
	&__description {
		&:extend(.text-light);
		
		margin-bottom: 1.25rem;
		
		@media @tablet-portrait-down {
			margin-bottom: 1.125rem;
		}
	}
}
