.toggle-switch {
	position: relative;
	display: inline-block;
	height: 22px;
	line-height: normal;
	width: 40px;

	&__input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + .toggle-switch__slider {
			background-color: @green;

			&:before {
				background: @white;
				transform: translateX(17px);
			}

			&:after {
				content: none;
				display: block;
				left: 7px;
				text-align: left;
			}
		}
	}

	&__slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #E5E7EA;
		-webkit-transition: .4s;
		transition: .4s;
		border-radius: 34px;

		&:before {
			.transition(@duration: .4s);

			background-color: #CCD0D5;
			border-radius: 50%;
			bottom: 4px;
			content: '';
			height: 17px;
			left: 3px;
			position: absolute;
			top: 2px;
			width: 17px;
		}

		&:after {
			color: @white;
			content: none;
			font-size: 14px;
			right: 7px;
			top: 2px;
			text-align: right;
			position: absolute;
		}
	}
}
