.instagram-posts {
	@grid-border-radius: 12px;

	&__title {
		.flexbox(@justifyContent: flex-start);
		
		padding:	12px 12px 4px 0;
		color:		#818896;
		font-size:	1rem;
		font-weight:400;
		
		> span {
			line-height: 1;
			
			&:first-child {
				margin-right: 4px;
			}
			
			&:nth-child(2) {
			
			}
		}
		
		
	}
	&__images {
		position:	relative;
		& > .ratio-guide {
			width:	100%;
		}
		& > .image-grid {
			position:	absolute;
			top:		0;
			left:		0;
			width:		100%;
			height:		13.4375rem;
			justify-content: stretch !important;
			display: grid;
			grid-template-columns: 33.3% 33.3% 33.3%;
			grid-template-rows: 50% 50%;
			grid-template-areas:
				"grid_0 grid_1 grid_2"
				"grid_3 grid_4 grid_5";
			
			@media @tablet-portrait-down {
				height:		16.083125rem;
			};
			@media @mobile-portrait-down {
				height:		13.193125rem;
			};
			& > div {
				justify-self: 			stretch;
		    	align-self: 			stretch;
		    	background-repeat: 		no-repeat;
		    	background-size: 		cover;
		    	background-position:	center center;
			}
			& > .grid_0 {
				grid-area:		grid_0;
				border-radius:	@grid-border-radius 0 0 0;
			}
			& > .grid_1 {
				grid-area:		grid_1;
			}
			& > .grid_2 {
				grid-area:		grid_2;
				border-radius:	0 @grid-border-radius 0 0;
			}
			& > .grid_3 {
				grid-area:		grid_3;
				border-radius:	0 0 0 @grid-border-radius;
			}
			& > .grid_4 {
				grid-area:		grid_4;
			}
			& > .grid_5 {
				grid-area:		grid_5;
				border-radius:	0 0 @grid-border-radius 0;
			}
		}
	}
}