.form {
	@input-border-color: @medium-grey-text-color;
	@input-color: @default-text-color;
	@input-padding: .5rem;
	@input-height: 2.5rem;
	@label-color: @default-text-color;

	* {
		box-sizing: border-box;
	}

	width: 100%;
	
	&.--hasMultipleInputs {
		.form__column {
			&:not(:last-child) {
				margin-right: 1.25rem;
			}
		}
		
		.form__inputWrapper {
			height: auto;
		}
		
		.form__input {
			height: 3.125rem;
			text-align: center;
			font-size: 2rem;

			&:focus {
				&:not(:placeholder-shown) {
					border-color: @blue-highlight;
				}
			}

		}
	}

	&__row {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start);
		
		&:not(:last-child) {
			margin-bottom: .5rem;
		}

		&.--medium {
			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
		}

		&.--large {
			&:not(:last-child) {
				margin-bottom: 2.5rem;
			}
		}

		&.--isEdit {
			justify-content: space-between;
		}
	}

	&__column {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: flex-start;);

		flex: 1;
	}

	&__label {
		.transition();
		
		color: @label-color;
		font-size: .75rem;
		padding-left: @input-padding;
	}

	&__inputWrapper {
		.flexbox(@flexFlow: column-reverse; @justifyContent: flex-start; @alignItems: stretch);
		
		overflow: hidden;
		width: 100%;
		height: 3.75rem;

		&.--hasIcon {
			position: relative;
		}

		&.--error {
			.form__input {
				border-color: @red;
			}
			
			.form__input:not(:placeholder-shown) + .form__label,
			.form__input:placeholder-shown + .form__label,
			.form__input:focus + .form__label {
				color: @red;
			}
		}
		
		&.--isCopied {
			.form__eventFeedback {
				color: @green;
			}
			
			.form__iconWrapper.--hasBackground {
				background-color: @green;
			}
		}
	}

	&__input {
		.transition();

		width: 100%;
		border-radius: @default-border-radius;
		padding: @input-padding;
		height: @input-height;
		line-height: 1;
		background-color: @white;
		font-size: 1rem;
		font-family: @body-font-family;
		font-weight: 400;
		color: @input-color;
		border: 2px solid @input-border-color;
		-webkit-appearance: none;
		
		.--hasIcon & {
			padding: @input-padding 2rem @input-padding @input-padding;
		}
		
		&.--disabled {
			background-color:	#E5E7EA !important;
			border-color:		#E5E7EA !important;
		}
		
		[ngclipboard] & {
			cursor: copy;
		}
		
		
		/*
			Floating Labels
		*/
		
		// Make label look like placeholder text
		&:placeholder-shown + .form__label {
			cursor: text;
			max-width: ~'calc(100% - @{input-padding})';
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transform-origin: left bottom;
			transform: translateY(1.8rem); // Vertically center label inside input
			font-size: 1rem;
		}
		
		// Hide placeholder by default, label is pretending to be the placeholder text
		&::-webkit-input-placeholder,
		&::placeholder {
			opacity: 0;
		}
		
		// Remove Chrome autofill background 
		&:-webkit-autofill { 
			-webkit-background-clip: text;
			
			// Make autofill text same as input font size
			&::first-line {
				font-size: 1rem;
			}
		}
		
		// Input has gained focus, move label above input	
		&:focus + .form__label,
		&:not(:placeholder-shown) + .form__label,
		&:-webkit-autofill + .form__label {
			transform: translateY(-3px);
			cursor: default;
			font-size: .875rem;
			padding-left: .3rem;
		}
		
		// Label is above input - focused, inactive state
		&:focus {
			outline: 0;
			border-color: @blue-highlight;
			
			+ .form__label {
				color: @blue-highlight;
			}
		}
		
		// Label is above input - focused, active state
		&:not(:placeholder-shown) {
			border-color: @input-border-color;
			
			+ .form__label {
				color: @label-color;
			}
		}
	}

	&__iconWrapper {
		.--hasIcon & {
			.flexbox();

			position: absolute;
			height: @input-height;
			right: @input-padding;
			left: unset;
		}
		
		&.--hasBackground {
			.transition(@prop: background-color);
			
			background-color: @selected-grey-background-color;
			border-radius: @alternate-border-radius;
			width: 1.875rem;
			height: 1.875rem;
			right: 5px;
			bottom: 5px;
			
			.form__icon {
				color: @white;
			}
			
			[ngclipboard] & {
				cursor: copy;
			}
		}
	}

	&__icon {
		color: @blue-highlight;
		font-size: 1rem;
		align-self: flex-end;

		.--hasIcon & {
			.transition(@prop: color);

			align-self: center;
			line-height: normal;
		}

		&.--grey {
			color: @light-grey-text-color;
		}

		&.--success {
			color: @green;
		}
		
		&.--error {
			color: @red;
		}
	}

	&__caption {
		margin-top: .75rem;
	}
	
	&__buttonWrapper {
		margin-top: 2rem;
	}
	
	&__eventFeedback {
		position: absolute;
		top: 0;
		font-size: .75rem;
		font-weight: 400;
		font-family: @body-font-family;
		
		&.--right {
			right: .3rem;	
		}
		
		&.--left {
			left: .3rem;	
		}
	}
	
	&__errorMessage {
		text-align: center;
		margin-bottom: .75rem;
		
		span {
			color: @red;
			font-size: .875rem;
			font-weight: 700;
		}
	}
	
	&__select {
		border: 2px solid @input-border-color;
		border-radius: @default-border-radius;
		box-shadow: none;
		outline: none;
		width: 100%;
		height: 40px;
		padding: 0.5rem 1.75rem 0.5rem 0.5rem;
		font-size: 100%;
		appearance: none; // Hide default down arrow
		cursor: pointer;
		background-color: @white;
		
		+ label {
			transform: translateY(-3px);
			cursor: default;
			font-size: .875rem;
			padding-left: .3rem;
			color: @label-color;
		}
	}
}
