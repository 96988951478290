.multi-select-step {
	@item-width: 5rem;
	@item-spacing-horizontal: .5rem;
	@item-spacing-vertical: .75rem;
	
	.flexbox();
	
	&__grid {
		display: grid;
		grid-gap: 1.5rem;
		grid-template-columns: ~'repeat(3, calc(@{item-width} + (@{item-spacing-vertical} * 2)))';
		
		@media @mobile-portrait-down {
			grid-gap: .5rem;
		}
	}
	
	&__item {
		.flexbox();
		
		border: 2px solid transparent;
		padding: @item-spacing-horizontal @item-spacing-vertical;
		border-radius: @alternate-border-radius;
		
		&.--selected {
			border: 2px solid @blue-highlight;
		}
	}
	
	&__image {
		width: @item-width;
		height: @item-width;
		object-fit: contain;
		border: 1px solid @divider-border-color;
		border-radius: @alternate-border-radius;
	}
	
	&__text {
		margin-top: .5rem;
		line-height: 1;
		font-weight: 700;
	}
}