.checkbox-step {
	&__grid {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 3rem 1.5rem;
		
		@media @mobile-portrait-down {
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 2rem .5rem;
		}
	}
	
	&__item {
		&.--selected {
			.checkbox__control::before {
				opacity: 1;
			}
		}
	}
}