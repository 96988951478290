.pointsSlider {
	overflow: hidden;

	* {
		box-sizing: border-box;
	}

	&__scrollList {
		.flexbox(@flexFlow: row nowrap; @alignItems: flex-start; @justifyContent: flex-start;);

		margin: 0;
		padding: 0;
	}

	&__scrollItem {
		margin-bottom: 0;
		padding: 0 1rem 0 0;
		
		&:focus {
			outline: none;
		}
	}
}
