.progressBar {
	.flexbox(@flexFlow: row nowrap; @justifyContent: space-between);

	@semi-circle-width: 11.5625rem;
	@semi-circle-height: 6.25rem;

	&.--horizontal {
		height: .875rem;
	}

	&.--semiCircle {
		align-items: flex-start;
		justify-content: center;
		margin: 2rem 0 1rem;
		min-height: @semi-circle-height;
	}
	
	&.--withPendingText {
		min-height: ~'calc(@{semi-circle-height} + 1.25rem)';
	}

	&__column {
		.flexbox(@flexFlow: column;);

		position: relative;

		&:last-child:not(:only-child) {
			margin-left: 1rem;
			align-self: center;
		}
	}

	&__circWrapper {
		position: relative;
		width: @semi-circle-width;
		height: @semi-circle-height;

		+ .progressBar__textWrapper {
			position: absolute;
			left: 0;
			top: 2.5rem;
			width: 100%;
			text-align: center;
		}
	}

	&__circ {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 2;

		&.--pending {
			z-index: 1;
		}

		> svg {
			width: 100%;
			height: 100%;
			stroke-linecap: round;
		}
	}

	&__bar {
		border-radius: @default-border-radius;
		flex: 1;
		height: 100%;
		margin-right: 1rem;
		background-color: @progress-bar-background-color;
		position: relative;
		overflow: hidden;
		z-index: 1;
	}

	&__progress {
		.transition(@prop: width; @duration: 1.5s);

		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		border-radius: inherit;

		&.--pending {
			z-index: 2;
			background-color: @blue-highlight-slider;
		}

		&.--current {
			z-index: 3;
		}

		&.--unlocked {
			background-color: @yellow;
		}

		&.--locked {
			background-color: @blue-highlight;
		}
	}

	&__textWrapper {
		.flexbox(@flexFlow: column; @alignItems: stretch;);
	}

	&__text {
		color: @default-text-color;

		&.--target {
			font-size: .75rem;
			text-align: center;
		}

		&.--next {
			text-transform: uppercase;
			font-weight: 700;
			font-size: .675rem;
			line-height: 1.2;
			
			> span {
				font-weight: 400;
			}
		}

		&.--light {
			color: @light-grey-text-color;
			text-transform: none;
		}

		&.--pending {
			color: @blue-highlight;
			margin-top: .25rem;

			&.--small {
				font-size: .75rem;
				margin-top: .5rem;
			}
		}
	}
	
	&__label {
		font-weight: 700;
		font-size: .75rem;
		text-transform:	uppercase;
	}
}
