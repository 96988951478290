.animated-onboarding {
	margin-top: 1.5rem;
	
	* {
		box-sizing: border-box;
	}
	
	&__scene {
		background-color: @blue-highlight;
		width: 23.4375rem;
		height: 15.625rem;
	}
	
	&__animation {
		.flexbox(@flexFlow: column;);
		
		width: 100%;
		height: 100%;
		overflow: hidden;
		
		img {
			width: 100%;
			max-width: 100%;
			height: auto;
			display: inline-block;
		}
		
		
		
		/*
			Animation types
		*/
		
		&.--bounce {
			.animated-onboarding__stack {
				animation: 3s bounceMoveVertical ease-in-out infinite;
			}
			
			.animated-onboarding__shadow {
				.flexbox();
				
				z-index: 1;
				width: 100%;
				animation: 3s bounceShadowOpacity ease-in-out infinite;
				
				img {
					width: 18.75rem;
				}
			}
			
			.animated-onboarding__tail {
				z-index: 2;
				bottom: 1.75rem;
				left: 2rem;
				animation: 3s bounceRotateTail ease-in-out infinite;
				
				img {
					width: 16.375rem;
				}
			}
			
			.animated-onboarding__body {
				z-index: 3;
				
				img {
					width: 14.375rem;
				}
			}
			
			.animated-onboarding__tag {
				z-index: 4;
				top: 0;
				right: 3.875rem;
				animation: 3s bounceRotateTag ease-in-out infinite;
				
				img {
					width: 9.375rem;
				}
			}
		}
		
		// Hide before the slide is active
		&.--fly-hidden {
			visibility: hidden;
			opacity: 0;
		}
		
		// Slide is active - begin animation
		&.--fly {
			visibility: visible;
			opacity: 1;
			
			.animated-onboarding__stack {
				transform: translateX(-100%);
				animation: 1.25s flyMoveHorizontal ease-out forwards;
			}
			
			.animated-onboarding__motion {
				z-index: 1;
				animation: 1.25s flyMoveMotion ease-out forwards;
				
				img {
					width: 12.8125rem;
				}
			}
			
			.animated-onboarding__tail {
				z-index: 2;
				animation: 1.1s flyMoveRotateTail ease-out forwards;
				
				img {
					width: 10.625rem;
				}
			}
			
			.animated-onboarding__body {
				z-index: 3;
				top: 0;
				right: -0.9375rem;
    			animation: 1.25s flyRotateBody ease-out forwards;
				
				img {
					width: 21.125rem;
				}
			}
		}
		
		
		&.--sparkle {
			@small-star-width: 1.125rem;
			@big-star-width: 1.5625rem;
			
			.animated-onboarding__coins {
				transform: translate(0, 1.375rem);
				
				img {
					width: 13.75rem;
				}
			}
			
			.animated-onboarding__star1 {
				top: 1.75rem;
				right: 9.875rem;
				animation: 1.5s sparkleStarOpacity infinite;
				
				img {
					width: @big-star-width;
				}
			}
			
			.animated-onboarding__star2 {
				top: 2.6875rem;
				right: 9.5rem;
				animation: 1.75s sparkleStarOpacity .25s infinite;
				
				img {
					width: @small-star-width;
				}
			}
			
			.animated-onboarding__star3 {
				left: 7.8125rem;
    			bottom: 5.9375rem;
				animation: 2s sparkleStarOpacity .5s infinite;
				
				img {
					width: @small-star-width;
				}
			}
			
			.animated-onboarding__star4 {
				right: 7.125rem;
    			top: 3.6875rem;
				animation: 2.25s sparkleStarOpacity .75s infinite;
				
				img {
					width: @big-star-width;
				}
			}
			
			.animated-onboarding__star5 {
				right: 7.625rem;
    			bottom: 5.1875rem;
				animation: 2.5s sparkleStarOpacity 1s infinite;
				
				img {
					width: @small-star-width;
				}
			}
		}
	}
	
	// Stack the assets
	&__stack {
		.flexbox(@flexFlow: column;);
		
		position: relative;
		width: 100%;
		height: 100%;
		
		> span {
			position: absolute;
		}
	}
	
	

	/*
		Keyframes
	*/
	
	// Bounce animations
	@keyframes bounceMoveVertical {
		30%, 100% {
			transform: translate(0, 0);
		}

		90% {
			transform: translate(0, 0.625rem);
		}
	}

	@keyframes bounceShadowOpacity {
		0%, 100% {
			opacity: .65;
		}

		85%, 90% {
			opacity: 1;
		}
	}

	@keyframes bounceRotateTail {
		30%, 100% {
			transform: rotate(0deg);
		}

		90% {
			transform: rotate(-2deg);
		}
	}

	@keyframes bounceRotateTag {
		30%, 100% {
			transform: rotate(0deg);
		}

		90% {
			transform: rotate(2deg);
		}
	}



	// Fly animations

	@keyframes flyMoveHorizontal {
		100% {
			transform: translateX(0);
		}
	}

	@keyframes flyMoveMotion {
		0%, 20% {
			transform: translate(0, -1rem);
		}
		
		70%, 100% {
			transform: translate(-4rem, -1rem);
		}
	}

	@keyframes flyMoveRotateTail {
		0%, 20% {
			transform: translate(0, 2.0625rem) rotate(2deg);
		}
		
		70%, 100% {
			transform: translate(-1.875rem, 2.0625rem) rotate(0deg);
		}
	}

	@keyframes flyRotateBody {
		0%, 20% {
			transform: rotate(3deg);
		}
		
		70%, 100% {
			transform: rotate(0deg);
		}
	}



	// Sparkle animations
	@keyframes sparkleStarOpacity {
		0%, 100% {
			opacity: 0;
		} 
		
		50% {
			opacity: 1;
		}
	}
}
