.tabs-list {

	.flexbox();

	width: 100%;
	text-align: center;

	&__tab {
		.transition(@prop: color;);

		flex: 1;
		color: @dark-grey-text-color;
		font-family: @body-font-family;
		font-weight: 400;
		font-size: .875rem;
		margin: 0;
		background-color: unset;
		border:none;

		&.--current {
			color: @blue-highlight;
			background-color: #E4F5FF;
			padding: .5rem;
			border-radius: 10px;
			font-weight: 700;
		}

	}
}