.checkout-confirmation {
    .panel__content {
        justify-content: space-between !important;
    }
    .body {
        width:      100%;
        padding:    2rem 3rem 0;
        box-sizing: border-box;

        @media @mobile-portrait-down {
            padding:        0 2rem;
            margin-bottom:  7rem;
        }
        img {
            width:      100%;
            max-width:  75%;
            display:    block;

            @media @mobile-portrait-down {
                margin-bottom: 2rem;
            }
        }
    }
    
    &__details {
        text-align: center;
        margin-top: 2rem;
    }
    
    &__cost {
        &:extend(.text-extra-large);
        
        &.--sup {
            &::first-letter {
                vertical-align: super;
                font-size: 1.125rem;
            }
        }
        
        margin-bottom: .875rem;
    }
}
