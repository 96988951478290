@fill-color: @blue-highlight;
@border-color: @blue-highlight;
@text-color: @blue-highlight;
@circle-width: 1.25rem;
@dot-width: .75rem;

.stepperWrapper {
	background-color: white;
	height: auto;

	.stepper {
		display: flex;
		margin: 0 auto;
		padding: 0;
		width: 100%;

		.step {
			padding: 1rem;
			position: relative;
			width: ~'calc(100% / 5)';
			z-index: 2;

			.step__circle {
				border: 3px solid @filtered-grey-background-color;
				border-radius: 50%;
				font-size: .5rem;
				font-weight: 600;
				width: @circle-width;
				height: @circle-width;
				line-height: 1;
				margin: 0 auto;
				position: relative;
				text-align: center;
				z-index: 2;
			}

			&.active {
				.step__circle {
					&::after {
						background-color: @fill-color;
						content: '';
						display: inline-block;
						width: @dot-width;
						height: @dot-width;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.step__bar--left {
					background-color: @fill-color;
					border-color: @fill-color;
					width: ~'calc(100% - 8px)';
					z-index: 3;
				}
			}

			&.partial {
				.step__circle {
					background: @white;

					&::after {
						content: '';
						display: inline-block;
						width: 12px;
						height: 12px;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			&.done {
				z-index: 3;

				.step__circle {
					background-color: @fill-color;
					border: 3px solid @fill-color;
					color: @white;

					&:before {
						content: "\f00c";
						font-family: @font-awesome;
						font-weight: 700;
						font-size: .675rem;
						left: 2px;
						position: absolute;
						top: 3px;
						z-index: 4;
					}

					* {
						display: none;
					}
				}

				.step__bar--left {
					background-color: @fill-color;
					border-color: @fill-color;
					width: ~'calc(100% - 1rem)';
					z-index: 3;
				}
			}

			.step__bar--left {
				background-color: @filtered-grey-background-color;
				border: solid @filtered-grey-background-color;
				border-width: 1px 0;
				height: 1px;
				position: absolute;
				width: ~'calc(100% - 1rem)';
				z-index: -1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				margin-left: -50%;
			}
		}
	}
}