.checkbox {
	position: relative;
	display:flex;

	&:focus-within {
		.checkbox__control:after {
			border: 2px solid @blue-highlight;
		}
	}

	&__input {

		/* Hide real input and use fake controller to style */
		&[type="checkbox"]:not(:checked),
		&[type="checkbox"]:checked {
			position: absolute;
			opacity: 0;
			pointer-events: none;
		}

		/* Checked styles */
		&[type="checkbox"]:checked {
			+ .checkbox__control {
				/* Show the check when checked */
				&:before {
					opacity: 1;
				}
			}
		}

		/* Disabled styles */
		&[type="checkbox"]:not(:checked),
		&[type="checkbox"]:checked {
			&:disabled + .checkbox__control {
				opacity: .5;

				&:after {
					border: 1px solid @divider-border-color;
					cursor: not-allowed;
				}
			}
		}

		/* Disabled styles when not checked */
		&[type="checkbox"]:not(:checked) {
			&:disabled + .checkbox__control {
				&:before {
					opacity: 0;
				}
			}
		}

		/* Disabled styles when checked */
		&[type="checkbox"]:checked {
			&:disabled + .checkbox__control {
				&:before {
					opacity: 1;
					color: @dark-grey-text-color;
				}
			}
		}
	}
	
	/* Fake checkbox controller */
	&__control {
		.user-select();

		position: relative;
		padding-left: 1.5rem;
		display: inline-block;

		/* Style the checkbox */
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: .875rem;
			height: .875rem;
			z-index: 0;
			border-radius: 1px;
			margin-top: 0;
			cursor: pointer;
		}

		/* Style the check, hide when not checked */
		&:before {
			.transition(@duration: .2s; @prop: opacity;);

			opacity: 0;
			content: '\f00c';
			font-family: @font-awesome;
			color: @blue-highlight;
			font-size: 0.675rem;
			border: none;
			left: 4px;
			top: 3px;
		}

		/* Default checkbox border color */
		&:after {
			border: 2px solid @light-grey-border-color;
			border-radius: 4px;
		}
	}
}
