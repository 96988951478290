.value-selector {
	&__box {
		background-color: 	#e6f4fe;
		padding:			5px;
		border-radius:		10px;
		display:			inline-flex;
		flex-direction:		row;
		flex-wrap: 			nowrap;
		justify-content:	flex-start;
		
		.sticky__slider & {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}
	&__label {
		display:		inline-block;
		color:			#021431;
		font-size:		1rem;
		font-weight:	400;
		padding:		5px;
		align-self:		center;
	}
	&__sep {
		display:			inline-block;
		background-color:	#9bd4fd;
		width:				2px;
		height:				18px;
		align-self:			center;
	}
	&__value {
		display:		inline-block;
		color:			#021431;
		font-size:		1rem;
		font-weight:	600;
		padding:		5px;
		align-self:		center;
	}
	&__edit {
		display:		inline-block;
		color:			#40BDFF;
		font-size:		0.9rem;
		font-weight:	400;
		align-self:		center;
	}
}

.value-selector-box {
	.hover-button {
		float:				right;
		display:			flex;
		flex-direction:		row;
		flex-wrap: 			nowrap;
		justify-content:	center;
		width:				20px;
		height:				20px;
		border-radius:		5px;
		transition: 		.3s background-color ease-out;
		background-color: 	rgba(0,0,0,0);
		&:hover {
			background-color: rgba(0,0,0,0.1);
		}
		span {
			align-self:			center;
		}
	}
}