.radio {
	@radio-label-color: #CCD0D5;
	@radio-border-color: #BFC4CB;
	
	&:not(:last-child) {
		margin-bottom: .75rem;
	}
	
	&:hover {
		.radio__input[type="radio"]:not(:checked) + label:after {
			background: @blue-highlight;
			opacity: .25;
			transform: scale(1);
		}
	}
	
	&__input {
		&[type="radio"]:checked,
		&[type="radio"]:not(:checked) {
			left: -9999px;
			position: absolute;
		}

		&[type="radio"]:checked + label,
		&[type="radio"]:not(:checked) + label {
			color: @radio-label-color;
			cursor: pointer;
			line-height: 20px;
			padding-left: 28px;
			position: relative;
			user-select: none;
		}

		&[type="radio"]:checked + label:before,
		&[type="radio"]:not(:checked) + label:before {
			background: @white;
			border: 3px solid @radio-border-color;
			border-radius: 100%;
			content: '';
			height: 20px;
			left: 0;
			position: absolute;
			top: 0;
			width: 20px;
			box-sizing: border-box;
		}

		&[type="radio"]:checked + label:after,
		&[type="radio"]:not(:checked) + label:after {
			background: @blue-highlight;
			border-radius: 100%;
			content: '';
			height: 10px;
			left: 5px;
			position: absolute;
			top: 5px;
			transition: all 0.2s ease;
			width: 10px;
		}

		&[type="radio"]:not(:checked) + label:after {
			opacity: 0;
			transform: scale(0);
		}

		&[type="radio"]:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}
	}
}
