.tabs-modern {
	&__header {
		background-color: @white;
		position: sticky;
		top: 0;
		z-index: 5200;
		padding: @inner-padding-default @inner-padding-default 1rem;

		@media @mobile-portrait-down {
			padding: @inner-padding-small;
		}
		
		+ .section__inner {
			border: none;
		}
	}
	
	&__iconWrapper {
		.flexbox();

		position: absolute;
		left: unset;
		top: 1rem;
		right: 1rem;
		width: 20px;
		height: 20px;
		
		&:hover {
			.sidebar__icon {
				color: @blue-highlight;
			}
		}
	}

	&__icon {
		.transition();
		.flexbox();

		color: @default-text-color;
		font-size: 1.65rem;
		width: 100%;
		height: 100%;
	}
	
	&__heading {
		.flexbox(@justifyContent: flex-start);
		
		margin-bottom: 1rem;
	}
	
	&__list {
		.flexbox();

		width: 100%;
		text-align: center;
	}

	&__item {
		.transition(@prop: color;);

		flex: 1;
		color: @dark-grey-text-color;
		font-family: @body-font-family;
		font-weight: 400;
		font-size: .875rem;
		margin: 0;

		&.--current {
			color: @blue-highlight;
			background-color: #E4F5FF;
			padding: .5rem;
			border-radius: 10px;
			font-weight: 700;
		}
	}

	&__section {
		/* Fade in when switching tabs */
		&.ng-enter {
			animation: fadeIn 0.4s ease-out;
			-webkit-animation: fadeIn 0.4s ease-out;
		}
	}
}
