.animated-digits {
	&:extend(h1);
	
	&__column {
		display:	inline-block;
		width:		18px;
		height:		30px;
		overflow:	hidden;
		text-align: center;
	}
	&__digits {
		transition:	transform 1s ease-out;
		transform: translateY(0);
		
		& > div {
			width:		18px;
			height:		36px;
		}
	}
}
