.giftcard-image {
	@card-aspect-ratio: 63.33333%; // Aspect ratio 300 / 190
	@card-aspect-ratio-circle: 100%; // Aspect ratio 1 / 1
	@card-padding: 3px;
	@card-border-radius: 8px;
	@card-border-radius-circle: 50%;
	@card-offset: 2px; // To deal with cards that have borders
	
	.flexbox();
	
	padding: @card-padding;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	
	* {
		box-sizing: border-box;
	}
	
	&__wrapper {
		width: 100%;
		height: auto;
		position: relative;
		border-radius: @card-border-radius;
		overflow: hidden;
		z-index: 0;
		padding-top: @card-aspect-ratio; // If aspect-ratio not supported
		
		// Maintain card aspect ratio
		@supports (aspect-ratio: 300 / 190) {
			aspect-ratio: 300 / 190;
			padding-top: 0;
		}
		
		&.--circ {
			border-radius: @card-border-radius-circle;
			padding-top: @card-aspect-ratio-circle; // If aspect-ratio not supported
			border: 2px solid transparent;
			
			// Maintain card aspect ratio
			@supports (aspect-ratio: 1 / 1) {
				aspect-ratio: 1 / 1;
				padding-top: 0;
			}

			&.--selected {
				border: 2px solid @blue-highlight;
			}
		}
		
		> .giftcard-image__image,
		> .giftcard-image__clear-overlay {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: inherit;
		}
	}
	
	&__image {
		object-fit: cover;
		object-position: center center;
		z-index: 1;
		display: inline-block;
		width: 100%;
		height: 100%;
		margin: 0;
		transform: scale(1.04); // Zoom in a little to make images with a border look like it has no border
	}
	
	&__clear-overlay {
		width: 100%;
		height: 100%;
		background-color: transparent;
		box-shadow: inset 0 0 0 2px rgba(2, 20, 49, 0.05);
		z-index: 2;
	}
}