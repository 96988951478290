.shopify-slider {
	&__title {
		color:		#818896;
		margin-bottom: 5px;
	}
}

.shopify-product {
	justify-content: stretch !important;
	border: 1px solid #e7e9eb;
	border-radius: 10px;
	display: grid;
	grid-template-columns: 5rem; /* 60px + 10px margin around */
	grid-template-rows: auto auto;
	grid-template-areas:
		"image title"
		"image cost";

	&__image {
		grid-area: image;
		justify-self: stretch;
		align-self: stretch;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		border-radius:	7px;
		height:	60px;
		margin:	10px;
	}
	&__title {
		grid-area: title;
		justify-self: start;
		align-self: end;
		padding: 0 10px;
		padding-bottom: 5px;
		font-weight: 600;
	}
	&__cost {
		grid-area: cost;
		justify-self: start;
		align-self: start;
		padding: 0 10px;
	}
}