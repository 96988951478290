.select-dropdown {
	@select-dropdown-background-color-primary: @white;
	@select-dropdown-border-color: #E5E7EA
	;
	@select-dropdown-border-radius: .5rem;
	@select-dropdown-box-shadow: 0px 4px 8px rgba(0,0,0,.1);
	@select-dropdown-caret-color: #CCD0D5;
	@select-dropdown-font-size: .875rem;
	
	@select-dropdown-ul-padding: .75rem .875rem;
	@select-dropdown-ul-padding-small: .5rem;
	
	@select-dropdown-li-color: #99A1AC;
	@select-dropdown-li-hovered-background-color: #F5F5F7;
	@select-dropdown-li-padding: .5625rem .875rem;
	@select-dropdown-li-separator-background-color: #F5F5F7;
	
	font-size: .875rem;
	
	&__dropdown {
		.transition(@duration: .8s; @timing: ease-out);

		background: @white;
		border-radius: @select-dropdown-border-radius;
		display: inline-block;
		font-family: @body-font-family;
		font-weight: 500;
		outline: none;
		position: relative;
		user-select: none;
		width: 100%;
		border: 1px solid @select-dropdown-li-separator-background-color;

		&.--change-border-radius {
			border-radius: @select-dropdown-border-radius @select-dropdown-border-radius 0 0;
		}
	}

	&__selected-option-container {
		.flexbox(@justifyContent: space-between);
		.transition();

		padding: @select-dropdown-ul-padding;
		
		&.--small {
			padding: @select-dropdown-ul-padding-small;
		}
		
		&.--withoutOptions {
			pointer-events: none;
			cursor: default;
		}
	}
	
	&__image {
		width: 12px;
		height: 12px;
		display: inline-block;
		margin-right: .35rem;
	}
	
	&__label {
		color: @default-text-color;
		font-family: @body-font-family;
		font-size: @select-dropdown-font-size;
		font-weight: 600;
	}
	
	&__icon {
		.transition();

		color: @select-dropdown-caret-color;
		margin-left: .625rem;

		&.--rotated {
			transform: rotate(-180deg);
		}
	}

	&__ul {
		.transition();

		background: @select-dropdown-background-color-primary;
		border: none;
		border-radius: 0 0 @select-dropdown-border-radius @select-dropdown-border-radius;
		box-shadow: @select-dropdown-box-shadow;
		box-sizing: border-box;
		list-style: none;
		overflow-y: auto;
		position: absolute;
		margin-top: 0;
		max-height: 0;
		padding: 0;
		width: inherit;
		z-index: 100;

		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */

		&::-webkit-scrollbar-thumb {
			border: 0;
			border-radius: 8px;
		}
		
		&::-webkit-scrollbar {
			display: none;
		}

		&:hover {
			&::-webkit-scrollbar {
				display: initial;
				width: .5rem;
			}
		}

		&.--show {
			max-height: 250px;
		}
	}

	&__li {
		.flexbox(@justifyContent: flex-start);
		.transition();
		
		color: @select-dropdown-li-color;
		cursor: pointer;
		font-family: @body-font-family;
		font-weight: 600;
		margin-bottom: 0;
		padding: @select-dropdown-li-padding;
		position: relative;

		&.--selected {
			color: @default-text-color;
			pointer-events: none;
		}
		
		&.--small {
			padding: @select-dropdown-ul-padding-small;
		}

		&:hover {
			background: @select-dropdown-li-hovered-background-color;
		}

		&:not(:last-child) {
			&:after {
				background: @select-dropdown-li-separator-background-color;
				bottom: 0;
				content: ' ';
				height: .0625rem;
				left: 0;
				position: absolute;
				transform: translateX(.875rem);
				width: ~'calc(100% - 1.75rem)';
			}
		}
	}
}
