.modal {
	background-color: @white;
	border-radius: @tertiary-border-radius;
	position: relative;
	color: @default-text-color;
	height: 100%;
	max-height: 85vh;
	overflow: auto;
	box-shadow: 0 0 10px rgba(2,20,49,.15);
	
	&.--fullScreen {
		.flexbox(@flexFlow: column; @alignItems: stretch);
		
		width: 100%;
		max-height: 100vh;
		z-index: 52000;
		top: 0;
		left: 0;
		position: fixed;
		border-radius: 0;
		text-align: center;
		
		.modal__inner {
			.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between);
			
			height: 100%;
		}
	}
	
	&.--iframe {
		.modal__inner {
			position: relative;
			padding: 0;
		}
	}
	
	&.--handleError {
		.modal__body {
			.flexbox(@flexFlow: row wrap; @alignItems: flex-end;);
			
			height: 70%;
			
			* {
				color: @red;
			}
		}
		
		.modal__graphics {
			justify-content: flex-end;
		}
		
		.modal__copy {
			align-self: center;
		}
	}
	
	&.--blueBackground {
		background: @blue-highlight;
		
		* {
			color: @white;
		}
	}
	
	&__inner {
		padding: 2.5rem;
		
		@media @mobile-portrait-down {
			padding: 1.5rem;
		}
	}
	
	&__close {
		.flexbox();
		
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 2.5rem;
		height: 2.5rem;
		z-index: 52001;
		background-color: unset;
		border: unset;

		&:hover {
			background-color: #e5e7ea;
			border-radius: 0.5rem;
		}
		
		> i {
			.transition();
			
			font-size: 1.75rem;
			
			&:hover {
				color: #808997;
			}
		}
		
		@media @mobile-portrait-down {
			top: .5rem;
			right: .5rem;
		}
	}
	
	&__header {
		margin-top: 1.25rem;
		
		.bottom-panel & {
			margin-top: 0;
		}
	}
	
	&__subtitle {
		&:extend(.text-light);
		
		margin-top: .75rem;
	}
	
	&__caption {
		margin-top: 1rem;
	}
	
	&__body {
		margin-top: 2rem;
		
		p {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
		
		> img {
			max-width: 70%;
			border-radius: 8px;
		}
	}
	
	&__buttonWrapper {
		margin-top: 2rem;
		
		.bttn +  .bttn {
			margin-top: 1rem;
		}
	}
	
	&__graphics {
		.flexbox(@flexFlow: column);
		
		i {
			font-size: 6.25rem;
			z-index: 2;
			position: relative;
		}
		
		img {
			z-index: 1;
			position: relative;
			
			&:first-child {
				transform: translateY(1rem);
			}
			
			&:last-child {
				transform: rotate(180deg) translateY(1rem);
			}
		}
	}
}