.profile {
	&__row {
		margin-bottom: 1.25rem;
	}
	
	&__key {
		margin-bottom: 1rem;
		font-weight: 700;
		font-size: 1rem;
		
		&.--small {
			margin-bottom: .5rem;
		}
	}
	
	&__valuesWrapper {
		.flexbox(@justifyContent: space-between; @flexFlow: row nowrap);
	}
	
	&__value {
		color: @light-grey-text-color;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}
	
	&__statusWrapper {
		.flexbox(@justifyContent: flex-end);
		
		margin-left: 1rem;
	}
	
	&__status {
		+ .profile__icon {
			margin-left: 1rem;
			color: @default-grey-text-color;
		}
	}
	
	&__icon {
		font-size: 1rem;
		
		&.--alert {
			font-size: 1.5rem;
		}
		
		+ .profile__status {
			margin-left: .5rem;
		}
	}
}
