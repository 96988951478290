/*
	Overrides for glider.js package
*/

.glider {
	@small-arrow-width: 1.8rem;
	
	// Hide the horizontal scrollbar beneath carousel
	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		display: none !important;
	}
	
	// Firefox - Hide the horizontal scrollbar beneath carousel
	@supports (scrollbar-width: none) {
		scrollbar-width: none;
	}
	
	&.--small {
		.glider-slide {
			min-width: 76px !important;
			
			&:not(:first-child) {
				margin-left: .5rem;
			}
		}
	}
	
	.glider-slide {
		min-width: 150px !important; // Set min width so layout doesn't break when going from desktop to mobile view
		
		&--wide {
			min-width: 175px !important;
			width: 100% !important;
			margin-right: 1.25rem;
			margin-left: .5rem;
		}
		
		&:not(:first-child) {
			margin-left: 1rem;
			
			@media @mobile-portrait-down {
				margin-left: .25rem;
			}
		}
	}
	
	~ .glider-prev {
		left: 1.5rem;

		&.--sidebar {
			left: @small-arrow-width/2;
		}
		
		i {
			margin-right: 2px;
		}
	}

	~ .glider-next {
		right: 1.5rem;
		left: auto;

		&.--sidebar {
			right: @small-arrow-width/2;
		}
		
		i {
			margin-left: 2px;
		}
	}
	
	~ .glider-prev,
    ~ .glider-next {
		.flexbox(@display: inline-flex);
		
		opacity: 1;
		visibility: visible;
		transition: all .3s ease-in-out;
		width: 3rem;
		height: 3rem;
		font-size: 1rem;
		background-color: rgba(2, 20, 49, .25);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		z-index: 1;
		cursor:	pointer;
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		
		> i {
			color: @white;
		}
			
		&.disabled {
			opacity: 0;
		}
		
		&:hover,
		&:focus {
			background-color: rgba(2, 20, 49, .35);
		}
		
		@media @tablet-portrait-down {
			opacity: 0 !important;
			visibility:	hidden !important;
		}

		&.--small {
			width: @small-arrow-width;
			height: @small-arrow-width;
		}

	}
	
	~ .glider-dots {
		position: absolute;
		top: -1rem;
		right: 1.5rem;
		
		@media @tablet-portrait-down {
			right: 1rem;
		}
		
		> .glider-dot {
			background: #E5E7EA;
			width: 6px;
			height: 6px;
			margin: 2px;
			
			&:hover,
			&:focus,
			&:active,
			&.active {
				background: #BFC4CB;
			}
		}
	}
}

/*
	Glider.js default css
*/

.glider,.glider-contain{margin:0 auto;position:relative}.glider,.glider-track{transform:translateZ(0)}.glider-dot,.glider-next,.glider-prev{border:0;padding:0;user-select:none;outline:0}.glider-contain{width:100%}.glider{overflow-y:hidden;-webkit-overflow-scrolling:touch;-ms-overflow-style:none}.glider-track{width:100%;margin:0;padding:0;display:flex;z-index:1}.glider.draggable{user-select:none;cursor:-webkit-grab;cursor:grab}.glider.draggable .glider-slide img{user-select:none;pointer-events:none}.glider.drag{cursor:-webkit-grabbing;cursor:grabbing}.glider-slide{user-select:none;justify-content:center;align-content:center;width:100%;min-width:150px}.glider-slide img{max-width:100%}.glider::-webkit-scrollbar{opacity:0;height:0}.glider-next,.glider-prev{position:absolute;background:0 0;z-index:2;font-size:40px;text-decoration:none;left:-23px;top:30%;cursor:pointer;color:#666;opacity:1;line-height:1;transition:opacity .5s cubic-bezier(.17,.67,.83,.67),color .5s cubic-bezier(.17,.67,.83,.67)}.glider-next:focus,.glider-next:hover,.glider-prev:focus,.glider-prev:hover{color:#ccc}.glider-next{right:-23px;left:auto}.glider-next.disabled,.glider-prev.disabled{opacity:.25;color:#666;cursor:default}.glider-hide{opacity:0}.glider-dots{user-select:none;display:flex;flex-wrap:wrap;justify-content:center;margin:0 auto;padding:0}.glider-dot{display:block;cursor:pointer;color:#ccc;border-radius:999px;background:#ccc;width:12px;height:12px;margin:7px}.glider-dot:focus,.glider-dot:hover{background:#ddd}.glider-dot.active{background:#a89cc8}@media(max-width:36em){.glider::-webkit-scrollbar{opacity:1;-webkit-appearance:none;width:7px;height:3px}.glider::-webkit-scrollbar-thumb{opacity:1;border-radius:99px;background-color:rgba(156,156,156,.25);-webkit-box-shadow:0 0 1px rgba(255,255,255,.25);box-shadow:0 0 1px rgba(255,255,255,.25)}}
