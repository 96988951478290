.website-root {
	min-height: ~'calc(100vh - (@{header-height} + 4rem))';
	
	&__section {
		.transition();
		.offset-padding(@verticalPadding: 2rem; @horizontalPadding: 9rem; @mediaQuery: @monitor-up);
		.offset-padding(@verticalPadding: 2rem; @horizontalPadding: 1.875rem; @mediaQuery: @tablet-landscape-down);
		.offset-padding(@verticalPadding: 2rem; @horizontalPadding: 1rem; @mediaQuery: @tablet-portrait-down);
		.offset-padding(@verticalPadding: 1.25rem; @horizontalPadding: 1.25rem; @mediaQuery: @mobile-portrait-down);

		width: 100%;
		height: 100%;
		padding: 2rem;
		box-sizing: border-box;
		display: grid;
		min-height: ~'calc(100vh - (@{header-height} + 4rem))';
		
		/*
			iOS-specific
		*/
		@supports (-webkit-touch-callout: none) {
			min-height: -webkit-fill-available;
		}
		
		&.--adaptive {
			min-height: initial;
		}
		
		// Push content over when sidebar opened or expanded
		.sidebar-opened & {
			width: ~'calc(100% - @{side-panel-width})';

			@media @split-screen-down {
				width: ~'calc(100% - @{sidebar-width-mobile})';
			}

			// Allow sidebar to go above content
			@media @mobile-landscape-down {
				width: 100%;
			}
		}

		.sidebar-expanded & {
			width: ~'calc(100% - @{sidebar-width-expanded})';

			// Allow expanded sidebar to go above content
			@media @split-screen-down {
				width: 100%;
			}
		}
	}

	&__content {
		.flexbox(@flexFlow: row nowrap; @justifyContent: space-between);
		.one-column-layout(@tablet-portrait-down); // Break to one column layout

		background: url('https://assets.prizeout.com/secure-widget/secure-widget-welcome-background.svg') no-repeat center center;
		background-size: cover;
		border-radius: @tertiary-border-radius;
		width: 100%;

		.sidebar-opened & {
			.one-column-layout(@laptop-down); // Break to one column layout
		}
		
		&.--solid {
			background: #BFE9FF;
			
			.website-root__copy {
				* {
					color: @default-text-color;
				}
			}
			
			.website-root__graphic {
				margin: 0 2.25rem 1rem 0;
			}
		}
		
		.bttn.--inline {
			@media @mobile-portrait-down {
				width: 100%;
			}
		}
	}

	&__copy {
		flex: 1;

		* {
			color: @white;
		}
	}

	&__graphic {
		max-width: 25rem;
		margin: 2.25rem 0 1rem;
		flex: 1;

		@media @mobile-portrait-down {
			margin: 2rem 0 0;
		}

		img {
			width: 100%;
			display: inline-block;
		}
	}


	/*
		Local mixins
	*/

	.one-column-layout(@mediaQuery) {
		> div {
			flex: 1;

			&:first-child {
				padding: 3.125rem;
			}

			&:nth-child(2) {
				.flexbox(@justifyContent: flex-end);
			}
		}
		
		&.--solid {
			> div {
				&:first-child {
					padding: 3.125rem;
				}
				
				&:nth-child(2) {
					align-self: flex-start;
				}
			}
		}

		@media @mediaQuery {
			flex-flow: column;
			align-items: stretch;
			padding: 3rem 3rem 0;
			
			&.--solid {
				padding: 0 3rem 3rem;
				
				> div {
					&:first-child {
						order: 2;
						padding: 0;
					}
					
					&:nth-child(2) {
						order: 1;
						align-self: center;
						align-items: flex-start;
						
						.website-root__graphic {
							margin: 0 0 1rem;
						}
					}
				}
			}

			> div {
				&:first-child,
				&:nth-child(2) {
					padding: 0;
					justify-content: center;
				}
			}

			.website-root__copy {
				width: 100%;
			}
		}

		@media @mobile-portrait-down {
			padding: 1rem 2rem;
		}
	}
}
