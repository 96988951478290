.grid {
	@card-max-width: 18.75rem;
	
	display: grid;
	grid-gap: .75rem;
	
	&.--giftcards {
		grid-template-columns: ~'repeat(auto-fill, minmax(@{card-max-width}, 1fr))';
		
		@media @mobile-portrait-down {
			grid-template-columns: 1fr;
		}
	}
	
	/* One column on the first row, 2 columns following */
	&.--columnsOneTwo {
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
		
		@media @mobile-portrait-down {
			grid-template-columns: 1fr;
			justify-items: stretch;
		}
		
		.grid__item {
			&:first-child {
				grid-column-start: 1;
				grid-column-end: 3;
				max-width: @card-max-width;
				width: 100%;
				
				@media @mobile-portrait-down {
					grid-column-start: auto;
					grid-column-end: auto;
					max-width: 100%;
				}
			}
		}
	}
	
	/* One column on the first row, 2 columns following */
	&.--columnsTwo {
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
		
		@media @mobile-portrait-down {
			grid-template-columns: 1fr;
			justify-items: stretch;
		}
	}
}
