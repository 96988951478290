.flexbox(@display: flex; @flexFlow: row wrap; @alignItems: center; @justifyContent: center;) {
	display: @display;
	flex-flow: @flexFlow;
	align-items: @alignItems;
	justify-content: @justifyContent;
}

.transition(@delay: 0s; @duration: .3s; @prop: all; @timing: ease;) {
	transition-delay: @delay;
	transition-duration: @duration;
	transition-property: @prop;
	transition-timing-function: @timing;
}

.modal-show-header(@header-height) {
	top: @header-height;
	height: ~'calc(100% - @{header-height})';
}

.user-select(@event: none;) {
	user-select: @event;
}

.offset-padding(@verticalPadding: 1rem; @horizontalPadding; @mediaQuery) {
	@media @mediaQuery {
		padding: @verticalPadding @horizontalPadding;
	}
}

// Make the horizontal scroll modules go from edge to edge
.horizontal-scroll-edge(@leftValue; @rightValue;) {
	.glider-contain {
		margin-left: -@leftValue;
		width: ~'calc(100% + (@{leftValue} + @{rightValue}))';
	}
	
	.glider {
		padding-left: @leftValue;
	}
	
	.glider-slide:last-child,
	.glider-slide:last-child > .ui-toggles__item,
	.glider-slide--wide > .ui-toggles__item {
		margin-right: @rightValue;
	}
}
