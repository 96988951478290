.nobreak {
	overflow: 		hidden;
	text-overflow: 	ellipsis;
	white-space: 	nowrap;
}

.no-text-underline {
	text-decoration: none !important;
}

.link {
	font-size:		10px;
	font-weight:	500;
	color:			rgba(66, 165, 245, 0.7);
	transition:		color ease-out 0.3s;
	&:hover {
		text-decoration:	underline;
	}
}

.loading-page {
	.flexbox();

	background-color: @white;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 90000;

	img {
		width: 100%;
		max-width: 6.25rem;
	}
}

.loading-indicator {
	padding:	20px auto;
	font-size:	45px;
	color:		#000000;
	text-align:	center;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-italicize {
	font-style:	italic;
}

.help-link {
	text-align:	right;
	& > span {
		color:			rgba(66, 165, 245, 1);
		font-size:		11px;
		font-weight:	600;
	}
	padding:	3px 10px;
}

.font-weight-bolder {
	font-weight:	800;
}

/*
	Alert Boxes
*/
.ui-alert {
	padding:		20px;
	font-size:		14px;
	font-weight:	500;
	text-align:		center;
	&.warning {
		background-color:	rgba(255, 193, 7, 0.8);
		color:				#333;
	}
}

/* Full screen overlay */
.ui-overlay {
	.modal-show-header(@header-height);

	position: 			fixed;
	top: 				unset;
	bottom: 			0;
	left: 				0;
	width: 				100%;
	z-index: 			51000;
	background-color: 	rgba(229, 231, 234, 0.7); // Default - if backdrop-filter isn't supported
	
	// If backdrop-filter is supported
	@supports (backdrop-filter: blur(6px)) {
		background-color: rgba(229, 231, 234, 0.2);
		-webkit-backdrop-filter: blur(6px);
		backdrop-filter: blur(6px);
	}

	&.--fullScreen {
		top: 0;
		height: 100%;
	}

	&.--show-sidebar {
		z-index: 50100;
	}

	&.--with-message {
		display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
            "message";

		// go below header on mobile
		@media @split-screen-down {
			z-index: 50990;
		}

        & > .ui-overlay__message {
            grid-area: message;
            justify-self: center;
            align-self: center;
			margin: 2rem;

            & > div {
				.flexbox(@flexFlow: column);

				background-color: @white;
				border-radius: @tertiary-border-radius;
				padding: 2rem;
            }
        }

		&.--show-sidebar {
			width: ~'calc(100% - @{side-panel-width})';
		}
	}

	&.--sidebar {
		z-index: 51020; /* Must go above sidebar but below bottom-panel */
	}

	&.--for-dialog {
		&.ng-enter {
			animation: fadeIn 0.4s ease-out;
		}

		&.ng-leave {
			animation: fadeOut 0.3s linear;
		}
	}
	&.--for-sidebar {
		z-index: 51005;	/* Under the menu sidebar, but over the checkout sidebar */
		&.ng-enter {
			animation: fadeIn 0.4s linear;
		}

		&.ng-leave {
			animation: fadeOut 0.3s linear;

		}
	}
}

.floating-dialog {
	/* Dialog bg */
	& > .bg {
		position:	absolute;
		top:		0;
		left:		0;
		width:		100%;
		height:		100%;
		cursor:		default;
		background-color: 	rgba(229, 231, 234, 0.7); // Default - if backdrop-filter isn't supported
		z-index:	51000;	/* navbar is 50000 */
	
		// If backdrop-filter is supported
		@supports (backdrop-filter: blur(6px)) {
			background-color: 	rgba(229, 231, 234, 0.2);
			-webkit-backdrop-filter: blur(6px);
			backdrop-filter: blur(6px);
		}
		
		&.--solid {
			background-color: @white;
		}
	}
	
	& > .dialog-box {
		z-index:			51000;	/* navbar is 50000 */
		background-color:	#ffffff;
		border-radius:		10px;
		position:			absolute;
		top:				50px;
		left:				50px;
	}
}

.sidebar-dialog {
	position:	absolute;
	bottom:		0;
	right:		0;
	box-shadow:	0 -10px 20px rgba(0,0,0,0.1);
	width:		@sidebar-width-expanded;
	padding:	20px;
	z-index:	52000;
	background-color:	#ffffff;
	box-sizing:	border-box;
	
	@media @tablet-portrait-down {
		width: 100%;
	}
	
	/* ng-if show animation */
	&.ng-enter {
		animation: slideLeft 0.4s ease-out;
	}
	
	/* ng-if hide animation */
	&.ng-leave {
		animation: slideRight 0.3s linear;
	}
}

/*
	Copy-pasted from Quant-Studio.com with authorization
*/
.app-dialog {
	position:	fixed;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	display:	table /*!important*/;
	z-index:	51000;	/* navbar is 50000 */
	transition:	opacity ease-out 0.3s, transform ease-out 0.3s;
	&.gdpr {
		@media @split-screen-down {
			bottom: 0;
		}

		.ctas {
			& > div {
				width:	180px !important;
				&.disabled {
					opacity:	0.5;
				}
			}
		}
		.exit {
			background:		#acacac;
			&:hover {
				background:	#0000009f;
			}
		}
	}
	&.ng-enter, &.ng-leave.ng-leave-active {
		opacity:	0;
	}
	&.ng-leave, &.ng-enter.ng-enter-active {
		opacity:	1;
	}
	&.front {
		z-index:	52000;
	}
	&.always-on-top {
		z-index:	90000;
	}

	/* Dialog bg */
	& > .bg {
		position:	absolute;
		top:		0;
		left:		0;
		width:		100%;
		height:		100%;
		background-color: 	rgba(229, 231, 234, 0.7); // Default - if backdrop-filter isn't supported
	
		// If backdrop-filter is supported
		@supports (backdrop-filter: blur(6px)) {
			background-color: 	rgba(229, 231, 234, 0.2);
			-webkit-backdrop-filter: blur(6px);
			backdrop-filter: blur(6px);
		}
		
		&.--solid {
			background-color: @white;
		}
	}
	.overlay {
		position:		relative;
		vertical-align:	middle;
		text-align:		center;
		display:		table-cell;

		& > div {
			max-width:		500px;
			margin:			0 auto;
			text-align:		left;
			border-radius:	5px;
			padding: 		2rem;
		}
		&.form {
			& > div {
				max-width:		500px;
			}
		}
		&.medium {
			& > div {
				max-width:		500px;
			}
		}
		&.large {
			& > div {
				max-width:		800px;
			}
		}
	}
	&.small {
		& > .overlay {
			& > div {
				max-width:		400px;
			}
		}
	}
	&.medium {
		& > .overlay {
			& > div {
				max-width:		600px;
			}
		}
	}
	&.large {
		.overlay {
			& > div {
				max-width:		800px;
			}
		}
	}
	&.x-large {
		& > .overlay {
			& > div {
				max-width:		90%;
			}
		}
	}
}



@color_dialog_header:			#1F2229;
@color_dialog_menu:				#2D2F3B;
@color_dialog_title:			#ffffff;
@color_dialog_body:				#ffffff;
@color_dialog_text:				#333333;
@color_dialog_sep:				#20222E;
@color_dialog_cta:				#508ED7;
@color_dialog_cta2:				#5BB85D;
@color_cta:						#1CC5BC;

.app-dialog-ui {
	background-image:	linear-gradient(#FEFEFF, #F9F8FF);
	border-radius:		5px;
	box-shadow:			0 3px 6px rgba(0,0,0,0.4);
	padding-bottom:		10px;

	.header {
		color:				#404040;
		font-size:			18px;
		font-weight:		400;
		padding:			30px 10px 10px;
		text-align:			center;
	}
}


.app-dialog-box-v2 {
	background-color:	@color_dialog_body;
	border-radius:		10px;
	padding:			40px;
	.dialog-close {
		text-align:		right;
		.fa-times, .fa-times-circle {
			margin-top:		5px;
			margin-right:	5px;
			color:			lightgray;
			font-size: 		30px;
			font-weight: 	300;
			transition:		0.25s ease-in;
			&:hover {
				color:		#000000;
			}
		}
	}
}


.app-dialog-box {
	background-color:	@color_dialog_body;
	border-radius:		5px;
	.body {
		background-color:	@color_dialog_body;
		color:				@color_dialog_text;
		font-size:			12px;
		font-weight:		500;
		border-bottom:		1px solid @color_dialog_sep;
		border-radius:		5px;
		&.gdpr {
			color:			#6d7798;
			padding:		1rem;
			text-align:		center;
			.logo {
				max-height:		48px;
			}
			.text {
				margin:			8px 0;
				color:			#6d7798;
				font-size:		16px;
				font-weight:	600;
				text-align:		left;
			}
			.info-block {
				font-size:		14px;
				font-weight:	400;
				text-align:		left;
				a {
					color:				#0000009f;
					font-weight:		600;
					text-decoration:	underline;
					&:hover {
						text-decoration:	none;
					}
				}
			}
			.checklist-container {
				display:			flex;
				flex-direction:		column;
				margin:				0 4rem 2rem 4rem;
				@media screen and (max-width: 480px) {
					margin:			0;
				}
				.checklist-item > div {
					display: 			flex;
					align-items: 		center;
					justify-content:	flex-start;
					margin:				8px 0;
				}
			}
			label {
				position: 				relative;
				display: 				block;
				margin-right:			1rem;
				margin-bottom: 			12px;
				padding-left: 			24px;
				font-size: 				20px;
				user-select: 			none;
				cursor: 				pointer;
				input {
					position: 			absolute;
					height: 			0;
					width: 				0;
					cursor: 			pointer;
					opacity: 			0;
					&:checked {
						~ .checkmark {
							background-color: #ffffff;
							&:after {
								display: 		block;
							}
						}

					}
				}
				.checkmark {
					position: 			absolute;
					top: 				-1px;
					left: 				0;
					height: 			12px;
					width: 				12px;
					border: 			1px solid #dcdddf;
					border-radius: 		6px;
					&:after {
						content: 			"";
						position: 			absolute;
						display: 			none;
						left: 				5px;
						top: 				-11px;
						width: 				6px;
						height: 			18px;
						border: 			solid #1eb1fa;
						border-width: 		0 3px 3px 0;
						transform: 			rotate(30deg);
					}
				}
			}
			.ctas {
				.button {
					font-weight:	500;
					letter-spacing:	.5px;
					box-shadow:		none;
					&.disabled {
						color:			#ffffff;
						background:		#1eb1fa !important;
					}
				}
			}
		}
		.text {
			font-size:		14px;
			font-weight: 	400;
		}
		&.no-padding {
			padding:		0;
		}
		&.scroll {
			max-height:	600px;
			overflow-y:	auto;
		}
	}
	.logo {
		max-height:		100px;
	}
	.message {
		text-align:			center;
		padding:			3px;
		font-size:			11px;
		background-color:	@color_dialog_cta;
		color:				@color_dialog_text;
		a {
			font-size:			10px;
			text-decoration:	underline;
		}
	}
	.header {
		background-color:	@color_dialog_header;
		color:				@color_dialog_title;
		font-size:			14px;
		font-weight:		700;
		padding:			10px;
		border-bottom:		1px solid @color_dialog_sep;
		border-radius:		5px 5px 0 0;
		text-align:			center;
		& > .author {
			color:			@color_cta;
			font-size:		11px;
			font-weight:	500;
		}
		&.with-tabs {
			position:	relative;
			.dialog-header-tabs {
				position:	absolute;
				top:		10px;
				right:		10px;
			}
		}
	}
	.menu {
		background-color:	@color_dialog_menu;
		color:				@color_dialog_text;
		font-size:			12px;
		font-weight:		500;
		padding:			5px;
		border-bottom:		1px solid @color_dialog_sep;
	}
	.tabs {
		background-color:	@color_dialog_menu;
		color:				@color_dialog_text;
		font-size:			12px;
		font-weight:		500;
		padding:			5px;
		border-bottom:		1px solid @color_dialog_sep;
		text-align:			center;
		& > div {
			display:		inline-block;
			padding:		5px 15px;
			margin:			4px 0;
			color:			darken(@color_dialog_text, 30%);
			border-bottom:	2px solid transparent;
			&.selected {
				border-bottom:	2px solid @color_dialog_cta;
				color:			@color_dialog_text;
			}
			&:hover {
				color:			@color_dialog_text;
			}
			.tag {
				font-size:		8px;
			}
		}
	}
	.dialog-close {
		text-align:		right;
		.fa-times, .fa-times-circle {
			margin-top:		5px;
			margin-right:	5px;
			color:			lightgray;
			font-size: 		30px;
			font-weight: 	300;
			transition:		0.25s ease-in;
			&:hover {
				color:		#000000;
			}
		}
	}
	.body {
		background-color:	@color_dialog_body;
		color:				@color_dialog_text;
		font-size:			12px;
		font-weight:		500;
		border-bottom:		1px solid @color_dialog_sep;
		border-radius:		0 0 5px 5px;
		.title {
			font-size:		15px;
			font-weight:	700;
			margin-bottom:	5px;
		}
		.info {
			font-size:		12px;
			font-weight:	700;
			text-align:		center;
			margin-bottom:	5px;
		}
		.text {
			font-size:		12px;
			font-weight:	400;
		}
		.error {
			font-size:		12px;
			font-weight:	400;
			text-align:		center;
			color:			#B71C1C;
		}
		.fake-link {
			text-decoration:	underline;
			font-weight:		700;
		}

		&.no-padding {
			padding:		0;
		}
		&.scroll {
			max-height:	600px;
			overflow-y:	auto;
		}
	}

	.confirmation-message {
		padding:		20px;
		text-align:		center;
		font-size:		14px;
		font-weight:	500;
		color:			rgba(0,0,0,0.7);
	}


	&.static-height {
		.body {
			height:			400px;
			overflow-y:		auto;
		}

	}


	.text-block {
		color:				@color_dialog_text;
		font-size:			12px;
		font-weight:		500;
		padding:			10px;
		text-align:			left;
		&.center {
			text-align:		center;
		}
		&.right {
			text-align:		right;
		}
		a {
			color:				@color_dialog_cta;
			text-decoration:	underline;
		}
	}
	.footer {
		background-color:	@color_dialog_cta;
		border-top:			1px solid rgba(255,255,255,0.05);
		color:				@color_dialog_text;
		font-size:			12px;
		font-weight:		600;
		padding:			10px;
		text-align:			center;
		border-radius:		5px;
		margin:				5px 0;
		&:last-child {
			margin:			5px 0 0 0;
		}
		&:hover {
			background-color:	darken(@color_dialog_cta, 5%);
		}
		&.green {
			background-color:	@color_dialog_cta2;
			&:hover {
				background-color:	darken(@color_dialog_cta2, 5%);
			}
		}
		&.disabled, &.disabled:hover {
			background-color:	rgba(0,0,0,0.2);
			color:				rgba(229, 231, 234, 0.2);
			cursor:				default;
		}
	}
	.text-link {
		color:				@color_dialog_text;
		font-size:			11px;
		font-weight:		300;
		padding:			10px;
		text-align:			center;
		margin:				5px 0;
	}
}



.ui-tags {
	border:				1px solid rgba(0,0,0,0.1);
	border-radius:		4px;
	background-color:	#ffffff;
	padding:			4px;
	cursor:				text;

	& > input {
		display:	inline-block;
		height:		20px;
		border:		0;
		background-color:	transparent;
		width:		20px;
		&:focus {
			outline: none;
		}
	}
	& > div {
		position:	relative;
		.ui-list {
			position:	absolute;
			top:		0;
			left:		0;
			width:		100%;
			z-index:	500;
		}
	}
}


.tag {
	background-color:	#42A5F5;
	color:				#ffffff;
	font-size:			10px;
	padding:			3px 5px;
	border-radius:		3px;
	vertical-align:		middle;
	white-space:		nowrap;
	display:			inline-block;
	margin:				1px;

	&.green {
		background-color:	rgba(102, 187, 106, 1);
	}
	&.red {
		background-color:	rgba(211, 47, 47, 1);
	}
	&.orange {
		background-color:	rgba(255, 152, 0, 1);
	}
	&.blue {
		background-color:	rgba(3, 169, 244, 1);
	}
	&.gray {
		background-color:	rgba(120, 144, 156, 1);
	}
	&.disabled {
		background-color:	rgba(0, 0, 0, 0.3);
		color:				rgba(0, 0, 0, 0.7);
	}

	& > span {
		display:			inline-block;
		vertical-align:		middle;
	}
	& > .label {
		white-space:		nowrap;
		text-overflow:		ellipsis;
		overflow:			hidden;
		max-width:			120px;
		vertical-align:		middle;
	}
	&.small {
		padding:			1px 3px;
		line-height:		14px;
		font-size:			8px;
	}
}


.ui-list {
	background-color:		#ffffff;
	border:					1px solid #EBEBEB;
	max-height:				200px;
    overflow:				auto;
    box-shadow:				0 2px 10px rgba(0,0,0,0.2);
    border-radius:			3px;
    &.absolute {
    	position:			absolute;
    	width:				100%;
    }
	& > div {
		padding:			5px;
		font-size:			14px;
		line-height:		16px;
		font-weight:		500;
		text-align:			left;
		color:				#333333;
		vertical-align:		top;

		& > .icon {
			float:			left;
			margin-right:	5px;
		}
		& > .with-help {
			float:			left;
			& > .main {
				font-size:			14px;
				font-weight:		700;
			}
			& > .sub {
				font-size:			11px;
				font-weight:		500;
			}
		}


		&.active {
			background-color:	rgba(204, 232, 255, 1);
		}
		&:hover {
			background-color:	rgba(66, 165, 245, 0.1);
		}
	}
}


.password-input {
	& > .form__inputWrapper {
		position:				relative;
		.password-strength {
			width:				~'calc(100% - 1rem)';
			height:				2px;
			border-radius:		3px;
			background-color:	rgba(0,0,0, 0.1);
			position:			absolute;
			bottom:				0;
			left: 				.5rem;
			
			& > div {
				height:				2px;
				background-color:	transparent;
				border-radius:		3px;
				&.score_0 {
					background-color:	#D95F57;
					width:				1px;
				}
				&.score_1 {
					background-color:	#F2675B;
					width:				25%;
				}
				&.score_2 {
					background-color:	#F0C700;
					width:				50%;
				}
				&.score_3 {
					background-color:	#26B668;
					width:				75%;
				}
				&.score_4 {
					background-color:	#2AC86e;
					width:				100%;
				}
			}
		}
	}
	.warning {
		color:			@red;
	}
}

.ui-checklist {
	background-color:		#ffffff;
    overflow:				auto;
    border-radius:			3px;
    display:				table;
    width:					100%;
	& > div {
		display:			table-row;
		& > div {
			display:			table-cell;
			padding:			5px;
			font-size:			14px;
			line-height:		16px;
			font-weight:		500;
			text-align:			left;
			color:				#333333;
			vertical-align:		middle;

			&.icon {
				width:		20px;
				text-align:	left;
			}
			&.label {
				& > .main {
					font-size:			12px;
					font-weight:		700;
				}
				& > .sub {
					font-size:			11px;
					font-weight:		500;
				}
			}
		}
		&.active {
			background-color:	rgba(204, 232, 255, 1);
		}
		&:hover {
			background-color:	rgba(66, 165, 245, 0.1);
		}
	}
}


.edit-in-place {
	position:	relative;
	input.input-text {
		width:			100%;
		padding:		2px 4px;
	}
	select {
		width:			100%;
	}
	.ui-tags {

	}
	&.with-message {

	}
	.cell-message {
		position:		absolute;
		bottom:			0;
		right:			0;
		font-size:		7px;
		font-weight:	600;
		padding:		1px 3px;
		border-radius:	3px 0 0 0;
		&.accepted {
			background-color:	rgba(102, 187, 106, 0.6);
			color:				#ffffff;
		}
		&.rejected {
			background-color:	#e57373;
			color:				#ffffff;
		}
		&.assigned {
			background-color:	rgba(0, 0, 0, 0.4);
			color:				#ffffff;
		}
		&.modified {
			background-color:	rgba(255, 193, 7, 0.6);
			color:				rgba(0,0,0,0.6);
		}
		&.new {
			background-color:	rgba(66, 165, 245, 0.6);
			color:				#ffffff;
		}
	}
}


/*
	Role editor UI
*/
.role-editor {
	.type-ahead {
		input {
			width:		100%;
			padding:	2px 4px;
		}
		& > div {
			& > div {
				position:	relative;
				& > div {
					position:	absolute;
					width:		100%;
				}
			}
		}
	}
	& > table {
		width:	100%;
		& > tbody {
			& > tr {
				border-bottom:	1px dotted rgba(0,0,0,0.2);
				& > td {
					padding:		5px;
					font-size:		14px;
					font-weight:	500;
					color:			#333333;
					&.role {
						width:	100px;
						select {
							width:	100%;
						}
					}
					&.del {
						width:		30px;
						text-align:	center;
					}
				}
				&:last-child {
					border-bottom:	0;
				}
			}
		}
	}
}


.ui-profile {
	img {
		float:			left;
		width:			34px;
		margin:			0 10px 0 0;
		border-radius:	50%;
	}
	.name {
		font-size:		12px;
		font-weight:	700;
		color:			#333333;
	}
	.email {
		font-size:		10px;
		font-weight:	600;
		color:			rgba(0, 156, 239,0.8);
	}
}


.ui-toggles {
	@itemWidth: 4.375rem;

	&.--grid {
		> div {
			display: grid;
			grid-gap: .5rem;
			grid-template-columns: ~'repeat(4, minmax(@{itemWidth}, 1fr))';
		}
	}

	&__spread {
		grid-column: span 2;
	}

	&__item {
		padding: 8px 4px;
		border: none;
		border-radius: @default-border-radius;
		text-align: center;
		background-color: #E5E7EA;
		color: @default-text-color;
		width: 100%;

		&.selected,
		&.selected:hover,
		&.selected:focus,
		&:focus {
			background-color: @blue-highlight;
			color: @white;
		}

		&:hover {
			background-color: #CCD0D5;
		}
	}
}

.ui-form {
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid @divider-border-color;

	@media @tablet-landscape-down {
		padding: 1rem 1.5rem 1.5rem;
	}

	@media @split-screen-down {
		padding: 1rem 1.5rem;
	}

	& > div.form {
		width:			100%;
		padding:		10px 0;
		& > div {
			display:		flex;
			align-items: 	center;
			& > div {
				display:		table-cell;
				padding:		10px;
				input {
					width:			82%;
					padding:		7px;
					border:			1px solid silver;
					border-radius:	3px;
					font-size:		13px;
					text-overflow:	ellipsis;
				}
				textarea {
					width:		100%;
					height:		96px;
				}
				&:first-child {
					font-size:		12px;
					font-weight:	700;
					color:			#333333;
					width:			35%;
					text-align:		right;
				}
				&:last-child {
					width:			70%;
					font-size:		13px;
					font-weight:	500;
					color:			#333333;
					text-align:		left;
					.about {
						text-align:	center;
						font-size:	11px;
						color:		rgba(93, 93, 93, 1);
						font-size:	500;
						padding:	6px 0;
					}
				}
				&.special-offer {
					color:		rgba(87, 186, 110, 1);
					font-size:	12px;
				}
			}
		}
		.title {
			margin:			0 0 10px 0;
			color:			#566481;
			text-align:		center;
		}
		&.lines {
			& > div {
				& > div {
					border-bottom:	1px dotted rgba(0,0,0,0.1);
				}
			}
		}
	}
	& > .sep {
		background-color:	rgba(225, 232, 235, 1);
		height:				1px;
		margin:				10px auto;
	}
	& > .ctas {
		text-align:					center;
		border-bottom-left-radius: 	3px;
		border-bottom-right-radius: 3px;

		.quantity-input {
			display:		table;
			width:			75%;
			margin:			10px auto;
			border-bottom:	1px solid #1EB1FA;
			& > div {
				display:	table-row;
				& > div {
					display:	table-cell;
					padding:	10px 0;
					&:first-child {
						text-align:		left;
						font-size:		16px;
						font-weight:	500;
						color:			#566481;

					}
					&:last-child {
						text-align:	right;
						input {
							width:			40px;
							padding:		7px;
							border:			1px solid silver;
							border-radius:	3px;
							font-size:		13px;
							text-overflow:	ellipsis;
						}
					}
				}
			}
		}
		.calculation {
			margin:		0 0 1rem 0;

			& > div {
				display: 			flex;
				justify-content: 	space-between;
				align-items:		center;
				margin: 			.5rem auto 0 auto;
				&:first-child {
					margin: 		0 auto;
				}
				& > div:first-child {
					flex:			1;
					text-align: 	left;
				}
				& > div:last-child {
					text-align:		right;
				}
			}
			& > div:last-child {
				border:				0;
				padding-bottom:		0;
				& > div:first-child {
					font-weight:		700;
				}
				& > div:last-child {
					white-space:	nowrap;
				}
			}
			.sep {
				height:				.5px;
				background-color:	@blue-border-color;
				margin:				.75rem auto;
			}
		}
		& > div {
			&.blank {
				width:			55%;
				margin:			0 auto;
				padding:		7px 17px;
				border-radius:	3px;
				color:			rgba(112, 112, 112, 1);
				font-weight:	500;
				transition:		0.2s ease-in-out;
				&:hover {
					background: rgba(0, 0, 0, 0.1);
				}
			}
			&.regular {
				position:			relative;
				margin:				0 auto;
				padding:			11px 17px;
				background-color:	@blue-highlight;
				color:				#ffffff;
				font-weight:		700;
				font-size: 			1rem;
				border-radius:		10px;
				transition:			0.2s ease-in-out;
				font-family: 		@body-font-family;

				&:hover {
					background: 	#128ef2;
					cursor:			pointer;
				}
				&:focus {
					outline:		none;
					& > div.confirmation-button {
						opacity:			1;
					}
				}
				&.disabled {
					opacity:	0.5;
				}
				&.confirmation-button,
				&.confirmation-loading-button {
					background-color:	@secondary-dark-blue;

					&:hover {
						background-color: darken(@secondary-dark-blue, 10%);
					}
				}
				&.confirmation-loading-button {
					pointer-events:		none;
					cursor:				not-allowed;
				}
				& > div.confirmation-button {
					width: 				100%;
					position: 			absolute;
					opacity:			0;
					top:				0;
					left: 				0;
					padding: 			11px 0;
					border-radius:		10px;
					background:			@secondary-dark-blue;
					color:				#ffffff;
				}
				& > div.confirmation-loading-button {
					width: 				100%;
					position: 			absolute;
					opacity:			1;
					top:				0px;
					left: 				0;
					padding: 			11px 0;
					border-radius:		10px;
					background:			@secondary-dark-blue;
					color:				#ffffff;
					pointer-events:		none;
					cursor:				not-allowed;
					&:hover {
						background-color:	@default-text-color;
					}
				}
			}
		}
	}
	.not-enough {
		position:		absolute;
		top:			-7px;
		color: 			#e70028 !important;
		font-size: 		12px !important;
	}

	.ui-form__terms {
		text-align:		center;
		font-size:		0.6875rem;
		padding:		0 0 8px;
	}
}



.ui-tabs {
	background-color:	lighten(rgba(31, 34, 41, 1), 17%);
	text-align:			center;
	& > div {
		display:		inline-block;
		color:			rgba(255,255,255, 0.3);
		font-weight:	600;
		font-size:		12px;
		border-bottom:	3px solid transparent;
		padding:		10px 25px;
		transition:		color ease-out 0.3s, border-bottom ease-out 0.3s;
		&.selected {
			color:			rgba(255, 255, 255, 1);
			border-bottom:	3px solid rgba(66, 165, 245, 1);
		}
	}
}

.ui-table {
	background-color:	#ffffff;
	max-height:			200px;
	overflow-y:			auto;
	&.no-scroll {
		max-height:		none;
	}
	& > table {
		width:	100%;
		& > thead {
			& > tr {
				& > th {
					padding:		5px;
					font-size:		12px;
					font-weight:	600;
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					cursor:			default;
					padding:		5px;
					font-size:		11px;
					font-weight:	500;
					color:			rgba(0,0,0,0.7);
					text-align:		left;
					border-bottom:	1px dotted rgba(0,0,0,0.3);

					&.name {
						max-width:		150px;
					}
					&.date {
						max-width:		110px;
					}
					&.profile {
						width:			200px;
					}
					&.number {
						width:			100px;
					}
					&.action {
						text-align:		right;
						& > span {
							&.link {
								color:			rgba(66, 165, 245, 0.6);
								font-weight:	600;
								padding:		0 5px;
								transition:		color ease-out 0.3s;
								&:hover {
									color:			rgba(66, 165, 245, 1);
								}
							}
						}
					}
				}
				&:hover {
					& > td {
						background-color:	rgba(0,0,0, 0.1);
					}
				}
				&.selected {
					& > td {
						background-color:	rgba(66, 165, 245, 0.8);
						color:				#ffffff;
					}
				}
			}
		}
	}
	&.autosize {
		& > table {
			width:	auto;
			margin:	0 auto;
		}
	}
}


/*
	Auth
*/

.ui-auth {
	padding:			10px;
	text-align:			center;
	& > div {
		background-color:	rgba(255,255,255, 1);
		margin:				0 auto;
		display:			inline-block;
		border:				1px solid rgba(0,0,0,0.1);
		border-radius:		5px;
		& > div {
			padding:		5px;
			input {
				min-width:	200px;
				border:		0;
				border-bottom:	1px solid rgba(0,0,0,0.05);
				padding:	5px;
			}
		}
	}
}


.ui-dropdown {
	display:	inline-block;
	margin:		10px auto;
	& > .label {
		background-color:	rgba(30, 177, 250, 1);
		color:				#ffffff;
		font-size:			14px;
		font-weight:		500;
		box-shadow:			0 3px 6px rgba(214, 214, 214, 1);
		padding:			5px 15px;
		border-radius:		2px;
	}
	& > .dropdown {
		position:		relative;
		& > div {
			position:		absolute;
			left:			0;
			top:			0;
			min-width:		100%;
			z-index:		5000;
			background-color:	rgba(255,255,255, 1);
			box-shadow:		0 3px 6px rgba(0, 0, 0, 0.5);

			& > div {
				padding:		6px 12px;
				color:			#989898;
				font-size:		12px;
				font-weight:	500i;
				border-bottom:	1px solid rgba(0,0,0,0.2);
				text-align:		left;
				&:last-child {
					border-bottom:	0;
				}
				&:hover {
					background-color:	rgba(30, 177, 250, 0.1);
				}
				&.selected {
					background-color:	rgba(30, 177, 250, 0.1);
				}
			}
		}
	}
}


.layout-homepage {
	.homepage-content {
		> div.--with-padding {
			padding-top: 2rem;

			@media @tablet-portrait-down {
				padding-top: 1rem;
			}
		}
	}
	.--just-padding {
		padding-top: 2rem;

		@media @tablet-portrait-down {
			padding-top: 1rem;
		}
	}
}



.bottom-panel {
	position:	fixed;
	top:		unset;
	bottom: 	0;
	left:		0;
	width:		100%;
	height:		100%;
	z-index:	51000;	/* navbar is 50000 */

	&.--sidebar {
		z-index: 51021; /* must go above sidebar */
	}

	&.--sticky {
		height: unset;
		background-color: @white;
		border-radius: @tertiary-border-radius @tertiary-border-radius 0 0;
		box-shadow: 0 -4px 8px rgba(2, 20, 49, 0.1);

		> .panel {
			border-radius: inherit;
		}
	}

	.bg {
		position:			absolute;
		top:				0;
		left:				0;
		width:				100%;
		height:				100%;
		cursor:				default;
		z-index: 			1;
	}

	.overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-height: 85%;
		height:	auto;
		overflow-y: auto;
		z-index: 2;
		box-shadow: 0 -10px 24px rgba(2, 20, 49, .15);
		border-radius: @tertiary-border-radius @tertiary-border-radius 0 0;
		
		// For checkout "sticky bottom"
		&.--set-height {
			height:	85%;
		} 
	}

	.panel {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between);

		background:			@white;
		border-radius:		@tertiary-border-radius @tertiary-border-radius 0 0;
		height: 			100%;

		// Allow bottom panel to scroll and swipe
		touch-action: pan-y !important;
		-webkit-overflow-scrolling: touch;
		overflow-y: auto;

		@media @mobile-portrait-down {
			.flexbox(@flexFlow: column; @alignItems: stretch);
		}
		
		// Alternate view, blue bg
		&.--alternate {
			background-color: @blue-highlight;
			
			> .h-bar {
				background-color: rgba(255, 255, 255, .25);
				box-shadow: none;
			}
		}

		& > div {
			& > div {
				width: 		100%;
				margin: 	0 auto;
				box-sizing: border-box;
			}
		}
	}

	.h-bar {
		width: 10rem;
		height: 3px;
		margin: 1rem auto;
		border-radius: @default-border-radius;
		background-color: rgba(2, 20, 49, 0.1);
		box-shadow: none;
		position: sticky;
		top: 0;
		z-index: 1;

		// Stretch content below h-bar to fill container
		+ div {
			flex: 1;
			overflow: auto;
			
			> .section__inner:first-child {
				padding-top: 0;
			}
		}
	}

	/* ng-if show animation */
	&.ng-enter {
		animation: slideUp 0.4s ease-out;
	}

	/* ng-if hide animation */
	&.ng-leave {
		animation: slideDown 0.6s ease-in-out;
	}
}


.side-panel {
	position:	fixed;
	top:		0;
	right:		0;
	width:		@side-panel-width;
	z-index:	51000;	/* navbar is 50000 */
	background-color: @light-grey-background-color;
	border-radius: @tertiary-border-radius 0 0 @tertiary-border-radius;

	& > .bg {
		display:	none;
	}
	& > .overlay {
		border-radius:		@tertiary-border-radius 0 0 @tertiary-border-radius;
		background-color:	@white;
		position:			absolute;
		top:				0;
		right:				0;
		width:				100%;
		height: 			100%;
		overflow-y:			auto;
		box-shadow: 		-1px 1px 10px rgba(0, 0, 0, 0.1);
		border: 			none;
		z-index: 			5001;
		box-sizing: 		border-box;

		&.--alternate {
			background-color: @blue-highlight;
		}

		& > .h-bar {
			display:	none;
		}

		& > .panel {
			width: 				100%;
			height: 			100%;
			border-radius: 		inherit;
			position:			relative;
			& > div {
				height:			100%;
			}

			.panel__content {
				position: 			relative;
				top: 				50%;
				-webkit-transform: 	translateY(-50%);
  				-ms-transform: 		translateY(-50%);
  				transform: 			translateY(-50%);
				padding: 			0;
				height: 			100%;
				box-sizing: 		border-box;
				display:			flex;
				flex-flow:			column;
				align-items:		stretch;
				justify-content: 	center;
			}
		}
	}

	/* ng-if show animation */
	&.ng-enter {
		animation: slideLeft 0.4s ease-out;
		-webkit-animation: slideLeft 0.4s ease-out;
	}

	/* ng-if hide animation */
	&.ng-leave {
		animation: slideRight 0.3s linear;
		-webkit-animation: slideRight 0.3s linear;
	}
}
