.onboarding {
	@padding: 1.875rem;
	@padding-split-screen: 1.5rem;
	@padding-mobile: 1rem;
	
	.flexbox(@flexFlow: column; @justifyContent: space-between; @alignItems: stretch);
	
	height: 100%;
	max-width: @side-panel-width;
	margin: 0 auto;
	
	* {
		box-sizing: border-box;
	}
	
	// Alternate view, blue bg, overlay(desktop) panel(mobile)
	.overlay.--alternate &,
	.panel.--alternate & {
		.onboarding {
			&__dots {
				margin-bottom: 1.5rem;
				
				// Slick overrides
				.slick-dots {
					li:first-child {
						display: none;
					}
					
					li.slick-active button {
						color: @white;
					}
					
					li button {
						color: rgba(255,255,255,.5);
					}
				}
			}
			
			&__message {
				color: @white;
			}
		}
		
	}
	
	&__slider {
		height: 100%;
		padding-top: 1rem;
		
		@media @split-screen-down {
			padding-top: 0;
		}
		
		// Slick overrides
		.slick-track {
			.flexbox(@alignItems: flex-end);
			
			height: 100%;
		}
		
		.slick-list {
			height: 100%;
		}
		
		.slick-slide {
			outline: none;
			will-change: transform;
			
			img {
				width: 100%;
			}
		}
		
		@media @split-screen-down {
			.slick-prev {
				display: none !important;
			}
		}
	}
	
	&__slide {
		.flexbox(@flexFlow: column; @justifyContent: space-between);
		
		display: flex !important; // Slick override
		text-align: center;
	}
	
	&__message {
		padding: @padding @padding .5rem;
		
		@media @split-screen-down {
			padding: @padding-split-screen @padding-split-screen .5rem;
		}
		
		@media @mobile-portrait-down {
			padding: @padding-mobile @padding-mobile .5rem;
		}
	}
	
	&__title {
		margin-bottom: .5rem;
	}
	
	&__logo {
		.flexbox();
		
		margin-bottom: 1.5rem;
		
		img {
			max-width: 6.25rem;
			width: 100%;
			display: inline-block;
		}
	}
	
	&__dots {
		.slick-dots {
			.flexbox();
			
			list-style-type: none;
			margin: 0;
			padding: 0;
			line-height: 10px;
			text-align: center;
			
			li {
				margin: 0;
				
				button {
					padding: 0 2px;
					background:	none;
					font-size: 9px;
					color: @filtered-grey-background-color;
					border: 0;
					outline: none;
					&:focus {
						outline: none;
					}
					&:hover {
						cursor:	pointer;
					}
				}
				&.slick-active {
					button {
						color: @blue-highlight;
					}
				}
			}
		}
	}
	
	&__controls {
		padding: @padding;
		
		.bttn + .bttn {
			margin-top: .875rem;
		}
		
		@media @split-screen-down {
			padding: @padding-split-screen;
		}
		
		@media @mobile-portrait-down {
			padding: @padding-mobile;
		}
	}
	
	&__skip {
		.flexbox(@justifyContent: flex-end);
		
		margin: 1rem 0;
		
		.bttn.--ghost {
			color: #A7E0FF;
			width: min-content;
		}
	}
}





/* 
	Default Slick Slider CSS
*/

.slick-slider{position:relative;display:block;box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{position:relative;top:0;left:0;display:block}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-loading .slick-track{visibility:hidden}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-loading .slick-slide{visibility:hidden}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}
