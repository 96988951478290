.tabs {
	@navigation-offset: 2rem;
	@tab-bar-height: 5px;
	@tab-width-2: ~'calc(100% / 2)';
	@tab-width-3: ~'calc(100% / 3)';

	position: relative;
	background-color: @light-grey-background-color;

	&__list {
		.flexbox();

		position: absolute;
		top: -@navigation-offset;
		left: 0;
		width: 100%;
		text-align: center;
	}

	&__item {
		.transition(@prop: color;);

		flex: 1;
		color: @default-text-color;
		font-family: @body-font-family;
		font-weight: 600;
		font-size: .75rem;

		&.--current {
			color: @blue-highlight;
		}

		&:first-child {
			&.--current {
				~ .tabs__bar {
					left: 0;
				}
			}
		}

		&:nth-child(2) {
			&.--current {
				/* Determine how far the bar should move to reach the 2nd item, depending on if there are 2 or 3 tabs */
				~ .tabs__bar {
					.--2 & {
						left: @tab-width-2;
					}
					.--3 & {
						left: @tab-width-3;
					}
				}
			}
		}

		&:nth-child(3) {
			&.--current {
				~ .tabs__bar {
					left: ~'calc(@{tab-width-3} * 2)';
				}
			}
		}

		/* Determine width of bar by amount of tab items */
		~.tabs__bar {
			.--2 & {
				width: @tab-width-2;
			}
			.--3 & {
				width: @tab-width-3;
			}
		}
	}

	&__bar {
		.transition(@prop: left);

		position: absolute;
		bottom: -10px;
		left: 0;
		height: @tab-bar-height;
		background-color: @blue-highlight;

		@media @mobile-portrait-down {
			bottom: -9px;
		}
	}

	&__section {

		/* Fade in when switching tabs */
		&.ng-enter {
			animation: fadeIn 0.4s ease-out;
			-webkit-animation: fadeIn 0.4s ease-out;
		}
	}
}
