.iconText {
	@icon-width: 20px;
	@icon-width-medium: 24px;
	
	.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start;);

	margin-bottom: .75rem;

	.--maxLimit & {
		.flexbox(@flexFlow: row nowrap; @alignItems: flex-start);

		margin-bottom: 0;
	}

	&.--status {
		margin-bottom: .25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&.--vertical {
		.flexbox(@flexFlow: column;);
		
		margin-bottom: 2rem;
		
		.iconText__icon {
			font-size: 1.5rem;
			margin-bottom: .25rem;
			color: @green;
		}
		
		.iconText__text {
			color: @green;
			font-family: @body-font-family;
		}
	}

	&__iconWrapper {
		.flexbox();

		border-radius: 50px;
		width: @icon-width;
		height: @icon-width;
		margin-right: .5rem;

		.--status & {
			width: .75rem;
			height: .75rem;
			margin-right: .25rem;
		}

		&.--unlocked,
		&.--yellow {
			background-color: @yellow;
		}

		&.--locked,
		&.--grey {
			background-color: @locked-color;
		}

		&.--blue {
			background-color: @blue-highlight;
		}

		&.--green {
			background-color: @green;
		}

		&.--white {
			background-color: @white;
		}
		&.--red {
			background-color: @red;
		}
	}

	&__icon {
		color: @white;
		font-size: .75rem;

		&.--yellow {
			color: @yellow;
		}

		&.--grey {
			color: @locked-color;
		}

		&.--blue {
			color: @blue-highlight;
		}

		&.--green {
			color: @green;
		}

		&.--white {
			color: @white;
		}
		&.--red {
			color: @red;
		}
	}

	&__subtitle {
		&:not(:first-child) {
			margin-top: .5rem;
		}
	}
	
	&__steps {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: flex-start;);
		
		&:extend(.text-light);
	}
}
