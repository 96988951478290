.website-homepage {
	@default-padding: 2rem;
	@logo-width: 7.5rem;
	@sticky-image-width: 31.25rem;
	@sticky-image-offset: 3.375rem;
	@signup-margin-offset: ~'calc((@{sticky-image-offset} * 2) + @{default-padding})';
	@form-max-width: 29rem;

	background-color: @white;

	* {
		box-sizing: border-box;
	}

	&__section {
		height: 100%;
		min-height: 100vh;
		display: grid;

		&.--signup {
			position: relative;
			grid-template-columns: repeat(2, 1fr);

			.website-homepage__content {
				// Left side/form
				&:first-child {
					.website-homepage__inner {
						margin: @default-padding @signup-margin-offset @default-padding @default-padding; // Use margin right to account for sticky image positioning

						// Reset margin
						@media @split-screen-down {
							margin: 0;
						}
					}
				}

				// Right/blue side
				&.--alternate {
					.website-homepage__inner {
						margin: @default-padding @default-padding @default-padding @signup-margin-offset; // Use margin left to account for sticky image positioning

						// Reset margin
						@media @split-screen-down {
							margin: 0;
						}
					}
				}
			}
		}

		&.--login {
			grid-template-columns: 40% 60%;

			.website-homepage__content {
				.website-homepage__inner {
					padding: 2rem;
				}

				// Right/blue side
				&.--alternate {
					background: url('https://assets.prizeout.com/secure-widget/secure-widget-banner-repeat.svg') repeat top center;
					background-size: cover;

					@media @split-screen-down {
						display: none;
					}
				}
			}
		}

		&.--signup,
		&.--login {
			// One column layout
			@media @split-screen-down {
				grid-template-columns: 1fr;
			}
		}
	}

	&__content {
		.flexbox();

		width: 100%;
		height: 100%;
		margin: 0 auto;
		background-color: @white;

		&.--alternate {
			background-color: @blue-highlight;
		}

		// One column layout, top align
		@media @split-screen-down {
			flex-flow: column;
			justify-content: flex-start;
		}
	}

	&__inner {
		width: 100%;
		max-width: @form-max-width;

		@media @split-screen-down {
			padding: @default-padding;
		}
	}

	&__header {
		margin-bottom: 1rem;
	}

	&__logo {
		margin-bottom: 2.5rem;
		max-width: @logo-width;
		width: 100%;
		display: inline-block;

		@media @mobile-portrait-down {
			margin-bottom: 1rem;
		}
	}

	&__h1 {
		font-size: 1.75rem;
		margin-bottom: 1rem;

		@media @mobile-portrait-down {
			margin-bottom: .5rem;
		}
	}

	&__sticky-image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		// Put back in normal document flow
		@media @split-screen-down {
			.flexbox();

			position: static;
			transform: translate(0,0);
			background-color: @blue-highlight;
		}

		img {
			max-width: @sticky-image-width;
			width: 100%;
		}
	}

	&__info {
		&:not(:last-child) {
			margin-bottom: 2.5rem;
		}
		
		h3,
		p,
		small {
			color: @white;
			font-family: @body-font-family;
		}

		h3 {
			margin-bottom: .25rem;
		}
	}

	&__switch-country {
		.flexbox(@justifyContent: flex-start);

		margin-top: 2.5rem;
	}

	&__flag-wrapper {
		.flexbox(@alignItems: stretch);

		width: 1.125rem;
		height: 1.125rem;
		margin-right: .25rem;
	}

	&__flag {
		width: 100%;
		height: 100%;
		display: inline-block;
	}
}
