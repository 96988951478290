.gmap-slider {
	// Make the horizontal scroll modules go from edge to edge
	.horizontal-scroll-edge(@leftValue: 1.875rem; @rightValue: 1.875rem;);
	
	&__title {
		margin-bottom: @base-spacing;
	}
}

.gmap {
	overflow: hidden;
	border: 1px solid #e7e9eb;
	border-radius: 12px;
	display: grid;
	grid-template-rows: 35px 150px;
	grid-template-columns: 100%;
	grid-template-areas:
		"address"
		"distance"
		"map";
	
	&__address {
		grid-area: address;
		justify-self: start;
    	align-self: center;
    	padding: 0 10px;
    	font-weight: 700;
		span {
			display: inline-block;
			width: 14px;
			color: #43a0fb;
		}
	}
	&__distance {
		grid-area: distance;
		justify-self: start;
    	align-self: center;
    	padding: 0 10px;
		span {
			display: inline-block;
			width: 14px;
			color: #43a0fb;
		}
	}
	&__map {
		position: relative;
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}