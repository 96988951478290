.vertical-offers {
	&__heading {
		margin-bottom: .75rem;
	}

	.offers {
		display: grid;
		gap: 1.75rem 2rem;
		grid-template-columns: ~'repeat(auto-fill, minmax(11.25rem, 1fr))';
		
		@media @desktop-up {
			gap: 1.75rem 3.5rem;
		}

		@media @mobile-portrait-down {
			grid-template-columns: 	~'repeat(auto-fill, minmax(9.875rem, 1fr))';
			gap: .25rem;
		}
	}
}

// Setting from auction
.is-small-cards {
	.card {
		border: none !important;
	}
	
	.card__row + .card__row {
		display: none;
	}
	
	.card__row {
		flex-flow: column;
		align-items: flex-start;
		padding-left: 3px;
	}
	
	.card__name {
		width: 100%;
		height: auto;
		order: 2;
		align-items: flex-start;
	}
	
	.card__row {
		.discount-tag {
			&::before {
				border-right-width: 10px;
				border-left-width: 0;
				left: unset;
				border-right-color: transparent;
				right: -10px;
			}
			
			margin-bottom: 4px;
		}
	}
	
	.giftcard-image {
		padding: 3px 3px 0;
	}
	
	@media @mobile-portrait-down {
		.vertical-offers .offers {
			grid-template-columns: 	~'repeat(auto-fill, minmax(6.875rem, 1fr))';
			
			gap: .875rem .25rem;
			
			.glider .glider-slide:not(:first-child) {
				margin-left: .25rem;
			}
		}
	}
}

.horizontal-offers,
.promo-offers {
	// Make the horizontal scroll modules go from edge to edge
	.horizontal-scroll-edge(@leftValue: 3rem; @rightValue: 2.25rem;);
	
	@media @monitor-up {
		.horizontal-scroll-edge(@leftValue: 9rem; @rightValue: 5rem;);
	}
	
	@media @desktop-down {
		.horizontal-scroll-edge(@leftValue: 2rem; @rightValue: 2.5rem;);
	}
	
	@media @tablet-landscape-down {
		.horizontal-scroll-edge(@leftValue: 1.875rem; @rightValue: 1.875rem;);
	}
	
	@media @tablet-portrait-down {
		.horizontal-scroll-edge(@leftValue: 1rem; @rightValue: 1rem;);
	}
	
	@media @mobile-portrait-down {
		.horizontal-scroll-edge(@leftValue: .5rem; @rightValue: 1rem;);
	}
	
	background-color: @light-grey-background-color;

	&__heading {
		margin-bottom: .75rem;
	}
}

.promo-offers,
.gmap-slider {
	// Glider overrides to make full width if only 1 slide
	&.--full-width {
		.glider-contain,
		.glider-slide,
		.glider-track {
			width: 100% !important;
		}
		
		.glider-contain,
		.glider-slide {
			margin: 0 !important;
		}
		
		.glider {
			padding: 0 !important;
		}
	}
}

.progress-bar {
	border-radius:		5px;
	width:				80%;
	height:				20px;
	margin:				10px auto;
	border:				1px solid #5069A0;
	background-color:	#E9E9E9;
	display:			table;
	& > .progress {
		display:			table-cell;
		height:				20px;
		border-radius:		5px;
		background-color:	#1EB1FA;
		min-width:			5px;
		&.with-pending {
			border-radius:	5px 0 0 5px;
		}
	}
	& > .pending {
		display:			table-cell;
		background-image:	linear-gradient(45deg, #1eb1fa 25%, #e9e9e9 25%, #e9e9e9 50%, #1eb1fa 50%, #1eb1fa 75%, #e9e9e9 75%, #e9e9e9 100%);
		background-size:	10px 10px;
		height:				20px;
		border-radius:		0 5px 5px 0;
	}
	& > .empty {
		display:			table-cell;
		height:				20px;
	}
	& > .error {
		display:			table-cell;
		background-color:	#D50000;
		height:				20px;
		border-radius:		0 5px 5px 0;
	}
}

.giftcard-preview {
	padding:	40px 30px;
	text-align:	center;
	& > div {
		display:		inline-block;
		width:			225px;
		height:			120px;
		border-radius:	10px;
		background-size:	cover;
		background-repeat:	no-repeat;
		background-position:center center;
		position:		relative;
		& > .overlay {
			position:			absolute;
			top:				0;
			left:				0;
			width:				100%;
			height:				100%;
			border-radius:		10px;
			background-color:	rgba(0, 0, 0, 0.6);
		}
		& > .info {
			position:	absolute;
			bottom:		10px;
			left:		10px;
			& > .name {
				font-size:		18px;
				font-weight:	700;
				color:			#ffffff;
				text-align:		left;
			}
			& > .gc {
				font-size:		10px;
				font-weight:	500;
				color:			#ffffff;
				text-align:		left;
			}
		}
	}
}

.ui-offer-page {
	width: 				100%;
	height: 			100%;
	display: 			flex;
	flex-flow: 			column;
	align-items: 		stretch;
	justify-content: 	space-between;
	box-sizing: 		border-box;

	.merchant-content-wrapper {
		position: relative;
		overflow-y: auto;
		overflow-x: hidden; // Remove horizontal scroll bars for gmap slider
		
		@media @split-screen-down {
			padding: 0 1.5rem 1.5rem;
		}
		
		/* 
			Set max height for top part (card, title, desc) to scroll content
		*/
		
		// iPhone 6/7/8
		@media @tiny-mobile-down {
			max-height: 36vh;
		}
		
		// Tablet
		@media only screen and (max-width: 768px) and (max-height: 1024px) and (orientation: portrait) {
			max-height: 50vh;
		}
		
		// iPhone X
		@media only screen and (max-width: 375px) and (max-height: 812px) and (orientation: portrait) {
			max-height: 43vh;
		}
		
		// iPhone 6/7/8 plus
		@media only screen and (max-width: 414px) and (max-height: 736px) and (orientation: portrait) {
			max-height: 40vh;
		}
		
		// Small, short screen
		@media only screen and (max-width: 375px) and (max-height: 666px) and (orientation: portrait) {
			max-height: 31vh;
		}
		
		.gift-card-container {
			width:						240px;
			height:						100%;
			position:					relative;
			background-position: 		top center;
			background-size: 			100% 100%;
			background-repeat: 			no-repeat;
			margin:						0 auto;
			padding:					7px;
			.merchant-info {
				width:				95%;
				display:			flex;
				justify-content: 	space-between;
				align-items:		center;
				margin:				0 auto 10px auto;
				color:				#ffffff;
				font-weight:		500;
				.card-title {
					width:			60%;
					height:			41px;
					display:		flex;
					align-items: 	center;
					font-size:		16px;
				}
				.card-value {
					font-size:		18px;
				}
			}
		}
		.gift-card-wrapper {
			width: 			165px;
			margin: 		0 auto;
			background:		#ffffff;
			border-radius: 	13px;
		}
		.gift-card {
			background-size:	cover;
			background-repeat:	no-repeat;
			background-position:center center;
			display:			table;
			margin-top:			30px;
			height: 			104px;
			width: 				165px;
			display: 			table;
			margin:				0 auto 10px auto;
			left: 				50%;
			border-radius: 		13px;
			box-shadow:			0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
			& > div {
				display:			table-cell;
				padding:			15px 0;
				vertical-align:		middle;
				text-align:			center;
				border-radius:		13px;
				& > .overlay {
					position:		absolute;
					top:			0;
					left:			0;
					width:			100%;
					height:			100%;

				}
				& > .card-value {
					position:		absolute;
					top:			0;
					right: 			5px;
					padding:		7px;
					font-size:		24px;
					font-weight:	700;
					color:			#1eb1fa;
					text-align:		center;
				}
				& > .sep {
					width:				50px;
					height:				2px;
					background-color:	rgba(255, 255, 255, 1);
					margin:				7px auto;
				}
				& > .text {
					font-size:		12px;
					font-weight:	500;
					color:			rgba(255,255,255,0.8);
					text-align:		center;
					padding:		10px 35px;
					line-height:	20px;
				}
				& > .more {
					font-size:		9px;
					font-weight:	500;
					color:			#ffffff;
					text-transform:	uppercase;
					text-align:		center;
				}
			}
		}
	}
	.description-container {
		color:				#ffffff;
		font-weight:		400;
		text-align:			center;
		font-style:			italic;
		overflow:			hidden;
		.merchant-description {
			margin-bottom:		5px;
			font-size:			12px;
			line-height:		15px;
			height:				28px;
			text-align:			left;
			overflow:			hidden;
			a {
				color:	#ffffff;
				&:hover {
					text-decoration:	underline;
				}
			}
			&.show {
				overflow:		visible;
				height:			auto;
			}
		}
		.read-more, .read-less {
			margin:				3px 0;
			font-size:			12px;
			&:hover {
				text-decoration:	underline;
			}
		}
	}
}



.ui-offer-widget-B {
	top:				0;
	left:				0;
	width:				100%;
	height:				100%;
	display:			inline-block;
	background-size:	cover;
	position:			absolute;
	background-repeat:	no-repeat;
	& > .overlay {
		position:			absolute;
		top:				0;
		left:				0;
		width:				100%;
		height:				100%;
		background-color:	rgba(0, 0, 0, 0.3);
	}
	& > .name {
		position:			absolute;
		top:				17px;
		left:				17px;
		& > .merchant {
			font-size:		22px;
			font-weight:	600;
			color:			#ffffff;
		}
		& > .gc {
			font-size:		13px;
			font-weight:	500;
			color:			#ffffff;
		}
	}
	& > .info {
		position:		absolute;
		bottom:			17px;
		left:			17px;
		font-size:		13px;
		font-weight:	600;
		color:			#ffffff;
		background-color:	rgba(243, 148, 29, 1);
		border-radius:	3px;
		padding:		3px 10px;
	}
}

.ui-offer-widget {
	text-align:			center;

	@transitionSpeed:	0.3s;
	@transitionEasing:	ease-out;

	/* ng-if show animation */
	&.ng-enter {
		transition:	@transitionSpeed @transitionEasing all;
		opacity:	0;
		&.ng-enter-active {
			opacity:	1;
		}
	}
	/* ng-if hide animation */
	&.ng-leave {
		transition:	@transitionSpeed @transitionEasing all;
		opacity:	1;

		&.ng-leave-active {
			opacity:	0;
		}
	}
}

.powered-title {
	background-color:	rgba(253, 253, 255, 1);
	padding:			14px;
	text-align:			center;
	&.shadow {
		box-shadow:			0 1px 20px rgba(0,0,0,0.3);
		margin-bottom:		20px;
	}
	& > div {
		display:			inline-block;
		margin:				0 auto;
		& > .text {
			font-size:		17px;
			font-weight:	400;
			line-height:	24px;
			letter-spacing:	1px;
			color:			rgba(64, 64, 64, 0.9);
			text-shadow:	0 1px 2px rgba(214, 214, 214, 1);
		}
		& > .powered {
			font-size:		7px;
			font-weight:	500;
			line-height:	14px;
			color:			rgba(112, 112, 112, 0.9);
			text-align:		right;
			vertical-align:	middle;
			& > img {
				margin-left:	4px;
				vertical-align:	middle;
			}
		}
	}
}


.cashout_confirmation {
	background-color:	#0F567A;
	padding:			15px;
	& > .title {
		font-size:		20px;
		font-weight:	700;
		letter-spacing:	1px;
		color:			#ffffff;
		text-align:		center;
		margin-bottom:	20px;
	}
	& > .text {
		font-size:		15px;
		font-weight:	400;
		color:			#ffffff;
		text-align:		center;
	}
	& > .debug {
		font-size:		9px;
		font-weight:	400;
		color:			rgba(255,255,255, 0.3);
		text-align:		center;
		margin:			10px 0;
	}
}


.invalid-country {
	& > div {
		text-align:		center;
		padding:		30px 20px;
		font-size:		16px;
		font-weight:	500;
		color:			#0F79AF;
	}
}
