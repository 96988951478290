/*
	Keyframes
*/

@keyframes fadeIn {
	from { 
		opacity: 0;
	}
	
	to { 
		opacity: 1;
	}
}

@keyframes fadeOut {
	from { 
		opacity: 1;
	}
	
	to { 
		opacity: 0;
	}
}


/* Sidebar overlay animation */
@keyframes sideSlideIn {
	from {
		transform: translateX(100vw);
	}
	
	to {
		transform: translateX(0);
	}
}

@keyframes sideSlideOut {
	from {
		transform: translateX(0);
	}
	
	to {
		transform: translateX(100vw);
	}
}


/* Bottom panels */
@keyframes slideUp {
	from { 
		transform: translateY(100%);
	}
	
	to { 
		transform: translateY(0);
	}
}

@keyframes slideDown {
	from { 
		transform: translateY(0);
	}
	
	to { 
		transform: translateY(150%);
	}
}


/* Accordion */
@keyframes unfold {
	from { 
		height:	0;
	}
	
	to { 
		height:	100%;
	}
}


/* Side panels */
@keyframes slideLeft {
	from { 
		transform: translateX(100%);
	}
	
	to { 
		transform: translateX(0);
	}
}

@keyframes slideRight {
	from { 
		transform: translateX(0);
	}
	
	to {
		transform: translateX(150%);
	}
}


/*
	Lock -> unlock -> check animation for points reward levels
*/

// Fade out the 'lock' icon after the 'unlock' icon to appear as though it's unlocking
@keyframes fadeOutAfter {
	99% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

// Scale down grey circle
@keyframes scaleDown {
	from {
		transform: scale(1);
	}

	to {
		transform: scale(0);
	}
}

// Scale up yellow circle
@keyframes scaleUp {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}

// Rotate and scale down unlock icon
@keyframes scaleDownRotate {
	from {
		transform: scale(1) rotate(0);
	}

	to {
		transform: scale(0) rotate(-90deg);
	}
}

// Rotate, scale up, and display check icon
@keyframes scaleUpRotate {
	from {
		transform: rotate(135deg) scale(0);
	}

	to {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
}

// Loading with confetti
@keyframes propagateOut {
	0% {
		opacity:	0;
		transform:	scale(0);
	}
	100% {
		opacity:	1;
		top:		-55%;
		transform:	scale(1.6);
	}
}



/*
	Redeem points
*/

@keyframes scaleUpGroup {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(.85);
	}
}

@keyframes moveUpGroup {
	to {
		top: -110px;
	}
}

@keyframes moveLeftBlue {
	to {
		left: 5%;
	}
}

@keyframes moveRightGreen {
	to {
		left: 85%;
	}
}

@keyframes moveRightWhite {
	to {
		left: 95%;
	}
}

@keyframes moveDownGroup {
	to {
		top: -90px;
	}
}

@keyframes moveDownBlue {
	to {
		top: 0;
	}
}

@keyframes moveDownGreen {
	to {
		top: -15px;
	}
}

@keyframes moveDownWhite {
	to {
		top: -40px;
	}
}

@keyframes halfSpin {
	from {
		transform: rotate(0deg);
	}
	
	to {
		transform: rotate(180deg);
	}
}
  
