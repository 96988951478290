.barcode {
	&__image {
		margin-top: 1rem;
	}
	
	&__value {
		font-size: 1rem;
		margin-top: .25rem;
	}
}
