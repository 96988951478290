/* Change the widget background to white */
.prizeout-widget.theme-white {
	
	.side-panel-layout,
	.side-panel,
	.ui-content,
	.horizontal-offers,
	.promo-offers,
	.sticky__component {
		background-color: @white;
	}
	
	.sticky__component {
		width: calc(100% + 5rem); // Make sure entire bg stays white when scrolling content below
		
		@media @split-screen-down {
			width: 100%; // Reset
		}
	}
	
	.ui-promo-widget {
		box-shadow: 0 0 10px @divider-border-color;
	}
	
	.card {
		&.--hasDetails {
			border-color: #F5F5F7;
		}
		
		&.--selected {
			border-color: @blue-highlight;
		}
	}
}

/* Change header background to white */
.ui-header.theme-white {
	background-color: @white;
	color: @default-text-color;
	border-bottom: 1px solid @divider-border-color;
	
	* {
		color: @default-text-color;
	}
	
	.back {
		&__icon,
		&__text {
			color: @default-text-color;
		}
		
		&__wrapper {
			&:hover {
				.back__icon,
				.back__text {
					color: @blue-highlight;
				}
			}
		}
	}
	
	.user__balance {
		border: 2px solid @default-text-color;
	}
	
	.hamburger {
		&.--isActive {
			.hamburger__inner {
				&::before,
				&::after {
					background-color: @default-text-color;
				}
			}
		}
		
		&__inner,
		&__inner::before,
		&__inner::after {
			background-color: @default-text-color;
		}
	}
}