.sticky {
	&.side-panel-layout {
		padding-top: 0;
		transition: .3s all ease-out;

		&.--isSticky {
			@media @mobile-portrait-down {
				padding-top: .75rem;
			}
		}
	}
	
	&__component {
		top: 0;
		position: sticky;
		background-color: @light-grey-background-color;
		z-index: 2;
		border-bottom: 2px solid transparent;
		transition: .3s top ease-out, .3s border ease-out;

		.--isSticky & {
			// If component is sticky, make it full width to deal with border and z-index
			.make-full-width(@side-padding) {
				@base-padding: 1rem;
				/*
					If padding/margin changes when it gets sticky, it'll bounce. padding/margin should be on the element itself
				*/
				
				padding-left: @side-padding;
				padding-right: @side-padding;
				margin-left: -@side-padding;
				margin-right: -@side-padding;
				
			}

			.make-full-width(3rem);

			/*padding-top: 1rem;*/
			border-color: @divider-border-color;
			
			@media @monitor-up {
				.make-full-width(9rem);
			}
		
			@media @desktop-down {
				.make-full-width(2.5rem);
			}

			@media @tablet-landscape-down {
				.make-full-width(1.875rem);
			}
		
			@media @tablet-portrait-down {
				.make-full-width(1rem);
			}
			@media @mobile-portrait-down {
				top: -3rem;
			}

			> div {
				padding: 0;
			}
		}
	}

	&__slider {
		/*padding-bottom: 1rem;*/
	}
}
