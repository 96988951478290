.rewardsCard {
	@card-height: 100px;

	&__list {

	}

	&__item {
		.flexbox(@flexFlow: row nowrap; @justifyContent: flex-start; @alignItems: stretch;);

		background-color: @white;
		border-radius: @alternate-border-radius;
		height: @card-height;

		&:not(:last-child) {
			margin-bottom: 1.25rem;
		}
	}

	&__imageWrapper {
		position: relative;
		margin: 0;
		width: auto;
		height: 100%;

		& > img {
			width: auto;
			height: 100%;
		}

		& > div {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			box-sizing: border-box;
			border-radius: 4px @alternate-border-radius @alternate-border-radius 4px;
		}
	}

	&__copy {
		.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between;);

		padding: .75rem 1rem;
		flex: 2;
	}

	&__subheading {
		margin-bottom: .35rem;
	}

	&__description {
		margin-bottom: .5rem;

		> * {
			font-size: .75rem;
			line-height: 1.2;
		}
	}
}
