html {
	scroll-behavior: smooth;
}

.animated {
	transition:	all 0.3s ease-out;
	
}

a {
	text-decoration: none;
	color: @blue-highlight;
	font-weight: 700;
	
	&:hover {
		text-decoration: underline;
	}
}

[ng-click] {
	cursor:	pointer;
}

.sandbox-env {
	position:		absolute;
	z-index:		50000;
	bottom:			0;
	right:			0;
	color:			#ff0000;
	font-size:		10px;
	font-weight:	500;
	padding:		5px;
}

.loading-full-screen {
	position:	absolute;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	background-color:	rgba(255,255,255, 1);
	padding:	10px 30px;
	text-align:	center;
}

.prizeout-widget {
	position:	absolute;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	font-family: @body-font-family;
	font-weight: 400;
	background: rgba(252, 253, 255, 1);
	box-sizing: border-box;
}

.ui-header {
	background-color:	@blue-highlight;
	color:				@white;
	box-sizing: 		border-box;
	width: 				100%;
	z-index: 			51010;
	position: 			relative;
	height: 			@header-height;
	
	* {
		color: @white;
	}

	&__inner {
		.offset-padding(@verticalPadding: 0; @horizontalPadding: 1rem; @mediaQuery: @tablet-portrait-down);
		.flexbox(@justifyContent: space-between; @flexFlow: row nowrap);

		width:				100%;
		height: 			100%;
		margin: 			0 auto;
		box-sizing: 		border-box;
		padding: 			0 1.875rem;
	}

	.back {
		.flexbox(@justifyContent: flex-start);
		
		flex: 1;
		
		&__wrapper {
			.flexbox();
			
			&:hover {
				.back__icon,
				.back__text {
					color: @default-text-color;
				}
			}
		}
		
		&__icon,
		&__text {
			.transition();
			
			color: @white;
			font-size: 1rem;
			line-height: 1;
		}
		
		&__text {
			margin-left: .5rem;
		}
	}

	.logo {
		text-align: center;
		width: auto;
		
		&__wrapper {
			.flexbox();
		}

		&__image {
			width: 		100%;
			max-width: 	100px;
			height: 	auto;
			display: 	inline-block;

			&.--mobile {
				max-width: 20px;
			}
		}
	}

	.user {
		.flexbox(@display: inline-flex; @justifyContent: flex-end);
		
		flex: 1;

		&__balance {
			.flexbox(@display: inline-flex);
			
			background-color:	transparent;
			border-radius:		.5rem;
			border: 			2px solid @white;
			padding: 			.15rem .5rem;
			
			&.--currency {
				border: none;
			}
			
			p {
				font-size: 1rem;
				line-height: 1;
				
				@media @mobile-portrait-down {
					font-size: .75rem;
					line-height: 1.4;
				}
			}
			
			@media @mobile-portrait-down {
				border-width: 1.5px;
				font-size: .75rem;
			}
		}

		&__menu {
			.flexbox();
		}
		
		&__menu-item {
			margin: 0 2.5rem 0 0;
			
			&.--isActive {
				a {
					color: @default-text-color;
					font-weight: 700;
				}
			}
				
			a {
				font-family: @body-font-family;
				font-size: .875rem;
				font-weight: 400;
				color: @white;
				text-decoration: none;
				
				&:hover,
				&:active {
					color: @default-text-color;
				}
			}
		}
		
		&__currencyWrapper {
			.flexbox(@flexFlow: column; @alignItems: stretch);
			
			p {
				font-size: .875rem;
				color: @white;
			}
			
			small {
				font-size: .75rem;
				color: @default-text-color;
				font-weight: 600;
			}
		}
		
		&__flag {
			max-width: 1.5rem;
			margin-right: .35rem;
		}
		
		&__callout {
			color: @default-text-color;
		}
	}
}

.ui-content {
	.offset-padding(@verticalPadding: 2rem; @horizontalPadding: 9rem; @mediaQuery: @monitor-up);
	.offset-padding(@verticalPadding: 2rem; @horizontalPadding: 1.875rem; @mediaQuery: @tablet-landscape-down);
	.offset-padding(@verticalPadding: 2rem; @horizontalPadding: 1rem; @mediaQuery: @tablet-portrait-down);
	.offset-padding(@verticalPadding: 1rem; @horizontalPadding: .5rem; @mediaQuery: @mobile-portrait-down);
		
	box-sizing: border-box;
	padding: 2rem;
	color: @default-text-color;
	background-color: @light-grey-background-color;
	-webkit-overflow-scrolling: touch;
	
	.side-panel-layout.--website & {
		padding: 0;
		background-color: @white;
	}
}

.ui-footer {
	background-color: @white;
	
	* {
		box-sizing: border-box;
	}
	
	&__inner {
		.transition();
		.flexbox(@justifyContent: space-between);
		.offset-padding(@verticalPadding: 1.25rem; @horizontalPadding: 9rem; @mediaQuery: @monitor-up);
		.offset-padding(@verticalPadding: 1.25rem; @horizontalPadding: 1.875rem; @mediaQuery: @tablet-landscape-down);
		.offset-padding(@verticalPadding: 1.25rem; @horizontalPadding: 1rem; @mediaQuery: @tablet-portrait-down);
		.offset-padding(@verticalPadding: 1rem; @horizontalPadding: .5rem; @mediaQuery: @mobile-portrait-down);
		
		padding: 1.25rem 2rem;
		width: 100%;
		
		@media @desktop-down {
			flex-flow: column;
		}
		
		.sidebar-opened .side-panel-layout.--website & {
			width: ~'calc(100% - @{side-panel-width})';
			
			@media @split-screen-down {
				width: ~'calc(100% - @{sidebar-width-mobile})';
			}
			
			@media @mobile-landscape-down {
				width: 100%;
			}
		}
	}
	
	p {
		color: #808997;
		font-weight: 700;
		
		@media @desktop-down {
			font-size: .75rem;	
		}
	}
	
	&__links {
		.flexbox();
		
		@media @desktop-down {
			margin-top: .875rem;
		}
	}
	
	&__link {
		margin: 0;
		line-height: 1.5;
		
		a {
			margin: 0 .5rem;
			
			@media @mobile-portrait-down {
				margin: 0 .05rem;	
			}
		}
	}
}


.scroll-pane {
	width: 		100%;
	height: 	100%;
	margin: 	0;
	padding: 	0;
}

.side-panel-layout {
	width: 				100%;
	height: 			100%;
	overflow-y: 		auto;
	box-sizing: 		border-box;
	background-color: 	@light-grey-background-color;
	transition: 		0.3s ease-in all;
	overflow-x: 		hidden;
	
	&.--website {
		background-color: @white;
	}
	
	&.side-panel-open {
		.ui-content,
		.ui-footer__inner {
			width: @content-width;
			padding-right: 2.25rem;

			@media @monitor-up {
				padding-right: 5rem;
			}

			@media @desktop-down {
				padding-right: 2.5rem;
			}

			@media @tablet-landscape-down {
				padding-right: 1.875rem;
			}

			@media @tablet-portrait-down {
				padding-right: 1rem;
			}
		}
	}
}

.ng-highlight {
	background-color:	rgba(66, 165, 245, 0.3);
}



/*
	OneTrust
*/
button {
	margin:	0;
}
.ot-btn-anchor {
	text-decoration: none;
}
.ot-exercise-button-light {
	background: #eee;
	border: 1px solid #aaa;
	/*margin-left: 44px;*/
}
.ot-exercise-button {
	margin-top: 10px;
	padding: 6px 10px;
	border-radius: 5px;
	cursor: pointer;
	img {
		margin-right: 0px;
		vertical-align: middle;
	}
	.ot-text-container {
		vertical-align: middle;
		display: inline-block;
		margin-top: -4px;
		color: #6cc04a;
		font-size: 13px;
	}
	.ot-subtext {
		float: right;
		margin-top: 0px;
		font-size: 11px;
	}
	.ot-powered-by-text {
		font-size: 7px;
		font-family: 'Open Sans';
		-webkit-font-smoothing: antialiased;
		vertical-align: top;
		line-height: 16px;
	}
}
.ot-exercise-button.ot-exercise-button-light {
	.ot-powered-by-text {
		color: #3c474c;
	}
}



table.my-giftcards {
	width:			100%;
	& > tbody {
		& > tr {
			& > td {
				padding:	10px 5px;
				&.image {
					width:	100px;
					& > .giftcard-image {
						width: 					123px;
						height: 				78px;
						position: 				relative;
						margin:					0 auto;
						border-radius: 			10px;
						border:					1px solid #dcdddf;
						background-size: 		cover;
						background-repeat: 		no-repeat;
						background-position: 	center center;
					}
				}
				&.gc-name {
					display:			flex;
					flex-direction: 	column;
					justify-content: 	space-between;
					margin:				12px 0;
					color:				#4f566b;
					font-size:			12px;
					font-weight:		500;

					.name {
						display:			flex;
						flex-wrap:			wrap;
						justify-content: 	space-between;
						align-items: 		center;
						margin-bottom:		10px;
						font-size:			13px;
						font-weight:		500;
					}
					.detail {
						display:			flex;
						justify-content:	space-between;
						align-items: 		center;
						.when {
							width:			100%;
							display:		flex;
							flex-direction: column;
							padding:		8px 0;
							font-size:		10px;
							font-weight:	300;
							border-right:	1px solid #e1e8eb;
							& > span {
								margin-bottom:	5px;
							}
						}
						.value {
							width:			40%;
							display:		flex;
							align-items: 	center;
							padding:		0 8px;
							font-size:		16px;
							& > span {
								width:				80%;
								margin-right:		10px;
							}
							.fa-caret-right, .fa-caret-down {
								font-size:		20px;
							}
						}
					}
				}
				&.status {
					text-align:	center;
					a {
						font-size:		10px;
						font-weight:	300;
						color:			rgba(0,0,0,0.7);
					}
				}
			}
			&.last {
				td {
					border-bottom:		1px solid #6c748e;
				}
			}
		}
	}
}
table.transaction-detail {
	width:			100%;
	padding:		5px;
	& > tbody {
		tr {
			font-size:	12px;
			td {
				padding-bottom:	5px;
				&:first-child {
					color:			rgba(0, 0, 0, 0.5);
					text-align:		right;
				}
				&.detail {
					color:			#4f566b;
				}
			}
		}
	}
}

.text-link {
	color:				#1eb1fa;
	text-decoration:	underline;
}
