.flex-container {
	display:		flex;
	align-items: 	center;
}

.align-start {
	align-items:	start;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-center {
	justify-content: center;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.flex-column {
	flex-direction:	column;
}

.flex-separate {
	.flexbox(@flexFlow: column; @alignItems: stretch; @justifyContent: space-between);
	
	min-height: 100%;
	overflow: auto;
}

.flex-1 {
	flex: 1;
}

.overflow-x {
	overflow-x:		auto;
}

.overflow-y {
	overflow-y:		auto;
}

.text-center {
	text-align:		center;
}

.text-left {
	text-align:		left;
}

.text-right {
	text-align:		right;
}

.text-italic {
	font-style:		italic;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-capitalize {
	text-transform:	capitalize;
}

.relative-block {
	display:	block;
	position:	relative;
}

// margins
.mt-xs {
    margin-top:     .5rem;
}

.mt-small {
    margin-top:     1rem;
}

.mt-medium {
    margin-top:     2rem;
}

.mt-large {
    margin-top:     4rem;
}

.mr-xs {
	margin-right:	.5rem;
}

.mr-small {
	margin-right:	1rem;
}

.mr-medium {
    margin-right:   2rem;
}

.mb-xs {
    margin-bottom:  .5rem;
}

.mb-small {
    margin-bottom:  1rem;
}

.mb-medium {
    margin-bottom:  2rem;
}

.mb-large {
    margin-bottom:  4rem;
}

.ml-xs {
	margin-left:	.5rem;
}

.ml-small {
    margin-left:    1rem;
}

.ml-medium {
    margin-left:   2rem;
}


// padding
.pt-small {
    padding-top:     1rem;
}

.pb-small {
    padding-bottom:  1rem;
}

.pr-small {
	padding-right:	1rem;
}

.pr-medium {
    padding-right:   2rem;
}

.pl-medium {
    padding-left:    2rem;
}

.pt-medium {
    padding-top:     2rem;
}

.pb-medium {
    padding-bottom:  2rem;
}

.pt-large {
    padding-top:     4rem;
}

.pb-large {
    padding-bottom:  4rem;
}

.p-small {
	padding: 1rem;
}

.highlight {
	color: @blue-highlight;
}

.dark {
	color: @default-text-color;
}

.success {
	color: @green;
}

.error {
	color: @red;
}

.warning {
	color: @yellow;
}

.divider {
	border-left: 0;
    border-right: 0;
    border-top: 0;
    border-color: @divider-border-color;
    margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	width: 100%;
	
	&.--withoutMargin {
		margin: 0;
	}
}

[ng-click],
[ng-mouseup] {
	cursor: pointer;
}

/* For screen readers */
.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.full-height {
	height: 100%;
}

ul,
ol,
dl {
	margin: 0;
	padding: 0;
}

li {
	list-style-type: none;
	font-size: .875rem;
	line-height: 1.3;
	margin-bottom: .5rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.fade-in {
	&.ng-enter {
		animation: fadeIn 0.4s ease-out;
	}
}

.disabled {
	cursor: not-allowed;
	pointer-events: none;
	user-select: none;
	
	.card__imageWrapper {
		opacity: .5;
	}
}

.grayscale {
	filter: grayscale(1);
}

.secondary-link {
	color: #99A1AC;
}

.flip {
	transform: rotate(180deg);
}

.visible {
	visibility: visible;
}

.invisible {
	visibility: hidden;
}

.inactive {
	*:not(.bttn *) {
		color: @default-grey-text-color;
	}
	
	.bttn.--primary {
		background-color: @default-grey-text-color;
	}
}
