.checkout-page {
	&[ng-click] {
		cursor: default;
	}
	.ui-offer-page {
		@carousel-height:					170px;
		@carousel-height-mobile:			100px;
		@thumbnail-wrapper-width:           43px;
		@thumbnail-wrapper-width-mobile:    40px;
		@thumbnail-width:                   36px;
		@thumbnail-width-mobile:            33px;
		@preview-width:                     ~'calc((100% - .5rem) - @{thumbnail-wrapper-width})';
		@preview-max-width: 				268px;
		@preview-max-width-mobile:          158px;

		.carousel-wrapper {
			width:              100%;
			height: 			@carousel-height;
			overflow: 			hidden;
			box-sizing: 		border-box;
			margin-bottom:      1.25rem;
			box-sizing:         border-box;
			background-color:   transparent;
			display:            flex;
			flex-flow:          row nowrap;
			align-items:        flex-start;
			justify-content:    flex-start;

			@media @mobile-portrait-down {
				height: @carousel-height-mobile;
			}

			@media @mobile-portrait-down {
				margin-bottom: 1rem;
			}

			&--no-thumbs {
				justify-content: center;

				.checkout-slider.checkout-slider-for {
					margin: 		auto;
				}
			}
		}

		.checkout-slider {
			// preview
			&.checkout-slider-for {
				width: 			@preview-width;
				max-width: 		@preview-max-width;
				height: 		@carousel-height;
				overflow: 		hidden;
				margin-right: 	.5rem;
				cursor:         default;
				border: 		none;

				@media @mobile-portrait-down {
					max-width: 	@preview-max-width-mobile;
					height: @carousel-height-mobile;
				}

				.card__imageWrapper {
					max-width: 		@preview-max-width;

					> div {
						border-radius: unset;
					}

					@media @mobile-portrait-down {
						max-width: @preview-max-width-mobile;
					}
				}
			}

			// thumbnails
			&.checkout-slider-nav {
				width:  @thumbnail-wrapper-width;
				height: @carousel-height;
				overflow: hidden;

				@media @mobile-portrait-down {
					width: 	@thumbnail-wrapper-width-mobile;
					height: @carousel-height-mobile;
				}

				.card__imageRepeater {
					border: 1px solid transparent;
				}

				.card__imageWrapper {
					width: @thumbnail-width;
					height: @thumbnail-width;
					margin: 0 auto;
					border-radius: 50%;
					border: 2px solid transparent;
					overflow: hidden;
					position: relative;
					z-index: 2;

					> img {
						display: inline-block;
						height: 100%;
					}

					@media @mobile-portrait-down {
						width: 	@thumbnail-width-mobile;
						height: @thumbnail-width-mobile;
					}
				}

				.slick-slide {
					outline: 	none;
					cursor: 	pointer;

					&.slick-current {
						.card__imageWrapper {
							border:     2px solid @blue-highlight;
						}
					}
				}
			}

			.slick-track,
			.slick-list {
				height: @carousel-height !important;

				@media @mobile-portrait-down {
					height: @carousel-height-mobile !important;
				}
			}

			.slick-list,
			.slick-track,
			.slick-slide {
				box-sizing: border-box;
			}
		}

		.checkout__tags {
			height: ~'calc(30px + 1rem)';
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-flow: row wrap;

			> * {
				margin-right: .5rem;
			}
		}

		.checkout__description {
			&:extend(.text-light);

			@media @mobile-portrait-down {
				margin-bottom:  0;
			}

			&.--bogo {
				> p {
					font-size: 1.25rem;
					font-weight: 600;
					color: @green;
				}

			}

		}

		.checkout__small {
			color: 			@blue-highlight;
			cursor: 		pointer;
			margin-top: 	.5rem;
			margin-bottom: 	1rem;
		}

		.checkout__subheading {
			.flexbox(@justifyContent: flex-start);
			
			margin-top: 0;

			.discount-tag {
				margin-left: .5rem;
			}
		}

		.ui-form {
			.regular {
				padding:    .75rem 0;
				width:      inherit;
			}
		}
		
		.ui-toggles-wrapper {
			@spacing: 1.25rem;
			
			@media @mobile-portrait-down {
				// Make the horizontal scroll modules go from edge to edge
				.horizontal-scroll-edge(@leftValue: @spacing; @rightValue: @spacing;);
			}
		}
	}
}

