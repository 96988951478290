.redeemPanel {
	@carousel-height:					170px;
	@carousel-height-mobile:			158px;
	@thumbnail-wrapper-width:           43px;
	@thumbnail-wrapper-width-mobile:    40px;
	@thumbnail-width:                   36px;
	@thumbnail-width-mobile:            33px;
	@preview-width:                     ~'calc((100% - .5rem) - @{thumbnail-wrapper-width})';
	@preview-max-width: 				290px;
	@preview-max-width-mobile:          250px;

	.carousel-wrapper {
		width:              100%;
		height: 			@carousel-height;
		overflow: 			hidden;
		box-sizing: 		border-box;
		margin-bottom:      1.5rem;
		box-sizing:         border-box;
		background-color:   transparent;
		display:            flex;
		flex-flow:          row nowrap;
		align-items:        flex-start;
		justify-content:    flex-start;

		@media @mobile-portrait-down {
			height: @carousel-height-mobile;
		}

		@media @mobile-portrait-down {
			margin-bottom: 1rem;
		}

		&--no-thumbs {
			justify-content: center;

			.redeem-slider.redeem-slider-for {
				margin: auto;
			}
		}
	}

	.redeem-slider {
		// preview
		&.redeem-slider-for {
			width: 			@preview-width;
			max-width: 		@preview-max-width;
			height: 		@carousel-height;
			overflow: 		hidden;
			margin-right: 	.5rem;
			cursor:         default;
			border: 		none;

			@media @mobile-portrait-down {
				max-width: 	@preview-max-width-mobile;
				height: @carousel-height-mobile;
			}

			.card__imageWrapper {
				max-width: 		@preview-max-width;

				> div {
					border-radius: unset;
				}

				@media @mobile-portrait-down {
					max-width: @preview-max-width-mobile;
				}
			}
		}

		// thumbnails
		&.redeem-slider-nav {
			width:  @thumbnail-wrapper-width;
			height: @carousel-height;
			overflow: hidden;

			@media @mobile-portrait-down {
				width: 	@thumbnail-wrapper-width-mobile;
				height: @carousel-height-mobile;
			}

			.card__image_repeater {
				border: 1px solid transparent;
			}

			.card__imageWrapper {
				width: @thumbnail-width;
				height: @thumbnail-width;
				margin: 0 auto;
				border-radius: 50%;
				border: 2px solid transparent;
				overflow: hidden;
				position: relative;
				z-index: 2;

				> img {
					display: inline-block;
					height: 100%;
				}

				@media @mobile-portrait-down {
					width: 	@thumbnail-width-mobile;
					height: @thumbnail-width-mobile;
				}
			}

			.slick-slide {
				outline: 	none;
				cursor: 	pointer;
				
				&.slick-current {
					.card__imageWrapper {
						border:     2px solid @blue-highlight;
					}
				}
			}
		}

		.slick-track,
		.slick-list {
			height: @carousel-height !important;

			@media @mobile-portrait-down {
				height: @carousel-height-mobile !important;
			}
		}

		.slick-list,
		.slick-track,
		.slick-slide {
			box-sizing: border-box;
		}
	}
}
