.entrance-modal {
	padding: 3rem 5rem;
	display: grid;
	gap: 2rem 1rem;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	grid-template-areas:
		"giftcards copy"
		"giftcards cta"; // set up initial layout for desktop
	
	// Success view
	&.--isSuccess {
		padding: 7.5rem 5rem;
		
		@media @tablet-portrait-down {
			padding: 5rem 2.5rem;
		}
		
		@media @mobile-landscape-down {
			padding: 3rem 2.5rem;
		}
		
		@media @mobile-portrait-down {
			padding: 2rem;
		}
	}
		
	@media @tablet-portrait-down {
		padding: 3rem 2.5rem;
	}
	
	@media @mobile-landscape-down {
		grid-template-columns: 1fr;
		grid-template-areas:
			"copy"
			"giftcards"
			"cta"; // change layout on mobile
		gap: 2.5rem;
	}
	
	@media @mobile-portrait-down {
		padding: 2rem;
	}
		
	&__grid-item {
		&:first-child {
			grid-area: giftcards;
			
			// nested grid
			display: grid;
			grid-template-columns: 1fr;
			gap: 2rem;
			
			@media @mobile-landscape-down {
				justify-items: center;
			}
		}
		
		&:nth-child(2) {
			grid-area: copy;
			align-self: end;
			
			@media @mobile-landscape-down {
				align-self: start;
			}
		}
		
		&:nth-child(3) {
			grid-area: cta;
		}
	}
	
	&__headline {
		font-size: 1.875rem; // Fallback
		font-size: ~'clamp(1.5rem, (1rem + 1.6667vw), 2.25rem)';
		min-height: 0vw; // Safari bug w/clamp
		margin-bottom: .75rem;
	}
	
	&__brand {
		.flexbox(@flexFlow: column; @alignItems: stretch);
		
		@media @mobile-landscape-down {
			align-items: center;	
		}
	}
	
	&__giftcard-wrapper {
		@giftcard-max-width: 13.4375rem;
		@giftcard-max-width-success: 16.25rem;
		@giftcard-max-width-mobile: 9.375rem;
	
		.flexbox();
		
		width: auto;
		max-width: @giftcard-max-width;
		border-radius: 13px;
		margin-bottom: .25rem;
		border: 3px solid transparent;
		padding: 3px;
		
		&.--selected {
			border: 3px solid @blue-highlight;
		}
		
		.--isSuccess & {
			max-width: @giftcard-max-width-success;
			
			@media @mobile-portrait-down {
				max-width: @giftcard-max-width-mobile;
			}
		}
		
		@media @mobile-portrait-down {
			max-width: @giftcard-max-width-mobile;
		}
	}
	
	&__giftcard {
		width: 100%;
		border-radius: @default-border-radius;
	}
}
